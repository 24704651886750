.menus{
  a:focus{
    color: #ffffff;
  }

  &.menu{
    width: 225px;

    > ul{
      list-style: none;
      padding: 0;
      margin: 0;

      > li.menu-item{
        a.menu-link{
          @include rem-2(16, 24);
          color: $white !important;
        }

        + li.menu-item{
          margin-top: 15px;
        }
      }
    }
    + .menu{
      margin-left: 20px;
    }
  }

  .container-fluid{
    .flex-row{
      margin: 0 auto;
      width: 100%;
      justify-content: center;

      .menu-item{
        &.breadcrumb-active{
          a{
            border-bottom: 1px solid #60c4ff;
          }
        }
      }

      .menu-content-titles{
        width: 100%;

        .back{
          color: $white;
          display: inline-block;
          position: absolute;
          outline: none;
          top: 40px;
          left: 20px;
        }

        &.hide-me{
          display: none !important;
        }
      }

      .menu-titles{
        font-family: "Prata", serif;
        font-size: 1.25rem;
        text-align: right;

        .menu-title{
          color: $orange-bright;
          display: inline-block;
          text-align: right;
          font-family: $headings-font-family;
          @include rem-2(20, 24);
          border-bottom: 1px solid transparent;

          + li.menu-item{
            margin-top: 20px;
            position: relative;
          }

          &.active{
            border-bottom: 1px solid $blue-light;
            margin-left: auto;
          }

          &:hover{
            border-bottom: 1px solid $blue-light;
            margin-left: auto;
          }
        }
      }

      .menu-content{
        ul{
          list-style: none;
          display: none;
          padding: 0;
          margin-left: 15px;

          &.active{
            display: block;
          }

          li{
            font-size: 16px;
            line-height: 1.5;
            color: $white;
            position: relative;

            .icon-play{
              color: $blue-light;
              opacity: 0;
              position: absolute;
              left: -23px;
            }

            & + li{
              margin-top: 20px;
            }

            .menu-link{
              position: relative;
              display: inline-block;
              color: #ffffff;

              span.material-icons{
                display: none;
              }
            }

            &.has-child{
              position: relative;
              padding-right: 24px;
              width: 100%;

              span.material-icons{
                display: block;
                position: absolute;
                right: 0;
                top: 0;
              }

              &.active{
                a{
                  border-bottom: 1px solid #60c4ff;
                }
              }
            }
          }
        }
      }

      .menu-content-level-two{
        display: none;

        .menu-list-second-level{
          list-style: none;
          display: none;
          padding: 0;
          margin-left: 15px;

          &.active{
            display: block;
          }

          li{
            font-size: 16px;
            line-height: 1.5;
            color: $white;
            position: relative;

            .icon-play{
              color: $blue-light;
              opacity: 0;
              position: absolute;
              left: -23px;
            }

            & + li{
              margin-top: 20px;
            }

            .child-item{
              position: relative;
              display: inline-block;
              color: #ffffff;
            }

            &.has-child{
              position: relative;
              padding-right: 24px;
              width: 100%;

              span.material-icons{
                display: block;
                position: absolute;
                right: 0;
                top: 0;
              }

              &.active{
                a{
                  border-bottom: 1px solid #60c4ff;
                }
              }
            }
          }
        }

        &.open{
          display: block;
        }
      }

      .menu-content-level-three{
        display: none;

        .menu-list-third-level{
          list-style: none;
          display: none;
          padding: 0;
          margin-left: 15px;

          &.active{
            display: block;
          }

          li{
            font-size: 16px;
            line-height: 1.5;
            color: $white;
            position: relative;

            .icon-play{
              color: $blue-light;
              opacity: 0;
              position: absolute;
              left: -23px;
            }

            & + li{
              margin-top: 20px;
            }

            .child-item{
              position: relative;
              display: inline-block;
            }
          }
        }

        &.open{
          display: block;
        }
      }
    }
  }
}

.navbar-toggler{
  padding: 0;

  &.dropdown-no-icon{
    padding-right: 0;

    &:after{
      display: none;
    }
  }
}

.dropdown{
  &.show .dropdown-toggle:after{
    content: "\e5ce";
  }
}

#mainnav.megamenu{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2040;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  padding: 0;
  margin: 0;
  border: none;
  @include border-radius(0 0 0 0);

  &.collapsing{
    position: absolute;
  }

  .mainnav-close{
    position: absolute;
    top: 30px;
    right: 20px;
    color: $white;
    border: none;
    @include border-radius(0 0 0 0);
    padding: 0;
    text-align: center;

    &:focus{
      outline: none;
    }

    .material-icons{
      @include rem(35);
    }
  }

  .limiter{
    padding-top: 135px;
    padding-bottom: 70px;
    max-width: 1255px;
    margin: 0 auto;
  }

  .document-finder{
    .title{
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      display: none;
      margin: 0 auto;
      color: $white;
      margin-top: 10px;
    }

    hr{
      width: 100vw;
      background-color: $white;
      position: absolute;
      right: 0;
      left: 0;
      margin-top: 20px;
      z-index: -1;
    }

    .circle{
      background-color: $white;
      border-radius: 30px;
      height: 40px;
      width: 40px;
      z-index: 1;
      margin: 0 auto;

      &:hover{
        background-color: $gray-light;
      }

      button{
        border: none;
        outline:none !important;
        outline-width: 0 !important;
        @include box-shadow(none);
        width: 40px;
        height: 40px;
        position: relative;
        transform: rotate(0deg);
        transition: all .6s;

        &.active {
          position: relative;
          transform: rotate(180deg);
        }
      }

      span{
        color: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
      }
    }

    .sub-title{
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      margin: 0 auto;
      text-align: center;
      color: $white;
      margin-top: 10px;
    }

    &.active{
      .limiter{
        padding-top: 25px;
        padding-bottom: 0;

        .title{
          display: block;
        }

        .sub-title{
          display: none;
        }
      }
    }
  }

  .filters{
    .select-view{
      display: none !important;
    }

    .section-padding{
      padding-bottom: 0 !important;
    }
  }

  .filters{
    height: 90vh;
    overflow-y: scroll;

    .faceted-filter-block{
      .section-padding{
        .limiter{
          &:first-child{
            padding-bottom: 40px !important;
          }
        }
      }

      &.results{
        .card-container{
          padding: 36px 25px 66px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .menus .container-fluid {
    padding: 0 30px;

    .flex-row {
      .menu-titles {
        text-align: right;
        width: 100%;

        .menu-title {
          text-align: right;
          margin-left: 10px;
          display: inline-block;

          &.active {
            margin-left: 10px;
            border: 0;
          }
        }
      }

      .menu-content {
        text-align: left;
        display: none;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.35;
        letter-spacing: 0.15px;
        width: 100%;

        &.open {
          display: block;
        }

        ul.menu-list {
          margin: 0;

          li {
            &> a {
              color: $white;
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md){
  #mainnav.megamenu .filters .faceted-filter-block.results .card-container{
    padding: 36px 65px 66px;
  }

  #mainnav.megamenu .filters .faceted-filter-block .show-only-on-menu{
    //display: block !important;
  }

  #mainnav.megamenu{
    .filters {
      .faceted-hide-filter{
        display: block;
        margin: 0 auto;
      }
    }
  }

  #mainnav{
    .menus{
      .menu-content-titles{
        width: auto;

        &.hide-me{
          display: block !important;
        }
      }

      .menu-content-titles + .menu-content-titles{
        margin-left: 15px;
      }

      .menu-content{
        &.open{
          min-width: 275px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #mainnav{
    .menus{
      .menu-content{
        max-width: 180px;
      }

      .menu-content-level-two{
        max-width: 180px;
      }
    }
  }
}

html.has-mouseover {
  .megamenu .menus .menu-content {
    a.link-arrow-left:hover,
    button.link-arrow-left:hover {
      color: $orange-bright;
    }

    .menu-list .menu-item:hover {
      a{
        color: #ffffff;
      }

      .icon-play {
        opacity: 1;
      }
    }

    .menu-list .menu-item.has-child a:hover {
      color: #ffffff;
    }

    .menu-list .menu-content-level-two.has-child a:hover {
      color: #ffffff;
    }
  }

  .megamenu .menus .menu-content-level-two {
    .menu-list-second-level li:hover{
      .icon-play {
        opacity: 1;
      }
    }

    .menu-list-second-level li a:hover{
      color: #ffffff;
    }
  }

  .megamenu .menus .menu-content-level-three {
    .menu-list-third-level li:hover{
      .icon-play {
        opacity: 1;
      }
    }

    .menu-list-third-level li a:hover{
      color: #ffffff;
    }
  }
}
