#newsletters-block {
  padding: 0 15px;

  .limiter-sidebar {
    .header-title {
      margin-bottom: 40px;
    }

    .content-link {
      margin-top: 30px;
      text-align: right;
    }

    .cart-content {
      background-color: $white;
      color: $gray-900;
      border-left: solid $color-text-dark-orange 3px;

      .date-content {
        margin-bottom: 10px;
        font-weight: 500;
      }

      .cart-content-text {
        padding: 40px 40px 34px 31px;
        position: relative;

        .sup-title {
          letter-spacing: 0.15px;
        }

        .text-body {
          margin-bottom: 0;
        }

        * + .text-body {
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #newsletters-block{
    padding: 0;

    .limiter-sidebar {
      .cart-container {
        max-width: 472px;
        margin-left: auto;

        .cart-content {
          .cart-content-text {
            padding: 40px 40px 40px 31px;

            .text-body {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #newsletters-block {
    .limiter-sidebar {
      .cart-container {
        max-width: 720px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #newsletters-block {
    .limiter-sidebar {
      .cart-container{
        max-width: 979px;
        padding-right: 59px;
      }
    }
  }
}
