.search-post-list-block {
  .section-padding {
    padding-top: 40px;
    padding-bottom: 0;

    .limiter {
      margin: 0 auto;
      margin-bottom: 41px;
      max-width: 540px;

      .container-fluid {
        .form-group {
          padding-right: 0;
          padding-left: 0;

          .input-field {
            margin-top: 20px;
            margin-bottom: 20px;

            .input-group-prepend {
              border: none;
              background-color: transparent;

              .input-group-text {
                position: absolute;
                top: 25%;
                padding-top: 3px;
                right: 0;
                background: none;
                border: none;
              }
            }

            &.input-outlined {
              > input {
                @include border-radius(6px);
                margin: 0;
              }
            }
          }
        }

        .faceted-hide-filter {
          text-align: center;

          p {
            margin-bottom: 8px;
            overflow-wrap: break-word;
          }

          .text-1 {
            display: none;
          }

          &.active {
            .text-1 {
              display: block;
            }

            .text-2 {
              display: none;
            }

            span.material-icons {
              @include transform (rotate(180deg));
            }
          }

          .icon-faceted-filter {
            width: 40px;
            height: 40px;
            @include border-radius(50px);
            color: $gray-900;
            background-color: $white;
            padding: 8px 7px 7px 8px;
            cursor: pointer;
          }
        }
      }
    }

    .tab-container {
      .col-md-12 {
        .nav-tabs {
          display: -webkit-box;
          flex-wrap: unset;
          width: 100%;
          overflow: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          padding-left: 20px;

          .nav-item {
            .nav-link {
              border: none;
              font-weight: 500;
              padding: 0 0 15px;
              @include rem-2(16, 24);
              text-align: center;
              margin: 0;
              overflow-wrap: break-word;
              border-bottom: 2px solid transparent;

              &.active {
                background-color: transparent;
                margin: 0;
                border: none;
                color: $orange;
                border-bottom: 2px solid $orange;
              }
            }
          }

          .nav-item + .nav-item {
            margin-left: 60px;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .tab-content {
          padding-top: 21px;
          padding-bottom: 39px;

          .radio-group {
            max-width: 289px;
            margin: 0 auto;

            .btn {
              background-color: transparent;
              border: 1px solid black;
              @include border-radius(21.5px);
              max-width: 289px;
              margin-top: 20px;
              color: $gray-900;

              input {
                position: relative;
                margin-left: -9px;
              }

              .input-group-prepend {
                .input-group-text {
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: none;
                  border: none;
                  padding: 9px 20px;

                  span {
                    @include rem(19);
                    @include border-radius(50px);
                  }
                }
              }
            }

            .active {
              background-color: $gray-light;
              color: $gray-900;

              .input-group-prepend {
                .input-group-text {
                  span {
                    background-color: $gray-900;
                    color: $white;
                  }
                }
              }
            }

            .btn + .btn {
              margin-left: 0;
            }
          }

          .apply-search {
            a + .cta-opt-1 {
              margin-left: 30px;
            }
          }

          * + .apply-search {
            margin: 40px 40px 0;
          }
        }
      }

      &.active {
        display: none;
      }
    }
  }
}

.theme-dark {
  .search-post-list-block {
    .section-padding {
      .limiter-540 {
        .container-fluid {
          .form-group {
            .input-field {
              .input-group-prepend {
                border: none;
                background-color: transparent;

                .input-group-text {
                  background: none;
                  border: none;
                }
              }
            }
          }

          .faceted-hide-filter {
            .icon-faceted-filter {
              border-color: $gray-900;
            }
          }
        }
      }

      .tab-container {
        .col-md-12 {
          .nav-tabs {
            .nav-item {
              .nav-link {
                &.active {
                  color: $orange-bright;
                  border-bottom: 2px solid $orange-bright;
                }
              }
            }
          }

          .tab-content {
            .radio-group {
              .btn {
                border: 1px solid $white;
                color: $white;

                .input-group-prepend {
                  .input-group-text {
                    background: none;
                    border: none;

                    span {
                      @include rem(19);
                      background-color: $white;
                      font-weight: 600;
                      height: 19px;
                      width: 19px;
                    }
                  }
                }

                &.active {
                  background-color: $white;
                  color: $gray-900;

                  .input-group-prepend {
                    .input-group-text {
                      span {
                        background-color: $gray-900;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .search-post-list-block {
    .section-padding {
      .limiter-540 {
        .container-fluid {
          .faceted-hide-filter {
            .icon-faceted-filter:hover {
              background-color: $gray-light;
            }
          }
        }
      }

      .tab-container {
        .col-md-12 {
          .nav-tabs {
            .nav-item {
              .nav-link:hover {
                color: $orange;
                border-bottom: 2px solid $orange;
              }
            }
          }

          .tab-content {
            .radio-group {
              .btn:hover {
                background-color: $gray-light;
                color: $gray-900;

                .input-group-prepend {
                  .input-group-text {
                    span {
                      background-color: $gray-900;
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .theme-dark {
    .search-post-list-block {
      .section-padding {
        .limiter-540 {
          .container-fluid {
            .faceted-hide-filter {
              .icon-faceted-filter:hover {
                background-color: $gray-light;
                color: $gray-900;
              }
            }
          }
        }

        .tab-container {
          .col-md-12 {
            .nav-tabs {
              .nav-item {
                .nav-link:hover {
                  color: $orange-bright;
                  border-bottom: 2px solid $orange-bright;
                }
              }
            }

            .tab-content {
              .radio-group {
                .btn:hover {
                  background-color: $white;
                  color: $gray-900;

                  .input-group-prepend {
                    .input-group-text {
                      span {
                        background-color: $gray-900;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search-post-list-block {
    .section-padding {
      padding-top: 40px;

      .limiter-540 {
        .container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .tab-container {
        .col-md-12 {
          .tab-content {
            .container-fluid {
              .col-md-12 {
                padding-right: 35px;
                padding-left: 35px;

                .radio-group {
                  max-width: 618px;

                  .btn {
                    margin-right: 10px;
                    margin-left: 10px;

                    input {
                      margin-left: -36px;
                    }
                  }
                }
              }
            }
          }

          .nav-tabs {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-post-list-block {
    .section-padding {
      .tab-container {
        .col-md-12 {
          .tab-content {
            .container-fluid {
              padding-right: 49px;
              padding-left: 48px;

              .col-md-12 {
                padding-right: 0px;
                padding-left: 0px;

                .radio-group {
                  max-width: 927px;

                  .btn {
                    input {
                      margin-left: -48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .search-post-list-block {
    .section-padding {
      .tab-container {
        .col-md-12 {
          .tab-content {
            .container-fluid {
              padding-right: 50px;
              padding-left: 50px;

              .col-md-12 {
                padding-right: 52px;
                padding-left: 52px;

                .radio-group {
                  max-width: 1236px;
                }
              }
            }
          }
        }
      }
    }
  }
}
