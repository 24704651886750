
.jquery-modal {
  z-index: 2001 !important;
  padding: 0 !important;
  overflow: hidden !important;

  &:before {
    content: none;
  }

  .modal-bio-detail {
    width: 100%;
    max-width: 100%;
    top: 100px;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

    .close-modal{
      display: none;
    }

    .close {
      position: fixed;
      right: 10px;
      top: 110px;
      z-index: 10;
      color: $gray-900;
      font-size: 35px;
      width: 35px;
      height: 35px;
      opacity: 1;

      .material-icons {
        font-size: 35px;
        width: 35px;
      }
    }

    .close:hover {
      color: $orange;
    }

    .modal-dialog {
      max-width: 100vW;
      top: 0;
      margin: 0;
      padding: 0;

      .modal-content {
        height: 100%;
        padding: 69px 15px 58px;
        display: block;

        .modal-body {
          padding: 0;
          padding-bottom: 40px;

          .modal-bio-info {
            border-bottom: 1px solid $gray-450;
            margin-bottom: 40px;

            .bio-img {
              width: 100%;
              max-width: 210px;
              max-height: 272px;
              margin: 0 auto;
              overflow: hidden;

              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }

            .container-modal-card {
              padding: 30px 0 0;

              .container-modal-card-header {
                padding-bottom: 28px;

                .title-bio-info {
                  font-family: $headings-font-family;
                  color: $gray-900;
                  @include rem-2(25, 27);
                }

                .sub-title-bio-info {
                  font-family: $font-family-sans-serif;
                  @include rem-2(20, 24);
                  color: $gray-900;
                  font-weight: 500;
                  letter-spacing: 0.15px;
                }

                * + .sub-title-bio-info {
                  margin-top: 10px;
                }

                .text-bio-info {
                  font-family: $font-family-sans-serif;
                  color: $gray-900;
                }

                * + .text-bio-info {
                  margin-top: 10px;
                }

                & + * {
                  border-top: 1px solid $gray-450;
                }
              }

              .container-modal-card-body {
                padding: 28px 0 30px;

                .title-bio-info {
                  font-family: $font-family-sans-serif;
                  font-weight: 500;
                  color: $gray-900;

                  p {
                    margin: 0;

                    a{
                      color: #0075ba;
                      font-weight: normal;
                    }
                  }
                }

                * + .title-bio-info {
                  margin-top: 15px;
                }

                .sub-title-bio-info {
                  font-family: $font-family-sans-serif;
                  color: $light-blue;
                }

                * + .sub-title-bio-info {
                  margin-top: 5px;
                }

                .text-bio-info {
                  font-family: $font-family-sans-serif;
                  color: $gray-900;
                }

                * + .text-bio-info {
                  margin-top: 5px;
                }
              }
            }
          }

          .modal-container-text {
            .title-text-bio {
              font-family: $font-family-sans-serif;
              font-weight: 500;
              color: $gray-900;
            }

            .text-bio {
              font-family: $font-family-sans-serif;
              color: $gray-900;
              margin: 0;
            }
          }
        }

        .modal-footer-content {
          padding: 30px 0 40px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .wrapper-prev {
            padding-left: 60px;
            width: 130px;
          }

          .wrapper-next {
            padding-right: 60px;
            width: 130px;
          }

          .wrapper-prev,
          .wrapper-next {
            display: flex;
            position: relative;

            .container-footer-bio-prev,
            .container-footer-bio-next {
              text-align: end;

              button.prev {
                background: transparent;
                border: none;
                padding: 0;
                margin-bottom: 70px;
                margin-top: 10px;
                @include border-radius(0);
                text-indent: 0;
                width: 100%;
                height: auto;

                &:after{
                  display: none;
                }

                .positioner-left{
                  display: inline-block;
                  height: 40px;
                  width: 40px;
                  position: absolute;
                  left: -55px;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                  transform: translateY(-50%);
                  border-radius: 50%;

                  .arrow-icon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                .header-footer-prev {
                  font-weight: 500;
                  color: $gray-900;
                }

                .body-footer-prev {
                  font-family: $headings-font-family;
                  @include rem-2(27, 29);
                  color: $light-blue;
                  margin-top: 8px;
                }

                .footer-footer-prev {
                  color: $light-blue;
                  margin-top: 5px;
                }
              }

              button.next {
                background: transparent;
                border: none;
                padding: 0;
                margin-bottom: 70px;
                margin-top: 10px;
                @include border-radius(0);
                text-indent: 0;
                width: 100%;
                height: auto;
                text-align: right;

                &:after{
                  display: none;
                }

                .positioner-right{
                  display: inline-block;
                  height: 40px;
                  width: 40px;
                  position: absolute;
                  right: -55px;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                  transform: translateY(-50%);
                  border-radius: 50%;

                  .arrow-icon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                .header-footer-next {
                  font-weight: 500;
                  color: $gray-900;
                }

                .body-footer-next {
                  font-family: $headings-font-family;
                  @include rem-2(27, 29);
                  color: $light-blue;
                  margin-top: 8px;
                }

                .footer-footer-next {
                  color: $light-blue;
                  margin-top: 5px;
                }
              }
            }
          }

          .clear-both {
            clear: both;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .jquery-modal {
    .modal-bio-detail {
      .modal-dialog {
        .modal-content {
          padding: 69px 39px 58px;

          .modal-body {
            .modal-bio-info {
              display: flex;
              flex-direction: row;

              .container-modal-card {
                padding: 0 0 40px 30px;
                width: 100%;
                flex: 1;

                .container-modal-card-body {
                  padding: 28px 0 0px;
                }
              }
            }
          }

          .modal-footer-content {
            .wrapper-prev {
              width: auto;
            }

            .wrapper-next {
              width: auto;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .jquery-modal {
    .modal-bio-detail {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bio-info {
              padding-bottom: 30px;

              .bio-img {
                max-width: 255px;
                max-height: 330px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal-bio-detail {
    .modal-body,
    .modal-footer-content {
      max-width: 920px;
      margin: 0 auto;
    }

    .modal-footer {
      width: 100%;

      .container-footer-bio-before,
      .container-footer-bio-after {
        width: 50%;
      }
    }
  }
}

html.has-mouseover{
  .jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-footer-content .wrapper-next .container-footer-bio-next button:hover{
    .body-footer-next{
      color: #005486;
    }

    .footer-footer-next{
      color: #005486;
    }
  }

  .jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-body .modal-bio-info .container-modal-card .container-modal-card-body{
    .title-bio-info a:hover{
      color: #005486;
    }
  }

  .jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-footer-content .wrapper-next .container-footer-bio-next button .positioner-right:hover{
    background-color: #e1e1e1;
  }

  .jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-footer-content .wrapper-prev .container-footer-bio-prev button .positioner-left:hover{
    background-color: #e1e1e1;
  }

  .jquery-modal .modal-bio-detail .modal-dialog .modal-content .modal-footer-content .wrapper-prev .container-footer-bio-prev button:hover{
    .body-footer-prev{
      color: #005486;
    }

    .footer-footer-prev{
      color: #005486;
    }
  }
}
