.modal {
  &.with-forms {
    height: auto;
    margin-top: 86px !important;
    @include border-radius(4px);
  }
}

.sign-in {
  .container-fluid {
    font-family: $font-family-sans-serif;
    padding: 0 15px;

    .sign-title {
      text-align: center;

      p {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .sign-body {
      text-align: center;
      margin-bottom: 30px;

      p {
        margin: 0;
      }

      [class^="h"] {
        margin: 0;

        & + p {
          margin-top: 20px;
        }
      }
    }

    .sign-form {
      width: 100%;
      max-width: 325px;

      .input-field {
        width: 100%;
        margin: 0;
        margin-bottom: 31px;

        .validate {
          margin: 0;
        }

        label {
          max-width: 400px;
        }
      }

      .sign-button {
        width: 154px;
        font-size: 16px;
        background-color: $gray-300;
        border: 1px solid $gray-900;

        &::after {
          right: 30px;
        }
      }
    }

    .formulate-wrapper{
      width: 100%;

      .formulate__form{
        width: 100%;

        .formulate__row{
          .formulate__cell{
            display: flex;
            justify-content: center;

            .formulate__field{
              width: 100%;
              margin: 0;
              position: relative;
              display: flex;
              flex-wrap: wrap;

              label{
                max-width: 375px;
                margin: 0;
                bottom: 17px;
                left: 16px;
                @include rem(16);
                line-height: 24px;
                color: #424647;
                float: left;
                margin-top: 15px;
                flex: 0 0 40%;

                input{
                  display: none;
                }

                &:nth-child(even) {
                  flex: 0 0 60%;
                }

                &.active{
                  color: #007dba;
                  @include rem(12);
                  line-height: 16px;
                  bottom: 37px;
                }
              }

              input{
                max-width: 315px;
                width: 100%;
                margin: 0;
                padding: 0 15px;
                height: 56px;
                border-radius: 3px;
                border: solid 1px #757575;
                background-color: $white;

                @include rem(16);
                line-height: 24px;
                color: #424647;
              }

              button{
                position: relative;
                display: table;
                margin: 0 auto;
                width: 154px;
                font-size: 16px;
                background-color: #fafafa;
                border: 1px solid #424647;
                padding: 12px 30px 12px 0;

                &:after{
                  position: absolute;
                  display: block;
                  top: 50%;
                  right: 34px;
                  -webkit-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                  transform: translateY(-50%);
                  font-family: $icofont;
                  font-size: 20px;
                  font-size: 1.25rem;
                  content: "\ea5d";
                }

                &:hover{
                  color: #ba4d01;
                }
              }

              &.formulate__field--text {
                max-width: 325px;

                label {
                  margin: 0;
                  position: absolute;
                }

                .error-message {
                  position: absolute;
                  top: 58px;
                }
              }
            }

            .formulate__field--checkbox-list {
              [type=checkbox]{
                +span:not(.lever):before{
                  border: 2px solid #007dba;
                }

                &:checked{
                  +span:not(.lever):before{
                    border-top: 2px solid transparent;
                    border-left: 2px solid transparent;
                    border-right: 2px solid #007dba;
                    border-bottom: 2px solid #007dba;
                  }
                }
              }

              .formulate__field__label {
                .formulate__field__label-text {
                  &:before {
                    top: auto;
                  }
                }
              }
            }
          }

          & + .formulate__row{
            margin-top: 30px;
          }
        }
      }

      .form-success{
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal {
    &.with-forms {
      max-width: 690px;
      margin-top: 107px !important;
    }
  }

  .sign-in {
    .container-fluid {
      padding: 0 81px;

      .sign-form {
        max-width: 445px;
      }

      .formulate-wrapper {
        .formulate__form {
          .formulate__row {
            .formulate__cell {
              .formulate__field {
                label {
                  flex: 0 0 33.3333%;

                  &:nth-child(even) {
                    flex: 0 0 33.3333%;
                  }
                }

                &.formulate__field--text {
                  max-width: 445px;

                  input {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal {
    &.with-forms {
      max-width: 930px;
    }
  }

  .sign-in {
    .container-fluid {
      .formulate-wrapper {
        .formulate__form {
          .formulate__row {
            .formulate__cell {
              .formulate__field {
                label {
                  flex: 0 0 25%;

                  &:nth-child(even) {
                    flex: 0 0 25%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal {
    &.with-forms {
      max-width: 1110px;
    }
  }
}
