.tabs-generic-block {
  .tab-container {
    padding-right: 0;
    padding-left: 0;

    .tab-list {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .wysiwyg-content + .tab-container {
    margin-top: 20px;
  }

  .tab-content .tab-pane {
    padding-top: 40px;
  }

  .tab-content {
    padding-right: 25px;
    padding-left: 25px;

    img + .caption {
      margin-top: 5px;
    }
  }

  ul.nav.nav-tabs {
    display: -webkit-box;
    flex-wrap: unset;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    border-bottom: 0;
    padding-left: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      + li {
        margin-left: 60px;
      }

      &.nav-item {
        margin-bottom: 0;

        a.nav-link {
          font-weight: 500;
          margin: 0;

          :link {
            color: $gray-900 !important;
          }

          padding: 0;
          padding-bottom: 19px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .tabs-generic-block {
    .tab-content {
      padding-right: 40px;
      padding-left: 40px;
    }

    ul.nav.nav-tabs {
      padding-left: 40px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .tabs-generic-block {
    .tab-content {
      padding-right: 50px;
      padding-left: 50px;
    }

    ul.nav.nav-tabs {
      padding-left: 50px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .tabs-generic-block {
    .wysiwyg-content {
      max-width: 1210px;
      margin: 0 auto;
    }

    .tab-content {
      max-width: 1210px;
      margin: 0 auto;
    }

    ul.nav.nav-tabs {
      max-width: 1210px;
      margin: 0 auto;
    }
  }
}
