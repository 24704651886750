.faceted-filter-block .facets-results .row .wysiwyg-field{
  color: inherit !important;
}

.wysiwyg-field,
.mce-content-body{
  word-break: break-word;
  overflow-wrap: break-word;

  .umbraco-forms-form{
    table{
      display: table;
      padding: 0;
      margin: 0;
      width: auto;
      height: auto;

      tbody{
        th,
        td{
          min-width: auto;
          padding: 0;
          margin: 0;
        }
      }
    }

    * + table{
      margin-top: 0;
    }

    ul{
      padding-left: 0;
      list-style-image: none;
      list-style: none;

      ul{
        list-style-image: none;
        margin-left: auto;
      }

      li{
        list-style: none;

        &:before{
          content: none;
        }

        li{
          &:before{
            border: none;
          }
        }
      }
    }
  }

  img{
    height: auto;
    max-width: 100%;
  }

  table{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    width: 100% !important;
    height: 100% !important;
    overflow: auto;

    tbody{
      th,
      td{
        min-width: 180px;
        padding: 15px 20px;
        border-color: #d8d8d8;
        font-weight: 500;
        @include rem-2(16, 20);
      }

      tr:first-child{
        background-color: rgba(0, 54, 131, 0.05);
        color: $gray-900;
        @include rem-2(17, 20);
      }
    }
  }

  * + table{
    margin-top: 26px;
  }

  button{
    a{
      color: inherit;
    }
  }

  a{
    color: $link-default;
    @include rem(16);
    line-height: 24px;

    &.link-arrow,
    &.link-arrow-left{
      color: $gray-900;
      display: inline;
    }
  }

  .cta-opt-1,
  .cta-opt-2,
  .cta-opt-2-back{
    background-color: $gray-50;
    color: $gray-900;
    display: inline-block;
  }

  > * + *{
    margin-top: 15px;
  }

  p{
    margin-bottom: 0;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6{
    + p{
      margin-top: 15px;
    }
  }

  h4 + h1,
  h4 + .h1,
  .h4 + h1,
  .h4 + .h1{
    margin-top: 10px;
  }

  .h6 + .h4{
    margin-top: 20px;
  }

  .h4 + p.large{
    margin-top: 20px;
  }

  &.title,
  &.title p{
    font-weight: 500;
  }

  p.large + p{
    margin-top: 20px;
  }

  p + .h3{
    margin-top: 15px;
  }

  ul,
  ol {
    li{
      padding-top: 1px;
    }
  }

  ol{
    counter-reset: item;
    padding-left: 0;

    li{
      display: block;

      ol{
        margin-left: 20px;
      }

      &:before{
        content: counter(item) ". "  ;
        counter-increment: item;
        color: $color-text-dark-orange;
        font-weight: bold;
      }
    }
  }

  ul{
    padding-left: 15px;
    list-style-image: url("../assets/images/oval-bright.svg");

    ul{
      list-style-image: url("../assets/images/oval-nofill-bright.svg");
      margin-left: 20px;
    }

    li{
      position: relative;
      list-style: none;

      &:before{
        content: '';
        position: absolute;
        top: 9px;
        left: -17px;
        width: 7px;
        height: 7px;
        background-color: $color-text-dark-orange;
        @include border-radius(50%);
      }

      li{
        &:before{
          background-color: transparent;
          border: 1px solid $color-text-dark-orange;
        }
      }
    }
  }

  * + ul.stats{
    margin-top: 36px;
  }

  .content-text{
    .header{
      word-break: break-word;
      padding: 0;

      .title{
        margin-bottom: 0;
      }

      * + .subtitle{
        margin-top: 5px;
      }
    }
  }

  .three-col-block{
    flex: 0 0 100%;
    max-width: 100%;

    p + p {
      margin-top: 15px;
    }
  }
}

.wysiwyg-field.body-hidden{
  &::before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white);
  }
}

.bg-neutral-dark-blue{
  .wysiwyg-field.body-hidden{
    &::before{
      background: linear-gradient(to bottom, rgba(28, 34, 64, 0) 50%, $blue);
    }
  }
}

.bg-neutral-light{
  .wysiwyg-field.body-hidden{
    &::before{
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white);
    }
  }
}

.bg-neutral-medium{
  .wysiwyg-field.body-hidden{
    &::before{
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $gray-100);
    }
  }
}

.bg-neutral-dark{
  .wysiwyg-field.body-hidden{
    &::before{
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $gray-200);
    }
  }
}

.bg-dark-blue{
  .wysiwyg-field{
    &.body-hidden{
      &::before{
        background: linear-gradient(to bottom, rgba(28, 34, 64, 0) 50%, $blue);
      }
    }

    hr{
      background-color: $white;
    }
  }
}

.theme-dark{
  .wysiwyg-field{
    color: $white;

    a{
      color: $blue-light;

      &.link-arrow,
      &.link-arrow-left,
      &.cta-opt-1,
      &.cta-opt-2,
      &.cta-opt-2-back{
        color: $gray-900;
      }
    }

    a.cta-opt-2,
    a.cta-opt-2-back{
      color: $white;
      background-color: transparent;
    }

    ol{
      li{
        &:before{
          color: $orange-bright;
        }
      }
    }

    ul{
      li{
        &:before{
          background-color: $orange-bright;
        }

        li:before{
          background-color: transparent;
          border-color: $orange-bright;
        }
      }

      &.stats{
        li{
          & + li{
            &:before{
              background: white;
            }
          }
        }
      }
    }
  }
}

.accordion-content{
  .wysiwyg-field{
    color: $gray-900;
  }
}

.wysiwygBlock{
  .wysiwyg-block{
    .section-padding{
      .limiter-700{
        .container-fluid{
          padding: 0 25px;
        }
      }
    }
  }
}

html.has-mouseover{
  .wysiwyg-field{
    button.cta-opt-1:hover,
    button.cta-opt-2:hover,
    button.cta-opt-2-back:hover{
      a{
        background-color: $gray-light;
        color: #424647;
      }
    }

    button:hover{
      a{
        color: inherit;
      }
    }
  }

  .theme-dark{
    .wysiwyg-field{
      a.cta-opt-2:hover,
      a.cta-opt-2-back:hover{
        background-color: $white;
        color: $gray-900;
      }

      a.link-arrow:hover,
      a.link-arrow-left:hover{
        color: #ba4d01;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .wysiwyg-field,
  .mce-content-body{
    .umbraco-forms-form{
      table{
        display: table;
        width: auto;

        th,
        td{
          &:last-child{
            min-width: auto;
          }
        }
      }
    }

    .content-text{
      .header{
        padding: 0;
      }
    }

    table{
      display: inline-table;
      width: 100% !important;

      th,
      td{
        &:last-child{
          min-width: 149px;
        }
      }
    }

    .three-col-block{
      margin: 0;
      flex: initial;
      max-width: inherit;

      &.three-col-2{
        flex: 0 0 50%;
        max-width: 50%;
      }

      &.three-col-3{
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }
    }
  }

  .wysiwygBlock{
    .wysiwyg-block{
      .section-padding{
        .limiter-700{
          .container-fluid{
            padding: 0 15px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .wysiwyg-field,
  .mce-content-body{
    .umbraco-forms-form{
      table{
        &.table{
          th,
          td{
            &:last-child{
              min-width: auto;
            }
          }
        }
      }
    }
  }

  .wysiwyg-field,
  .mce-content-body{
    ul{
      ul{
        padding-left: 12px;
      }
    }

    .content-text{
      .header{
        padding: 0;
      }
    }

    table{
      &.table{
        th,
        td{
          &:last-child{
            min-width: 156px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .wysiwyg-field,
  .mce-content-body{
    .umbraco-forms-form{
      table{
        &.table{
          th,
          td{
            &:last-child{
              min-width: auto;
            }
          }
        }
      }
    }
  }

  .wysiwyg-field,
  .mce-content-body{
    .content-text{
      padding: 0;

      .header{
        padding: 0;
      }
    }

    table{
      &.table{
        th,
        td{
          &:last-child{
            min-width: 160px;
          }
        }
      }
    }
  }
}
