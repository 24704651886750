.background-styles-block {
  .container-fluid {
    .row{
      .content-wrapper {
        width: 100%;
        flex-wrap: wrap;

        .body {
          padding: 90px 25px 90px 40px;

          .info {
            font-family: $font-family-sans-serif;
            word-break: break-word;
            padding: 0;
            @include rem-2(16, 20);
            font-weight: 500;
            margin: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .background-styles-block {
    .container-fluid {
      .row{
        .content-wrapper {
          .body {
            padding: 90px 41px 90px 35px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .background-styles-block {
    .container-fluid {
      .row{
        .content-wrapper {
          .body {
            padding: 140px 0;
          }
        }
      }
    }
  }
}
