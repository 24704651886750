@use "sass:math";

.text-image-three-columns{
  .col-text{
    padding: 0 25px;

    .text-field {
      .h3{
        margin: 0;
      }
    }

    .image img {
      width: 100%;
      height: auto;
      max-width: 290px;
    }

    * + *{
      margin-top: 22px;
    }

    * + .image{
      margin-top: 20px;
    }
  }

  .col-title{
    padding: 40px 25px 0;

    > .title{
      margin: 0;

      + .sub-title{
        margin-top: 20px;
      }
    }

    + .col-text{
      margin-top: 37px;
    }
  }

  .col-image {
    .image {
      margin-left: #{- math.div($grid-gutter-width, 2)};
      margin-right: #{- math.div($grid-gutter-width, 2)};

      img{
        width: 100%;
        height: auto;
      }
    }
  }

  * + .col-image{
    margin-top: 34px;
  }
}

.limited-to-1440 .text-image-three-columns {
  .col-text{
    max-width: 480px;
  }

  .col-image{
    max-width: 600px;
  }
}

@include media-breakpoint-up(md) {
  .text-image-three-columns{
    .col-text{
      padding: 60px 40px 0 35px;
      padding-top: 60px;

      .image{
        justify-content: flex-start !important;
      }

      * + *{
        margin-top: 20px;
      }

      * + .image{
        margin-top: 10px;
      }
    }

    .col-title {
      padding-top: 60px;

      .sub-title{
        max-width: 260px;
      }

      + .col-text{
        margin-top: 0
      }
    }

    > .container-fluid > .row{
      justify-content: flex-end;
    }

    .col-image{
      padding: 0 40px 60px 35px;

      .image{
        margin-left: 0;
        margin-right: 0;
      }
    }

    * + .col-image{
      margin-top: 47px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .text-image-three-columns{
    > .container-fluid > .row{
      justify-content: flex-start;
    }

    .col-title{
      padding-bottom: 60px;
    }

    .col-image{
      padding: 60px 46px 60px 15px;
      display: flex;

      .image {
        width: 100%;
      }
    }

    .col-text{
      padding: 60px 15px 60px 35px;

      + .col-image{
        margin-top: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .text-image-three-columns{
    .col-title {
      padding: 100px 55px 60px 25px;
    }

    .container-fluid {
      .row {
        .col-text{
          padding: 60px 15px 60px 60px;
          display: flex;
          max-width: 33.33%;

          .col-text-inner-wrapper {
            width: 100%;
          }
        }

        .col-image{
          padding: 60px 72px 60px 15px;
          max-width: 41%;

          .image {
            width: 100%;
          }
        }
      }
    }
  }
}
