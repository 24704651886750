.faceted-filter-block.results{
  .card-view,
  .card-container{
    .select-view {
      padding-right: 16px;
    }

    form{
      .select2-container{
        width: 160px !important;
        z-index: 2040;

        .selection{
          .select2-selection{
            height: auto;
            @include border-radius(3px);
            border: solid 1px #757575;
            outline: none;

            .select2-selection__rendered{
              padding: 15px 16px;
              color: $gray-900;

              &:focus{
                outline: none;
              }
            }

            .select2-selection__arrow{
              height: 100%;
              position: absolute;
              top: 0;
              right: 10px;
              width: 20px;

              b{
                display: none;
              }

              .icon-dropdown{
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
                outline: none;
              }
            }
          }

          span[aria-expanded^="true"] {
            border: solid 1px $light-blue;
          }
         }

        &.select2-container--open{
          .selection .select2-selection .select2-selection__arrow .icon-dropdown{
            @include transform-2(translateY(-50%), rotate(180deg));
          }
        }
      }
    }
  }
}

.select2-container--open {
  .select2-dropdown {
    z-index: 9999;

    .select2-results {
      .select2-results__options {
        .select2-results__option--highlighted {
          background-color: #f2f2f2;
          color: $gray-900;
        }
      }
    }
  }
}

.order-dates-dropdown{
  .select2-results{
    .select2-results__options{
      .select2-results__option{
        color: #424647;
        @include rem-2(16, 24);

        &.select2-results__option--selected{
           background-color: #f2f2f2;
        }
      }
    }
  }
}

.faceted-filter-block{
  .facets-results{
    .date-order{
      .label-date{
        line-height: 56px;
        color: black;
        font-weight: 500;
        color: #424647;
        margin-right: 10px;
      }

      .select2{
        .selection{
          .select2-selection{
            position: relative;
            height: 56px;
            border-radius: 3px;
            border: solid 1px #757575;
            background-color: #f4f4f4;

            .select2-selection__rendered{
              line-height: 56px;
            }

            .select2-selection__arrow{
              display: none;
            }

            span.material-icons{
              position: absolute;
              right: 10px;
              top: 15px;
              color: black;
              @include transform(rotate(0deg));
            }
          }
        }

        &.select2-container--open{
          .selection{
            .select2-selection{
              span.material-icons{
                @include transform(rotate(180deg));
              }
            }
          }
        }
      }
    }
  }
}

