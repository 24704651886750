.faceted-filter-block{
  .filters-section{
    .tab-content{
      .tab-pane[data-tab="dates"]{
        .container-dates{
          .label-dates{
          }

          .date-range{
            .date-start,
            .date-end{
              position: relative;
              width: 100%;

              &.padding-mobile{
                padding-bottom: 243px;
              }

              input{
                width: calc(100% - 15px);
                background-color: #ffffff;
                border-radius: 6px;
                border: solid 1px #757575;
                margin: 0;
                max-width: 100%;
                height: 18px;
                padding: 27px 15px 9px!important;

                &:placeholder{
                  font-size: 16px;
                  line-height: 1.5;
                  color: #424647;
                }
              }

              .label-text{
                @include rem(14);
                line-height: 16px;
                color: rgba(255, 255, 255, 0.9);
              }

              .label-date{
                position: absolute;
                top: 28px;
                left: 15px;
                color: #ba4d01;
                @include rem(12);
                line-height: 16px;
                letter-spacing: normal;
                margin: 0;
                background-color: #fff;
              }
            }

            .date-start + .date-end{
              margin-top: 15px;
            }
          }

          * + .date-range{
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    .filters-section{
      .tab-content{
        .tab-pane[data-tab="dates"]{
          .container-dates{
            .date-range{
              .date-start,
              .date-end{
                width: 135px;

                input{
                  height: 50px;
                }

                + *{
                  margin-left: 20px;
                }
              }

              .date-start + .date-end{
                margin-top: 0;
              }
            }

            * + .date-range{
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.daterangepicker{
  .drp-calendar{
    .calendar-table{
      table{
        thead{
          tr{
            th{
              select{
                display: block !important;
                width: 50%;
                float: left;
                margin: 0;
              }
            }
          }
        }
      }

      td, th{
        color: black;

        &.active{
          color: white;
        }
      }
    }
  }
}

