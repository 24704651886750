.management-tab-block {
  word-break: break-word;

  .tab-container {
    .tabs-wrapper {
      .tab-list {
        padding: 40px 45px 50px;

         .nav-tabs {
           a.nav-link:focus {
            border: none;
          }
        }

        ul.nav-pills {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: none;

          .button-block {
            border-color: $gray-900;
            @include border-radius(5);
            width: 100%;

            .btn-outline-dark {
              background-color: $gray-300;
              @include rem-2(16, 24);
              padding: 22px 18px;
              font-weight: 500;
              text-align: center;
              color: $gray-900;
              border: solid 1px #424647;

              &.active {
                background-color: $blue;
                color: $white;
                border: solid 1px $white;
              }
            }
          }

          li + li {
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .management-tab-block {
    .tab-container {
      .tabs-wrapper {
        .tab-list {
          ul.nav-pills {
            .button-block {
              .btn-outline-dark:hover,
              .btn-outline-dark:focus {
                background-color: $blue;
                color: $white;
                border: solid 1px $white;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .management-tab-block {
    .tab-container {
      .tabs-wrapper {
        .tab-list {
          padding: 50px 45px;

          ul.nav-pills {
            flex-direction: row;

            .button-block {
              max-width: 162px;

              .btn-outline-dark {
                padding: 20px 18px 22px;
              }
            }

            li + li {
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .management-tab-block {
    .tab-container {
      .tabs-wrapper {
        .tab-list {
          padding: 50px 51px;

          ul.nav-pills {
            .button-block {
              max-width: 223px;

              .btn-outline-dark {
                padding: 22px 20px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .management-tab-block {
    .tab-container {
      .tabs-wrapper {
        .tab-list {
          padding: 50px 0;
          max-width: 932px;
          margin: 0 auto;
        }
      }
    }
  }
}
