html{
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.scroll-block{
    overflow: hidden;

    body{
      overflow: hidden;
    }
  }

  body.fixed{
    overflow: hidden;
  }

  body{
    margin-top: 105px;
    top: 0 !important;
  }

  iframe[name="printIframe"]{
    display: none;
  }

  .mce-content-body{
    margin: 0;
    padding: 10px;
  }
}

html.has-mouseover a:focus,
html.has-mouseover a:hover,
html.has-mouseover button:focus,
html.has-mouseover button:hover,
html.has-mouseover input:focus,
html.has-mouseover input:hover {
  outline: none;
}

.section-row.newsroomSignUp{
  z-index: 101;
}

.b-lazy{
  opacity: 0;
  transition: all 500ms;
}

.b-loaded{
  opacity:1;
}

.goog-text-highlight{
  box-shadow: none !important;
  background: transparent !important;
}

.important-show{
  display: block !important;
}

html body.has-stories-block{
  .jquery-modal.blocker,
  a.newsroom-cta{
    z-index: 1019 !important;
  }
}

@include media-breakpoint-up(lg) {
  html {
    body{
      margin-top: 60px;
    }
  }
}

/**
* @version         1.0
* @lastmodified    16.06.2016
*/

@media print {
  #content, #page {
    width: 100%;
    margin: 0;
    float: none;
    background-color: #ffffff !important;
  }

  body{
    background-color: #ffffff !important;

    img {
      max-width: 225px;
      height: auto;
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
}

