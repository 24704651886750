.limiter-1056,
.limiter-1110,
.limiter-1210,
.limiter-1260,
.limiter-1440,
.limiter-700,
.limiter-730,
.limiter-790,
.limiter-900,
.limiter-920,
.limiter-1080,
.limiter-1220 {
  width: 100%;
  margin: 0 auto;
}

.limiter-1110 {
  max-width: 1110px;
}

.limiter-1220{
  max-width: 1220px;
}

.limiter-1440 {
  max-width: 1440px;
}

.limiter-920 {
  max-width: 920px;
}

.limiter-1080 {
  max-width: 1080px;
}

.limiter-1056 {
  max-width: 1056px;
}

.limiter-1210 {
  max-width: 1210px;
}

.limiter-1260 {
  max-width: 1260px;
}

.limiter-700 {
  max-width: 700px;
}

.limiter-790 {
  max-width: 790px;
}

.limiter-730 {
  max-width: 730px;
}

@include media-breakpoint-up(md) {
  .limiter-sidebar {
    max-width: 729px;
  }

  .limiter-1110-FeaturedStories, .limiter-1110-FeaturedTeaser {
    width: 729px;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(lg) {
  .limiter-sidebar {
    max-width: 977px;
  }

  .limiter-1110-FeaturedStories {
    width: 977px;
  }
}

@include media-breakpoint-up(xl) {
  .limiter-sidebar {
    max-width: 1007px;
    margin: 0 auto;
  }

  .limiter-1110-FeaturedStories {
    margin: 0 auto;
    width: 1110px;
  }
}
