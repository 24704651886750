.card-grid-block{
   word-break: break-word;

   &.listing{
     .card-container-fluid{
       .row + .global-search-pagination{
         margin-top: 58px;
       }
     }
   }

  .card-container-fluid{
    padding: 0 15px;

    .title-cart-grid{
      margin: 0;
    }

    .card-container-row{
      .main-item{
        margin-bottom: 0;

        .card-teaser{
          border: none;
          border-radius: initial;
          border-bottom: 2px solid $color-text-dark-orange;
          height: 100%;
          padding-bottom: 30px;

          .image{
            max-height: 150px;
            overflow: hidden;

            img{
              width: 100%;
            }

            + *{
              margin-top: 20px;
            }
          }

          .card-body{
            padding: 0;

            .card-text-img{
              font-family: $font-family-sans-serif;
              @include rem-2(14, 16);
            }

            .card-title{
              font-family: $font-family-sans-serif;
              @include rem-2(20, 24);
              font-weight: 500;
              margin: 0;

              p{
                margin: 0;
              }
            }

            * + .card-title{
              margin-top: 10px;
            }

            .card-text{
              font-family: $font-family-sans-serif;
              @include rem-2(16, 24);
            }

            + *{
              margin-top: 21px;
            }
          }

          .teaser-cta{
            padding: 0;
          }

          * + .content-arrow{
            margin-top: 20px;
          }

          .caption{
            margin-top: 5px;

            & + .card-body{
              padding-top: 10px;
            }
          }
        }
      }

      .main-item + .main-item{
        margin-top: 40px;
      }
    }

    .pagination-generated{
      * + .pagination-content{
        margin-top: 72px;
      }
    }

    * + .card-container-row{
      margin-top: 20px;
    }
  }
}

//This section is for Featured content teaser Stories
.modal-featured-content{
  &.modal{
    .close-article{
      margin: 123px 0 30px 15px;

    }

    .material-icons{
      margin: 123px 15px 20px 0;
      @include rem(35);
    }
  }

  &.modal .material-icons:hover{
    color: $orange;
  }

  &.modal .close-article:hover{
    color: $orange;
  }
}

@include media-breakpoint-up(md) {
  .card-grid-block{
    &.listing{
      .card-container-fluid{
        padding-left: 40px;
        padding-right: 40px;
        max-width: 720px;

        .card-container-row{
          &.stories{
            .main-item{
              margin-top: 0;

              &:nth-child(n + 3) {
                margin-top: 0;
              }

              &:nth-child(n+5) {
                margin-top: 20px;
              }
            }
          }

          &.blogs{
            .main-item{
              margin-top: 0;

              &:nth-child(n+3) {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    .card-container-fluid{
      margin: 0 auto;
      padding: 0 39px;

      .card-container-row{
        .main-item{
          padding: 0 15px;

          .card-teaser{
            .card-body{
              padding: 0;
            }

            .teaser-cta{
              padding: 0;
            }
          }
        }

        .main-item {
          + .main-item{
          margin-top: 0;
          }

          &:nth-child(n + 3) {
            margin-top: 40px;
          }
        }
      }
    }
  }

  .modal-featured-content{
    &.modal{
      .close-article{
        margin: 144px 0 30px 36px;
      }

      .material-icons{
        margin: 135px 33px 20px 0;
        @include rem(35);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .card-grid-block{
    &.listing{
      .card-container-fluid{
        max-width: 960px;
        padding-left: 50px;
        padding-right: 50px;

        .card-container-row{
          &.stories{
            .main-item{
              &:nth-child(n+5) {
                margin-top: 0;
              }

              &:nth-child(n+7) {
                margin-top: 68px;
              }
            }
          }

          &.blogs{
            .main-item{
              &:nth-child(n+3) {
                margin-top: 0;
              }

              &:nth-child(n+4) {
                margin-top: 68px;
              }
            }
          }
        }
      }
    }

    .card-container-fluid{
      padding: 0 47px;
      margin: 0 auto;
      max-width: 979px;

      .card-container-row{
        .main-item {
          &:nth-child(n + 3) {
            margin-top: 0;
          }

          &:nth-child(n + 4) {
            margin-top: 40px;
          }
        }
      }
    }
  }

  .modal-featured-content{
    .modal{
      .close-article{
        margin: 144px 0 30px 36px;
      }

      .material-icons{
        margin: 135px 32px 20px 0;
        @include rem(35);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .card-grid-block{
    &.listing{
      .card-container-fluid{
        max-width: 979px;

        .card-container-row{
          &.stories{
            .main-item{
              &:nth-child(n+7) {
                margin-top: 30px;
              }
            }
          }

          &.blogs{
            .main-item{
              &:nth-child(n+4) {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }

    .card-container-fluid{
      .card-container-row{
        .main-item + .main-item{
          &:nth-child(3) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .featuredContentTeaser{
    .limiter-sidebar{
      .card-grid-block{
        .card-container-fluid{
          padding: 0 50px;
        }
      }
    }

    .limiter-FeaturedStories{
      .card-container-fluid{
          margin: 0 auto;
      }
    }
  }
}
