.custom-html-block {
  overflow-wrap: break-word;
  padding: 150px 16px 128px 18px;

  h2 {
    letter-spacing: 0.15px;
  }
}

@include media-breakpoint-up(md) {
  .custom-html-block {
    padding: 190px 213px 196px 214px;
  }
}

@include media-breakpoint-up(lg) {
  .custom-html-block {
    padding: 190px 341px 196px 342px;
  }
}

@include media-breakpoint-up(xl) {
  .custom-html-block {
    padding: 279px 537px 294px 539px;
  }
}
