.hero-featured-post {
  .container-fluid {
    padding: 70px 15px 70px;

    .hero-featured-container {
      .featured-image{
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        height: 250px;
        overflow: hidden;

        img {
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }
      }

      .featured-text {
        .content-category {
          display: inline-block;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        .content-date {
          color: $color-text-dark-orange;
          margin-bottom: 10px;
        }

        h3 {
          margin-bottom: 30px;
        }
      }

      .link-arrow {
        display: inline-block;
        width: 183px;
        height: 48px;
        line-height: 48px;
        padding: 0;
        padding-left: 35px;
        border: 1px solid $gray-900;

        &::after {
          right: 35px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-featured-post {
    .container-fluid {
      padding: 70px 39px 70px;

      .hero-featured-container {
        .featured-image {
          width: inherit;
          min-width: 330px;
          min-height: 283px;
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-featured-post {
    .container-fluid {
      padding: 70px 47px 93px;

      .hero-featured-container {
        .featured-image {
          min-width: 453px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero-featured-post {
    .container-fluid {
      padding: 70px 165px 70px;

      .hero-featured-container {
        .featured-image {
          min-width: 540px;
          min-height: 304px;
        }
      }
    }
  }
}

