#hero-featured-post {
  .container-fluid {
    padding: 170px 15px 70px;

    .hero-featured-container {
      .featured-image{
        margin-bottom: 20px;
        width: 100%;
      }

      .featured-text {
        .content-category {
          text-transform: uppercase;
          margin-bottom: 15px;
          font-weight: 500;
        }

        .content-date {
          color: $color-text-dark-orange;
          margin-bottom: 10px;
          font-weight: 500;
        }

        h3 {
          margin-bottom: 30px;
        }
      }

      .link-arrow {
        display: inline-block;
        width: 183px;
        height: 48px;
        line-height: 3rem;
        padding: 0;
        padding-left: 35px;
        border: 1px solid $gray-900;

        &::after {
          right: 35px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #hero-featured-post {
    .container-fluid {
      padding: 171px 39px 70px;

      .hero-featured-container {
        .featured-image {
          min-width: 330px;
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #hero-featured-post {
    .container-fluid {
      padding: 129px 47px 93px;

      .hero-featured-container {
        .featured-image {
          min-width: 453px;
          margin-right: 25px;
        }

        .featured-text {
          h3 {
            line-height: 2.0625rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #hero-featured-post {
    .container-fluid {
      padding: 131px 165px 70px;

      .hero-featured-container {
        .featured-image {
          min-width: 540px;
          margin-right: 30px;
        }
      }
    }
  }
}
