.socialMediaBlock {
  background-color: $blue;
}

.social-media-block {
  max-width: none !important;
  margin-left: 0 !important;
  position: relative;
  padding-right: 24px;
  padding-left: 24px;

  .section-padding {
    padding-top: 75px;
    padding-bottom: 71px;

    .social-media-container {
      .social-media-title{
        margin: 0 auto;
        color: $white;
        margin-bottom: 31px;
        @include rem-2(25, 27);

        h3 {
          margin: 0;

          p {
            margin-bottom: 0;
          }
        }

        p{
          margin-bottom: 0;

          + *{
            margin-bottom: 16px;
          }
        }
      }

      .social-networks{
        margin: 0 auto;
        width: 327px;

        .social-link {
          @include rem(26);
          color: $white;

          .screen-reader-text {
            display: none;
          }

          &.icofont-twitter{
            text-indent: -9999px;
            text-align: left;
            width: 26px;
            height: 26px;
            -webkit-mask-size: 26px;
            mask-size: 26px;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            mask-position: center center;
            -webkit-mask-image: url(/assets/icons/x-logo.svg);
            mask-image: url(/assets/icons/x-logo.svg);
            background: #fff;
            top: 5px;

            &:before{
              content: none;
            }
          }
        }
      }

      .social-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        width: 100%;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@include media-breakpoint-up(md) {
  .social-media-block {
    padding-right: 0;
    padding-left: 0;

    .social-media-container {
      max-width: 472px;
      margin  : auto;

      .social-media-title {
        @include rem-2(27, 29);
      }
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

@include media-breakpoint-up(lg) {
  .social-media-block {
    .social-media-container {
      max-width: 720px;

      .social-media-title {
        @include rem-2(31, 33);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .social-media-block {
    .social-media-container {
      max-width: 979px;
      padding-right: 59px;
    }
  }
}
