﻿.notifications-block {
  position: fixed;
  z-index: 2000;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;

  .bar-wrap {
    background-color: #ba2101;
    color: #fff;
    position: relative;
    padding: 17px 0 15px 0;
    min-height: 56px;

    &.hide{
      display: none;
    }

    .bar {
      z-index: 100;

      .content {
        max-width: 880px;
        position: relative;
        margin: 0;
        padding: 0 15px;

        .text {
          position: relative;

          .icon {
            position: absolute;
            left: 0;
            font-weight: normal;

            .info-icon {
              color: $white;
            }
          }

          .notification-body {
            padding: 0 35px 0 35px;
            word-wrap: break-word;

            * {
              margin: 0;
            }
          }
        }

        .notification-link {
          font-weight: normal;
          padding-left: 35px;
          overflow: hidden;
          white-space: nowrap;

          a.link-arrow {
            color: $white;
            position: relative;
            @include rem(18);
            line-height: 0.89em;
          }
        }

        .text + .notification-link {
          margin-top: 10px;
        }
      }

      .close {
        background: transparent;
        border: 0;
        color: #fff;
        position: absolute;
        width: 35px;
        height: 35px;
        top: 12px;
        right: 10px;
        opacity: 1;
        @include rem(35);
        padding: 0;
        font-weight: normal;
        cursor: pointer;
        text-align: left;
        display: block;

        .material-icons {
          @include rem(35);
        }
      }

      .text-container {
        position: relative;
        font-family: $font-family-sans-serif;
        margin: 0;
        margin-top: 17px;
        padding: 0;
        overflow: hidden;
        max-width: 265px;
        max-height: 48px;
        @include rem(16);
        line-height: 1.5em;

        .text {
          position: relative;
          color: $white;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up (md) {
  .notifications-block {
    .bar-wrap {
      .bar {
        .content {
          padding-left: 67px;
          padding-right: 10px;

          .text {
            .notification-body {
              max-width: 460px;
            }
          }

          .notification-link {
            position: absolute;
            top: 0px;
            right: 106px;
          }

          .text + .notification-link {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up (lg) {
  .notifications-block {
    .bar-wrap {
      .bar {
        .content {
          max-width: inherit;
          padding-left: 81px;
          padding-right: 10px;

          .text {
            .notification-body {
              max-width: 730px;
            }
          }

          .notification-link {
            right: 80px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up (xl) {
  .notifications-block {
    .bar-wrap {
      .bar {
        .content {
          padding-left: 289px;

          .text {
            .notification-body {
              max-width: 728px;
            }
          }

          .notification-link {
            right: 288px;
            padding: 0;
          }
        }
      }
    }
  }
}
