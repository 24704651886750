/**
 * FX Intro SlideRight.
 */
.fx-intro-slideright-default{
  @include transform(translateX(-100%));

  &.fx-intro-slideright-on{
    @include transition(transform 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000));
    @include transition-timing-function(cubic-bezier(0.165, 0.840, 0.440, 1.000));
  }

  &.fx-intro-slideright{
    @include transform(translateX(0));
  }
}

/**
 * FX Intro Slideup.
 */
.fx-intro-slideup-default{
  @include transform(translateY(30%));

  &.fx-intro-slideup-on{
    @include transition(transform 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000));
    @include transition-timing-function(cubic-bezier(0.165, 0.840, 0.440, 1.000));
  }

  &.fx-intro-slideup{
    @include transform(translateY(0));
  }
}

/**
 * FX Intro Fadein.
 */
.fx-intro-fadein-default{
  opacity: 0;

  &.fx-intro-fadein-on{
    @include transition(opacity 1s ease-in-out);
  }

  &.fx-intro-fadein{
    opacity: 1;
  }
}
