.faceted-filter-block{
  &[data-document-type="post"],
  &[data-document-type="newsroom_|_blogs"],
  &[data-document="blog"],
  &[data-document="news"],
  &[data-document="newsletter"],
  &[data-document-type="newsroom_|_videos"],
  &[data-document-type="newsroom_news"],
  &[data-document-type="newsroom_newsletters"],
  &[data-document-type="newsroom_press_releases"],
  &[data-document-type="newsroom_|_newsletters"],
  &[data-document-type="newsroom_|_press_releases"]{
    .facets-results{
      .result-item{
        margin-top: 30px;

        &.post{
          .container-card{
            .content{
              .categories{
                .title-container{
                  .subject-text{
                    color: #424647;
                  }
                }
              }

              .date{
                color: #ba4d01;
              }

              .title{
                color: #424647;
                @include rem-2(20, 24);
                letter-spacing: 0.15px;
              }
            }
          }
        }
      }

      * + .result-item{
        margin-top: 24px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    &[data-document-type="post"],
    &[data-document-type="newsroom_|_blogs"],
    &[data-document="blog"],
    &[data-document="news"],
    &[data-document="newsletter"],
    &[data-document-type="newsroom_|_videos"],
    &[data-document-type="newsroom_news"],
    &[data-document-type="newsroom_newsletters"],
    &[data-document-type="newsroom_press_releases"],
    &[data-document-type="newsroom_|_newsletters"],
    &[data-document-type="newsroom_|_press_releases"]{
      .facets-results{
        padding-right: 40px;
        padding-left: 40px;

        .result-item{
          &.post{
            .container-card{
              padding: 0;
              min-height: 252px;

              .image{
                min-height: 252px;
                max-width: 300px;
                width: 100%;
                overflow: hidden;

                img{
                  position: absolute;
                }
              }

              .content{
                position: relative;
                padding: 40px 30px 90px;
              }
            }
          }
        }

        * + .result-item{
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .faceted-filter-block{
    &[data-document-type="post"],
    &[data-document-type="newsroom_|_blogs"],
    &[data-document="blog"],
    &[data-document="news"],
    &[data-document="newsletter"],
    &[data-document-type="newsroom_|_videos"],
    &[data-document-type="newsroom_news"],
    &[data-document-type="newsroom_newsletters"],
    &[data-document-type="newsroom_press_releases"],
    &[data-document-type="newsroom_|_newsletters"],
    &[data-document-type="newsroom_|_press_releases"]{
      .facets-results{
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
