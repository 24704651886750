.newsletters-block {
  padding: 0 15px;

  .header-title {
    margin-bottom: 40px;
  }

  .content-link {
    margin-top: 30px;
    text-align: right;
  }

  .cart-content {
    background-color: $gray-100;
    color: $gray-900;
    border-left: solid $color-text-dark-orange 3px;

    .date-content {
      margin-bottom: 10px;
      font-weight: 500;

      &.text-dark-orange {
        color: $color-text-dark-orange;
      }
    }

    .cart-content-text {
      padding: 40px 40px 34px 31px;
      position: relative;

      .sup-title {
        letter-spacing: 0.15px;
      }

      .text-body {
        margin-bottom: 0;
      }

      * + .text-body {
        margin-top: 20px;
      }

      a.link-arrow {
        color: $gray-900;
      }
    }
  }
}

.has-mouseover {
  .newsletters-block {
    .cart-content {
      .cart-content-text {
        a.link-arrow {
          &:hover,
          &:active,
          &:focus {
            color: $orange;
          }
        }
      }
    }
  }
}

.bg-neutral-medium{
  .newsletters-block{
    .cart-content{
      background-color: $white;
    }
  }
}

.bg-neutral-dark{
  .newsletters-block{
    .cart-content{
      background-color: $white;
    }
  }
}

.bg-dark-blue{
  .newsletters-block{
    .cart-content{
      background-color: $white;
    }
  }
}

@include media-breakpoint-up(md) {
  .newsletters-block{
    padding: 0;
    padding-left: 40px;
    padding-right: 40px;

    .cart-container{
      margin: 0 auto;

      .cart-content{
        .cart-content-text{
          padding: 40px 40px 40px 31px;

          .text-body{
            margin-top: 20px;
          }
        }
      }
    }
  }
}
