.bioBlock {
  position: relative;
  padding: 0 15px;

  .content-col + * {
    margin-top: 20px;
  }

  .title-list {
    + * {
      margin-top: 20px;
    }
  }

  .header-title {
    & + * {
      margin-top: 50px;
    }

    .title {
      p {
        margin-bottom: 0;
      }

      & + * {
        margin-top: 15px;
      }
    }

    .sub-title {
      font-family: $font-family-sans-serif;
      @include rem-2(16, 24);
      text-align: center;

      p {
        margin: 0;
      }
    }
  }

  .card-bio {
    position: relative;

    .bio-generic {
      border-top: solid $color-text-dark-orange 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      position: relative;

      >.add-circle-icon-bio{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
      }

      .bio-img {
        img {
          max-width: 140px;
          min-height: 200px;
        }
      }

      .bio-info {
        position: relative;
        padding: 20px;
        width: 100%;
        background-color: $gray-50;

        p {
          margin: 0;
        }

        .sub-title-bio-info {
          color: $gray-900;
          font-family: $font-family-sans-serif;
          font-weight: 500;

          & + * {
            margin-top: 11px;
          }
        }

        .text-bio-info {
          color: $light-blue;

          font-family: $font-family-sans-serif;

          & + * {
            margin-top: 5px;
          }
        }

        .member-date {
          overflow: hidden;
          overflow-wrap: break-word;
        }

        .title-bio-info {
          font-family: $headings-font-family;
          color: $gray-900;
          overflow-wrap: break-word;
          @include rem-2(20, 24);

          & + * {
            margin-top: 5px;
          }
        }

        .add-circle-icon-bio {
          background: none;
          border: none;
          display: none;
          position: absolute;
          right: 12px;
          bottom: 12px;
          height: 49px;
          width: 49px;
          align-items: center;
          border-radius: 50%;
          background-color: transparent;
          transition: all .1s;

          &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            background-color: #a6a6a6;
            border-radius: 50%;
          }

          .circle-wrapper {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 100%;

            .add_circle_outline {
              background-color: $gray-950;
              width: 25px;
              height: 25px;
            }

            span{
              color: $white;
              position: absolute;
              left: 50%;
              top: 50%;
              @include transform(translate(-50%, -50%));
              width: 24px;
              height: 24px;
            }
          }

          &:focus{
            outline: none;
          }
        }

        .sub-info {
          display: none;
          @include rem-2(14, 16);
          color: $gray-901;
        }
      }
    }

    & + .card-bio {
      margin-top: 20px;
    }
  }

  + .bioBlock {
    margin-top: -20px;
    padding-top: 0;

    .section-padding {
      padding-top: 0;
    }
  }
}

body.is-ie {
  .bioBlock .card-bio .bio-generic .bio-info .add-circle-icon-bio .circle-wrapper span {
    width: 24px;
    height: 19px;
  }
}

.theme-dark {
  .bioBlock {
    .card-bio {
      .bio-generic {
        .bio-info {
          .text-bio-info {
            color: $light-blue;
          }

          .sub-info {
            color: $gray-901;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .bioBlock {
    padding: 0 40px;

    .header-title {
      padding: 0 39px;
      max-width: 778px;
      margin: 0 auto;
    }

    .content-col {
      margin-left: -15px;
      margin-right: -15px;

      .card-bio {
        padding: 0 15px;

        .bio-generic {
          .bio-info {
            overflow: hidden;

            * + .member-date {
              margin-top: 5px;
            }

            .member-date {
              @include rem-2(14, 16);
            }
          }
        }

        & + .card-bio {
          margin-top: 0px;
        }

        &:nth-child(n + 3) {
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .bioBlock {
    padding: 0 47px;

    .header-title {
      padding: 0 39px;
    }

    .content-col {
      .card-bio {
        padding: 0 15px;

        .bio-generic {

          >.add-circle-icon-bio{
            display: none;
          }

          .bio-info {
            padding-right: 80px;

            .add-circle-icon-bio {
              display: block;
              position: absolute;
              right: 12px;
              bottom: 12px;
              height: 49px;
              width: 49px;
              align-items: center;
              border-radius: 50%;

              .cta {
                cursor: pointer;
                display: inline-block;
                padding: 25px;

                .add_circle_outline {
                  width: 25px;
                  height: 25px;
                }
              }
            }

            .sub-info {
              display: block;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .bio-list-block {
    .row {
      .card-bio {
        .bio-generic {

          .bio-info {
            min-width: 400px;
          }
        }
      }
    }
  }
}

html.has-mouseover{
  .bioBlock .card-bio .bio-generic .bio-info .add-circle-icon-bio:hover{
    transform: scale(1.081632653061224);

    &:before{
      opacity: .45;
    }
  }
}
