.theme-dark {
  .image-and-copy {
    .section-image{
      picture {
        .caption {
          color: $white;
        }
      }
    }
  }
}

.image-and-copy{
  padding-left: 15px;
  padding-right: 15px;
  word-break: break-word;

  .image-and-copy-item{
    .section-info{
      .title-section + *{
        margin-top: 15px;
      }

      .cta{
        position: relative;
        padding-right: 30px;

        a.cta-arrow{
          @include rem(18);
          line-height: 16px;
          color: #424647;

          span{
            position: absolute;
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));
          }

          &:hover{
            color: #ba4d01;
          }
        }
      }

      * + .cta{
        margin-top: 30px;
      }
    }

    .section-image{
      picture{
        img {
          width: 100%;
          @include border-radius(4px);
        }

        .caption {
          @include rem(14);
          line-height: 16px;
          color: #757575;
        }

        * + .caption {
          margin-top: 5px;
        }
      }
    }

    & + .image-and-copy-item{
      margin-top: 31px;
    }

    &.odd{
      .section-image + .section-info{
        margin-top: 10px;
      }
    }

    &.even{
      .section-info + .section-image{
        margin-bottom: 10px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-and-copy {
    padding-left: 40px;
    padding-right: 40px;

    .image-and-copy-item {
      .section-image{
        min-width: 329px;
      }

      .section-info{
        width: 100%;
      }

      & + .image-and-copy-item {
        margin-top: 59px;
      }

      &.odd {
        .section-image + .section-info {
          margin-top: 0;
          padding-left: 30px;

        }
      }

      &.even {
        .section-info + .section-image {
          margin-bottom: 0;
          margin-left: 30px;
          margin-top: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .image-and-copy{
    padding-left: 47px;
    padding-right: 47px;

    .image-and-copy-item{
      .section-image {
        min-width: 450px;
      }

      & + .image-and-copy-item {
        margin-top: 60px;
      }

      &.flex-column {
        padding-right: 80px;
      }

      &.flex-column-reverse {
        padding-left: 80px;
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .image-and-copy{
    padding-left: 0;
    padding-right: 0;

    .image-and-copy-item{
      .section-info{
        max-width: 540px;
      }

      .section-image{
        min-width: 540px;
      }

      &.flex-column {
        padding-right: 95px;
      }

      &.flex-column-reverse {
        padding-left: 95px;
      }
    }
  }
}
