.general-management-block {
  border-top: 1px solid $gray-450;
  background-color: $gray-200;
  width: 100%;

  .container-fluid {
    .row {
      .padding {
        padding: 30px 57px;
        width: 100%;

        .container-management {
          .title-management {
            text-align: center;
            @include rem-2(31, 33);
            color: $gray-900;
            margin-bottom: 13px;
          }

          .button-management {
            position: relative;
            padding: 0 15px;

            button {
              font-weight: 500;
              color: $gray-900;
              @include rem-2(16,16);
              padding: 16px 65px 16px 40px;
              background-color: $gray-300;
              border: 1px solid $gray-900;
              border-radius: inherit;

              &:after {
                position: absolute;
                right: 35px;
                content: "\ea5d";
                font-family: $icofont;
                width: 20px;
                @include rem(20);
              }

              &:hover {
                background-color: $blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .general-management-block {
    .container-fluid {
      .row {
        .padding {
          padding: 30px 40px;
          max-width: 1026px;
          margin: 0 auto;

          .container-management {
            display: flex;
            align-items: center;

            .title-management {
              margin: 0;
            }

            .button-management {
              margin-left: auto;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .general-management-block {
    .container-fluid {
      .row {
        .padding {
          padding-left: 0;
          padding-right: 0;
          max-width: 920px;
        }
      }
    }
  }
}
