.cards-block{
  word-break: break-word;

  .card-content{
    padding-right: 15px;
    padding-left: 15px;

    .card-content-2{
      padding-bottom: 40px;

      .card-title{
        font-family: $headings-font-family;
        @include rem-2(25, 27);
        margin-bottom: 20px;

        p{
          margin: 0;
        }

        span{
          display: block;
        }
      }

      .card-text{
        margin-bottom: 20px;

        .theme-light{
          display: inline;
        }
      }

      & + .card-content-2{
        padding-top: 30px;
        padding-bottom: 0;
        border-top: 1px solid $gray-450;
      }
    }
  }
}

//Here begin the first breakpoint
@include media-breakpoint-up(md) {
  .cards-block{
    .card-content{
      margin: 0 auto;
      padding: 0;

      .card-title{
        @include rem-2(27, 29);
      }

      .card-text{
        line-height: 1.5rem;
      }

      .card-content-2:first-child{
        padding-bottom: 30px;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .cards-block{
    .section-padding{
      padding-top: 0;
      padding-bottom: 0;

      .card-content{
        .card-content-2{
          padding: 0;

          .card-title{
            @include rem-2(31, 33);
          }

          &:first-child{
            padding: 70px 51px 70px 0;
          }

          & + .card-content-2{
            border: none;
            border-left: 1px solid $gray-450;
            padding: 70px 0 70px 38px;
          }
        }

        & + .card-content{
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .cards-block{
    .section-padding{
      .card-content{
        max-width: 1007px;
        padding: 0 15px;

        .card-content-2{
          &:first-child{
            padding: 70px 15px 70px 0;
          }

          & + .card-content-2{
            padding: 70px 15px 70px 35px;
          }
        }
      }
    }
  }
}
