.footer{
  position: relative;

  .col-menus{
    padding-top: 70px;
    padding-bottom: 70px;
    background: $gray-200;

    > .row{
      position: relative;

      &:before{
        content: "";
        background: #dbdada;
        width: 1px;
        position: absolute;
        display: block;
        left: 40px;
        top: 0px;
        bottom: 0px;
        max-height: 680px;
        height: 100%;
      }

      .col-menu{
        padding-left: 51px;
        padding-right: 51px;

        .menu-title{
          @include rem-2(14, 19);
          margin: 0 0 10px;
          color: $indigo;
        }

        ul.menu{
          list-style: none;
          padding: 0;
          margin: 0;

          li{
            @include rem-2(14, 19);

            a{
              &:focus{
                color: $indigo;
              }
            }

            + li{
              margin-top: 10px;
            }
          }
        }

        + .col-menu{
          margin-top: 30px;
        }
      }
    }
  }

  .col-title{
    padding-top: 40px;
    padding-bottom: 40px;

    .logo-image{
      picture{
        min-width: 100%;

        img{
          width: 100%;
          max-width: 144px;
          max-height: 144px;
          height: 100%;
          display: table;
          margin: auto;
        }
      }
    }

    a.social-link, a:link{
      color: $white;

      &:focus{
        color: $blue-light-hover;
      }
    }

    * + .title{
      margin-top: 20px;
    }

    * + .social-links{
      max-width: 200px;
      margin: 0 auto;
      margin-top: 15px;
    }

    * + .bottom-link{
      margin-top: 15px;
    }

    .social-links{
      a.social-link{
        position: relative;
        display: inline-block;
        text-align: left;
        text-indent: -99999px;
        width: 26px;
        height: 26px;
        margin: 0;

        &:after{
          font-family: "Ionicons", sans-serif;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-rendering: auto;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: inherit;
          text-indent: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          @include rem(26);
          @include transform(translate(-50%, -50%));
        }

        &.fb{
          width: 10px;

          &:after{
            content: "\f231";
          }
        }

        &.in{
          width: 20px;

          &:after{
            content: "\f351";
          }
        }

        &.tw{
          width: 20px;
          text-indent: -9999px;
          text-align: left;
          width: 20px;
          height: 20px;
          -webkit-mask-size: 20px;
          mask-size: 20px;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-position: center center;
          mask-position: center center;
          -webkit-mask-image: url(/assets/icons/x-logo.svg);
          mask-image: url(/assets/icons/x-logo.svg);
          background: #fff;
          top: 3px;

          &:after{
            content: none;
          }

          &:hover{
            background: #99d9ff;
          }
        }

        &.li{
          width: 20px;

          &:after{
            content: "\f239";
          }
        }

        &.yt{
          width: 26px;

          &:after{
            content: "\f24d";
          }
        }

        &.pt{
          width: 22px;

          &:after{
            content: "\f2b1";
          }
        }
      }
    }
  }

  &.mail-icon-container {
    &::before {
      font-family: 'Material Icons', sans-serif;
      content: '\e0e1';
      display: block;
      width: 62px;
      height: 62px;
      border: 1px solid white;
      border-radius: 50%;
      position: absolute;
      right: 30px;
      bottom: 210px;
      text-align: center;
      color: white;
      line-height: 62px;
      font-size: 25px;
      z-index: 100;
      background-color: $blue;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer .col-title{
    * + .social-links{
      justify-content: space-between !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .footer{
    .col-title{
      max-width: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer{
    .col-title{
      padding-top: 40px;
      text-align: center;

      .social-links a.social-link{
        margin: 0 15px;
      }
    }

    .col-menus{
      padding-top: 72px;
      padding-bottom: 70px;

      > .row{
        &:before{
          display: none;
        }

        .col-menu{
          padding-left: 41px;
          padding-right: 41px;

          &:before{
            content: "";
            height: 100%;
            max-height: 208px;
            background: #dbdada;
            width: 1px;
            position: absolute;
            display: block;
            left: 31px;
            top: 0px;
            bottom: 0px;
          }

          + .col-menu{
            margin-top: 0;
          }
        }
      }
    }

    &.mail-icon-container {
      &::before {
        bottom: 80px;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .footer{
    .col-title {
      padding-top: 70px;
      max-width: 20%;

      * + .social-links{
        justify-content: space-between !important;
      }

      .social-links a.social-link{
        margin: 0 0;
      }
    }

    .col-menus {
      padding-top: 70px;
      padding-bottom: 70px;
      max-width: 100%;
      flex: 1;

      > .row{
        .col-menu{
          padding-left: 40px;
          padding-right: 40px;

          &:before{
            left: 31px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .footer{
    .col-menus{

      > .row{
        .col-menu{
          padding-left: 61px;
          padding-right: 40px;

          &:before{
            left: 51px;
            bottom: -20px;
          }
        }
      }
    }

    &.mail-icon-container {
      &::before {
        bottom: 90px;
      }
    }
  }
}

