.featured-content {
  word-break: break-word;

  .image {
    min-height: 300px;
    position: relative;
    overflow: hidden;

    img, video{
      position: absolute;
      height: auto;
      width: 100%;
      top: 50%;
      @include transform (translateY(-50%));
    }
  }

  .section-padding {
    padding-top: 0;

    .limiter-1056 {
      .container-fluid {
        .content {
          padding: 0 25px;
          margin: 0 auto;

          .hide-filter {
            text-align: center;
            display: table;
            margin: 0 auto;
            user-select: none;
            color: $gray-900;

            p {
              margin-bottom: 0;
              @include rem-2(18, 16);
              font-weight: 500;
            }

            &:focus{
              outline: none;
            }

            &:active{
              color: $orange;
            }
          }

          .wysiwyg-field {
            h2 {
              line-height: 2.75rem;
            }
          }

          .wysiwyg-field.body-hidden {
            position: relative;
            max-height: 505px;
            overflow: hidden;

            &::before {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 350px;
              content: '';
            }
          }

          .wysiwyg-field + .hide-filter {
            margin-top: 20px;
          }
        }
      }
    }

    * + .limiter-1056 {
      margin-top: 60px;
    }
  }
}

.theme-dark {
  .featured-content {
    .section-padding {
      .limiter-1056 {
        .container-fluid {
          .content {
            .hide-filter {
              color: $white;
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .featured-content {
    .section-padding {
      .limiter-1056 {
        .container-fluid {
          .content {
            .hide-filter:hover {
              color: $color-text-dark-orange;
            }
          }
        }
      }
    }
  }

  .theme-dark {
    .featured-content {
      .section-padding {
        .limiter-1056 {
          .container-fluid {
            .content {
              .hide-filter:hover {
                color: $orange-bright;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .featured-content {
    .image {
      min-height: 213px;
    }

    .section-padding {
      .limiter-1056 {
        .container-fluid {
          .content {
            padding-left: 40px;
            padding-right: 40px;

            .wysiwyg-field {
              h2 {
                 @include rem-2(36, 46);
              }
            }

            .wysiwyg-field.body-hidden {
              max-height: 301px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .featured-content {
    .image {
      min-height: 284px;
    }

    .section-padding {
      .limiter-1056 {
        &::before {
          height: 338px;
        }

        .container-fluid {
          .content {
            max-width: 780px;
            margin: 0 auto;

            .wysiwyg-field {
              h2 {
                 @include rem-2(40, 50);
              }
            }

            .wysiwyg-field.body-hidden {
              max-height: 278px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .featured-content {
    .image {
      min-height: 400px;
    }

    .limiter-1056 {
      &::before {
        height: 341px;
      }

      .container-fluid {
        .content {
          .wysiwyg-field.body-hidden {
            max-height: 281px;
          }
        }
      }
    }
  }
}
