.hero-home{
  width: 100%;
  height: 680px;
  min-height: 680px;
  overflow: hidden;
  position: relative;

  .items{
    white-space: nowrap;
    height: 100%;
    width: 100vw !important;

    .cycle-carousel-wrap{
      height: 100%;
    }

    .item{
      display: inline-block;
      width: 100vw;
      height: 100%;
      overflow: hidden;
      position: relative;
      z-index: 1;

      .inner-item{
        width: 100%;
        height: 100%;
        display: block;
        white-space: normal;
        position: relative;
        background: $blue;
      }

      .image{
        position: relative;
        height: 100%;
        overflow: hidden;

        picture,
        img{
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }

        &:after{
          content: "";
          opacity: 0.6;
          background: #000000;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
        }

        video{
          z-index: 1;
        }
      }

      &.has-video{
        .image{
          position: relative;
          z-index: 1;
          height: 100%;
        }
      }

      .stats{
        position: absolute;
        bottom: 66px;
        z-index: 3;
        overflow: hidden;
        white-space: nowrap;

        .stat{
          min-width: 124px;
          display: inline-block;
          white-space: normal;
          vertical-align: top;

          .caption{
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            max-height: 30px;
            overflow: hidden;
            max-width: 124px;
            margin-left: auto;
            margin-right: auto;
          }

          h4{
            text-align: center;
            margin: 0;
            max-height: 27px;
            overflow: hidden;
            color: $white;

            + .caption{
              margin-top: 4px;
            }
          }

          + .stat{
            margin-left: 10px;
          }
        }
      }

      .info{
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        @include transform(translateX(0));
        @include transition(all .6s ease-in-out);

        .info-position{
          position: absolute;
          min-height: 100%;
          min-width: 100%;
          padding-left: 25px;
          padding-right: 25px;
          @include transition(all .6s ease-in-out);

          .title-right-panel-wrapper{
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
          }
        }

        .title{
          + .body{
            margin-top: 20px;
          }

          + .read-more{
            margin-top: 30px;
          }
        }
      }
    }
  }

  .featured-stories{
    display: none;
    height: 100%;
    position: absolute;
    z-index: 101;
    @include transform(translateX(-366px));
    @include transition(all .7s ease-in-out);
    border-right: 1px solid white;

    .content-hero{
      color: #fff;
      text-align: right;

      .content-hero-title{
        line-height: 20px;
        @include rem(16);
        font-weight: 500;
      }

      * + .info-hero{
        margin-top: 20px;
      }
    }

    .featured-stories-wrapper{
      overflow: hidden;
      position: relative;
      height: 100%;
      min-width: 425px;
      max-width: 425px;
      opacity: 0;
      background-color: #0d1126;
      @include transition(all .4s ease-in-out);
      padding: 41px 49px 70px 126px;

      .featured-stories-title{
        text-align: right;
        color: #fff;
      }

      * + .related-stories{
        margin-top: 41px;
      }

      .related-stories{
        pointer-events: none;

        .related-story-item{
          position: relative;
          border: none;
          background: none;
          color: #fff;
          padding: 0;
          text-align: right;
          padding-bottom: 5px;
          width: 100%;

          .title{
            margin: 0;

            *{
              margin: 0;
            }
          }

          .body{
            margin: 0;

            *{
              margin: 0;
            }
          }

          &:after{
            content: '';
            position: absolute;
            bottom: 0;
            width: 0;
            background-color: #fff;
            left: 0;
            height: 1px;
            @include transition(all .4s ease-in-out);
          }

          &:focus{
            outline: none;
          }

          &.active{
            &:after{
              content: '';
              width: 100%;
            }
          }
        }

        .related-story-item + .related-story-item{
          margin-top: 20px;
        }
      }

      .content-hero{
        position: relative;
        top: 50px;
        opacity: 0;
        @include transition(all 2s);
      }

      .featured-stories-block-home{
        position: relative;
        top: 50%;
        opacity: 0;
        transform: translate(100%, -50%);
        @include transition(all 2.3s);
      }
    }

    button.open-stories{
      position: absolute;
      padding: 0;
      top: 50%;
      right: -20px;
      @include transform(translateY(-50%));
      width: 40px;
      height: 40px;
      border-radius: 50%;
      z-index: 1;
      background-color: #ffffff;
      box-shadow: none;
      border: none;
      overflow: hidden;

      .aligner{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        .screen-reader{
          display: none;
        }

        &:after{
          font-family: $material-icons;
          @include rem(24);
          content: "\e315";
          position: absolute;
          left: 50%;
          top: 50%;
          @include transition(all 0.4s ease-in-out);
          @include transform(translate(-50%, -50%) rotate(0));
        }
      }

      &:focus{
        outline: none;
      }

      .btnText{
        text-indent: -9999999px;
      }
    }

    &.active{
      @include transform(translateX(0));

      .featured-stories-wrapper{
        opacity: 0.9;

        .content-hero{
          opacity: 1;
          top: 0;
        }

        .featured-stories-block-home{
          opacity: 1;
          transform: translate(0, -50%);

          .related-stories{
            pointer-events: auto;
          }
        }
      }

      button.open-stories{
        .aligner{
          &:after{
            @include transform(translate(-50%, -50%) rotateY(180deg));
          }
        }
      }
    }
  }

  .pagination{
    position: absolute;
    bottom: 70px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 100;

    button.page{
      + button.page{
        margin-left: 10px;
      }
    }
  }
}

@include media-breakpoint-up(md){
  .hero-home{
    .items .item{
      .info{
        .info-position{
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -50%));
          min-width: 500px;
        }

        .title + .body{
          margin-top: 30px;
        }
      }


      .image{
        picture,
        img{
          width: 100%;
          height: auto;
          top: 50%;
          left: auto;
          @include transform(translateY(-50%));
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .hero-home{
    height: 95vh;
    min-height: 796px;

    .featured-stories{
      padding: 0;
      display: block;
    }

    .items{
      .item{
        .info{
          .info-position{
            padding: 0;
            left: 379px;
            max-width: 540px;

            .body.wysiwyg-field{
              max-width: 340px;
            }

            .title-body{
              overflow: hidden;
            }
          }

          &.active{
            @include transform(translateX(425px));
            width: calc(100% - 425px);

            .info-position{
              left: 330px;

              .stats{
                display: none !important;
              }
            }
          }
        }

        .stats{
          bottom: 120px;
        }
      }
    }

    .pagination{
      display: none !important;
    }
  }
}

@include media-breakpoint-up(xl){
  .hero-home .items .item{
    .stats{
      bottom: 160px;
    }
  }

  .hero-home .items .item .info{
    .info-position{
      left: 509px;
    }

    &.active{
      width: calc(100% - 551px);
      @include transform(translateX(551px));
    }
  }

  .hero-home{
    .featured-stories{
      @include transform(translateX(-492px));
      .featured-stories-wrapper{
        min-width: 551px;
        max-width: 551px;
      }
    }
  }
}

html.has-mouseover{
  .hero-home .featured-stories .featured-stories-wrapper .related-stories .related-story-item:hover{
    &:after{
      content: '';
      width: 100%;
    }
  }

  .hero-home .featured-stories button.open-stories:hover{
    background-color: $gray-light;
  }
}
