.events-calendar-block {
  padding: 0 15px;

  .limiter-920 {
    .header-title {
      .title {
        max-width: 340px;
        margin: 0 auto;
        font-family: $headings-font-family;
        @include rem-2(34, 44);
        text-align: center;
        overflow-wrap: break-word;
      }

      * + .cta-view-all {
        margin-top: 20px;
      }

      .cta-view-all {
        overflow-wrap: break-word;
        word-break: break-all;

        a {
          margin: 0 auto;
          display: table;
          font-family: $font-family-sans-serif;
          @include rem-2(18, 16);
          font-weight: 500;
        }
      }
    }

    * + .schedule-content {
      margin-top: 50px;
    }

    .schedule-content {
      + .schedule-content {
        margin-top: 40px;
      }

      .title {
        margin-bottom: 25px;
        font-family: $headings-font-family;
        @include rem-2(27, 29);
        overflow-wrap: break-word;
        max-width: 158px;
        margin-left: auto;
        text-align: right;
      }

      .wrapper-schedule {
        .schedule {
          .meeting {
            width: 100%;
            padding: 20px 10px;
            display: flex;
            border-top: 1px solid $gray-100;
            border-bottom: 1px solid $gray-100;

            .date {
              max-width: 66px;

              .month {
                max-width: 66px;
                font-family: $font-family-sans-serif;
                @include rem-2(20, 24);
                text-transform: uppercase;
                font-weight: 500;
                text-align: center;
                overflow-wrap: break-word;
              }

              .day {
                width: 66px;
                font-family: $headings-font-family;
                @include rem-2(40, 50);
                text-align: center;
                overflow-wrap: break-word;
                color: $orange;
              }
            }

            .meeting-info {
              padding-top: 10px;
              display: flow-root;
              max-width: 239px;
              margin-left: 20px;

              .meeting-name {
                min-width: 239px;
                font-family: $headings-font-family;
                @include rem-2(20, 24);
                overflow-wrap: break-word;
              }

              .links {
                margin-top: 10px;
                min-width: 239px;
                display: flex;

                .link {
                  overflow-wrap: break-word;

                  a {
                    font-family: $font-family-sans-serif;
                    @include rem-2(16, 24);
                    color: $link-default;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                }

                .divider {
                  background: #5a5b5d;
                  width: 1px;
                  height: 18px;
                  margin: auto 20px;
                }
              }
            }
          }
        }
        & + div.schedule-content {
          margin-top: 40px;
        }
      }
    }

    .navigation {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      font-family: $font-family-sans-serif;
      @include rem-2(18, 16);
      font-weight: 500;
    }
  }
}

.theme-dark {
  .events-calendar-block {
    .limiter-920 {
      .schedule-content {
        .wrapper-schedule {
          .schedule {
            .meeting {
              border-top: 1px solid $white;
              border-bottom: 1px solid $white;
              .date {
                .day {
                  color: $orange-bright;
                }
              }

              .meeting-info {
                .links {
                  .link {
                    a {
                      color: $blue-light;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .events-calendar-block {
    .limiter-920 {
      .schedule-content {
        .wrapper-schedule {
          .schedule {
            .meeting {
              &:hover {
                background: rgba( 0, 0, 0, 0.05);
              }

              .meeting-info {
                .links {
                  .link {
                    a:hover {
                      color: $link-default-hover;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .theme-dark {
    .events-calendar-block {
      .limiter-920 {
        .schedule-content {
          .wrapper-schedule {
            .schedule {
              .meeting {
                &:hover {
                  background: rgba( 255, 255, 255, 0.05);
                }

                .meeting-info {
                  .links {
                    .link {
                      a:hover {
                        color: $blue-light-hover;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .events-calendar-block {
    padding: 0 39px 0 40px;

    .limiter-920 {
      .header-title {
        .title {
          max-width: 562px;
          margin: 0 auto;
          @include rem-2(36, 46);
        }
      }

      .schedule-content {
        display: flex;

        + .schedule-content {
          margin-top: -1px;
        }

        .title {
          max-width: 202px;
          margin: 0 30px 0 0;
        }

        .wrapper-schedule {
          width: 100%;

          .schedule {
            width: 100%;

            .meeting {
              .date {
                .day {
                  width: 66px;
                }
              }

              .meeting-info {
                min-width: 332px;

                .meeting-name {
                  min-width: 332px;
                }

                .links {
                  width: 332px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .events-calendar-block {
    padding: 0 57px 0 47px;

    .limiter-920 {
      .header-title {
        .title {
          max-width: 624px;
          @include rem-2(40, 50);
        }
      }

      .schedule-content {
        .title {
          min-width: 225px;
          max-width: 225px;
          @include rem-2(31, 33);
        }

        .schedule {
          .meeting {
            .meeting-info {
              min-width: 529px;

              .meeting-name {
                min-width: 529px;
              }

              .links {
                width: 529px;
              }
            }
          }
        }
      }

      .navigation {
        padding-left: 35px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .events-calendar-block {
    padding: 0;
  }
}
