.section-padding{
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-top-0{
  .section-padding{
    padding-top: 0;
  }
}

.padding-bottom-0{
  .section-padding{
    padding-bottom: 0;
  }
}

.padding-top-35{
  .section-padding{
    padding-top: 35px;
  }
}

.padding-bottom-35{
  .section-padding{
    padding-bottom: 35px;
  }
}

.padding-top-70{
  .section-padding{
    padding-top: 70px;
  }
}

.padding-bottom-70{
  .section-padding{
    padding-bottom: 70px;
  }
}

.blog-post{
  .article-content{
    .section-block.padding-top-0{
      .section-padding{
        padding-top: 0;
      }
    }

    .section-block.padding-bottom-0{
      .section-padding{
        padding-bottom: 0;
      }
    }

    .section-block.padding-top-35{
      .section-padding{
        padding-top: 35px;
      }
    }

    .section-block.padding-bottom-35{
      .section-padding{
        padding-bottom: 35px;
      }
    }

    .section-block.padding-top-70{
      .section-padding{
        padding-top: 70px;
      }
    }

    .section-block.padding-bottom-70{
      .section-padding{
        padding-bottom: 70px;
      }
    }
  }
}
