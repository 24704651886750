//$height-modal: (100vh - 100px);
.modal-bio-detail {
  .modal-dialog {
    width: 100vW;
    max-width: 100vW;
    margin: 0;

    .modal-content {
      height: 100%;
      padding: 69px 15px 0;

      p {
        margin: 0;
      }

      button.close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $gray-900;
        font-size: 35px;
        width: 35px;
        height: 35px;

        .material-icons {
          font-size: 35px;
          width: 35px;
        }
      }

      .modal-body {
        padding: 0;
        padding-bottom: 40px;
        border-bottom: 1px solid $gray-450;

        .modal-bio-info {
          .bio-img {
            width: 100%;
            max-width: 210px;
            max-height: 272px;
            margin: 0 auto;
            border-radius: 4px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .container-modal-card {
            padding: 30px 0 40px;

            .container-modal-card-header {
              padding-bottom: 28px;

              .title-bio-info {
                font-family: $headings-font-family;
                color: $gray-990;
                font-weight: 500;
              }

              .sub-title-bio-info {
                font-family: $font-family-sans-serif;
                @include rem-2(20, 24);
                color: $gray-900;
                font-weight: 500;
                letter-spacing: 0.15px;
              }

              * + .sub-title-bio-info {
                margin-top: 10px;
              }

              .text-bio-info {
                font-family: $font-family-sans-serif;
                color: $gray-900;
              }

              * + .text-bio-info {
                margin-top: 10px
              }
            }

            .container-modal-card-body {
              padding: 30px 0;

              .title-bio-info {
                font-family: $font-family-sans-serif;
                color: $gray-900;
                font-weight: 500;
              }

              * + .title-bio-info {
                margin-top: 15px;
              }

              .sub-title-bio-info {
                font-family: $font-family-sans-serif;
                color: $light-blue;
              }

              * + .sub-title-bio-info {
                margin-top: 5px;
              }

              .text-bio-info {
                font-family: $font-family-sans-serif;
                color: $gray-900;
              }

              * + .text-bio-info {
                margin-top: 5px;
              }
            }
          }
        }

        .modal-container-text {
          .title-text-bio {
            font-family: $font-family-sans-serif;
            font-weight: 500;
            color: $gray-900;
          }

          .text-bio {
            font-family: $font-family-sans-serif;
            color: $gray-900;
            margin: 0;
          }
        }
      }

      .modal-footer {
        position: relative;
        padding: 30px 0 58px;

        button {
          margin: 0;
          background-color: transparent;
          border: 0;
          width: 40px;
          height: 40px;
          position: absolute;
          padding: 7px 8px;

          .material-icons {
            width: 25px;
            height: 25px;
          }
        }

        button.before {
          left: 0;
        }

        button.next {
          right: 0;
        }

        .container-footer-bio-before {
          position: relative;
          margin: 0;
          left: 60px;
          text-align: left;
          max-width: 28.28%;

          .header-footer-before {
            font-weight: 500;
            color: $gray-900;

            & + * {
              margin-top: 8px;
            }
          }

          .body-footer-before {
            font-family: $headings-font-family;
            @include rem-2(27, 29);
            color: $light-blue;

            & + * {
              margin-top: 5px;
            }
          }

          .footer-footer-before {
            color: $light-blue;
          }
        }

        .container-footer-bio-after {
          position: relative;
          margin: 0;
          right: 60px;
          text-align: end;
          max-width: 28.57%;

          .header-footer-after {
            font-weight: 500;
            color: $gray-900;

            & + * {
              margin-top: 8px;
            }
          }

          .body-footer-after {
            font-family: $headings-font-family;
            @include rem-2(27, 29);
            color: $light-blue;

            & + * {
              margin-top: 5px;
            }
          }

          .footer-footer-after {
            color: $light-blue;
          }
        }
      }

      .divider {
        color: $gray-450;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal-bio-detail {
    .modal-dialog {
      .modal-content {
        padding: 69px 39px 0;

        .modal-body {
          .modal-bio-info {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $gray-450;

            & + * {
              margin-top: 40px;
            }

            .bio-img {
              margin: 0;
            }

            .container-modal-card {
              padding: 0 0 0 30px;
              flex: 1;
            }
          }
        }

        .modal-footer {
          .container-footer-bio-before {
            max-width: inherit;
          }

          .container-footer-bio-after {
            max-width: inherit;
          }
        }
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .modal-bio-detail {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bio-info {
            padding-bottom: 30px;

            .bio-img {
              max-width: 255px;
              max-height: 330px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal-bio-detail {
    .modal-body {
      max-width: 920px;
      margin: 0 auto;
    }

    .modal-footer {
      margin: auto;
      min-width: 920px;
    }
  }
}

