.embed-frame-block {
  word-break: break-word;
  padding: 150px 16px 149px 18px;

  h2 {
    letter-spacing: 0.15px;
  }
}

@include media-breakpoint-up(md) {
  .embed-frame-block {
    padding: 150px 213px 149px 214px;
  }
}

@include media-breakpoint-up(lg) {
  .embed-frame-block {
    padding: 190px 341px 217px 342px;
  }
}

@include media-breakpoint-up(xl) {
  .embed-frame-block {
    padding: 221px 549px 246px 550px;
  }
}
