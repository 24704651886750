/* stylelint-disable */

:root {
  --rest: 0px;
  --article-teaser-min-height: 680px;
  --offset-pagination: 143px;
}

.article-teaser{
  position: relative;
  background: $white;
  height: calc(var(--article-teaser-min-height) - var(--rest));
  overflow: hidden;
  min-height: var(--article-teaser-min-height) - var(--rest);

  .close-featured-stories{
    margin-top: 35px;
    margin-left: 25px;
  }

  .close{
    margin-top: 35px;
    margin-right: 25px;
    position: relative;
    top: 0;
    right: 0;

    .material-icons{
      font-size: 35px;
      width: 35px;
    }
  }

  .slider-wrapper{
    min-height: var(--article-teaser-min-height);
    position: relative;
    overflow: hidden;

    .slider-items{
      min-height: var(--article-teaser-min-height);

      .article-slide-item{
        min-height: (var(--article-teaser-min-height) - var(--offset-pagination));
        width: 100%;

        &.Dark{
          opacity: 0.75;
          background-color: $black;

          .scroll-box{
            .article-header{
              .number-title{
                color: $white;

                &:after{
                  content: '';
                  background: $white;
                }
              }

              .close-article{
                color: $white;
              }
            }

            .story-content{
              position: relative;

              .limiter{
                height: 100% !important;

                .body{
                  p{
                    color: $white;
                  }

                  .article-header-inner{
                    .number-title{
                      &:after{
                        content: '';
                        background-color: $white;
                      }
                    }
                  }

                  .stats{
                    .stat{
                      &:after{
                        position: absolute;
                        content: '';
                        background-color: $white;
                        width: 90px;
                        height: 1px;
                        display: block;
                        left: 50%;
                        @include transform(translateX(-50%));
                        bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .scroll-box{
          width: 100%;
          height: 580px;
          overflow: auto !important;
          padding-bottom: 160px;

          .article-header{
            padding: 23px 15px 41px 13px;
          }

          .story-content{
            .limiter{

              .image{
                img{
                  width: auto;
                  height: auto;
                  min-width: 100%;
                  min-height: 100%;
                  max-width: 100%;
                }
              }

              .img-fluid {
                height: 100%;
              }

              .background-video {
                max-width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                z-index: -1;
                opacity: 0.4;
              }

              .content + .image{
                margin-top: 40px;
              }
            }
          }
        }

        .scroll-box{
          .story-content{
            .content{
              .body{
                .stats{
                  text-align: center;

                  .stat{
                    position: relative;
                    padding: 20px 0;

                    h4{
                      @include rem(27);
                      line-height: 29px;
                    }

                    .caption{
                      @include rem(16);
                    }

                    &:after{
                      position: absolute;
                      content: '';
                      background-color: $black;
                      width: 90px;
                      height: 1px;
                      display: block;
                      left: 50%;
                      @include transform(translateX(-50%));
                      bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.popup-article-ready #popup-article .article-slide-item.Dark button.close-article{
  color: $white;
}

#popup-article{
  .article-slide-item{
    .number-title{
      opacity: 0;
      @include transition(opacity 0.5s);
      @include transition-delay(0.3s);
    }

    button.close-article{
      color: $gray-900;
      opacity: 0;
      @include transform(translateX(20px));
      @include transition(transform 0.3s);
      @include transition-delay(0.7s);
    }
  }
}

.row{
  .article-teaser .slider-wrapper .slider-items .article-slide-item .scroll-box .story-content {
    .content-image,
    .image.has-video{
      position: static;
      height: 75vh;

      .background-video{
        left: 0;
        min-width: unset;
        max-width: 100%;
      }
    }

    .image.has-video{
      .prev{
        left: 483px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  :root {
    --offset-pagination: 98px;
  }

  .article-teaser {
    .close-featured-stories {
      margin-top: 45px;
      margin-left: 35px;
    }

    .close {
      margin-top: 45px;
      margin-right: 35px;
    }

    .slider-wrapper {
      margin-top: 0;
      height: calc(100% - 113px);
      min-height: 0;

      .slider-items {
        height: 100%;
        min-height: 0;

        .article-slide-item {
          height: 100%;

          &.Dark {
            background-color: $white;

            .scroll-box {
              .story-content {
                .limiter {
                  height: calc(100% - 140px);

                  .row {
                    height: 100%;
                  }

                  .content {
                    background-color: $black;
                    opacity: 0.75;

                    .number-title {
                      color: $white;
                    }
                  }
                }
              }
            }
          }

          .scroll-box {
            height: 579px;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            overflow-y: hidden !important;
            height: 100%;
            padding-bottom: 0;

            &::-webkit-scrollbar {
              display: none;
            }

            .story-content {
              margin-top: 23px;
              overflow: hidden;
              padding-left: 34px;
              padding-right: 34px;
              -ms-overflow-style: none; /* for Internet Explorer, Edge */
              scrollbar-width: none; /* for Firefox */
              overflow-y: scroll;
              height: calc(100% - 23px);

              &::-webkit-scrollbar {
                display: none;
              }

              .limiter {
                position: relative;
                height: 100%;

                .row {
                  margin: 0;
                  height: 100%;;
                }

                .content,
                .content-image {
                  height: 100%;
                  min-height: 0;
                }

                .content {
                  background-color: $white;
                  overflow: auto;
                  max-width: 330px;
                  flex: none;
                  border: solid 1px $gray-light-2;
                  border-right: none;
                  height: 100%;
                  min-height: 0;
                  overflow: auto;

                  .body {
                    padding: 10px 40px;

                    .article-header-inner {
                      margin-bottom: 64px;
                      margin-left: -77px;
                      margin-right: -50px;

                      .close-article {
                        padding-right: 4px;
                      }
                    }
                  }
                }

                .content-image {
                  padding: 0;
                  max-width: calc(100% - 330px);
                  flex: none;
                  overflow-y: auto;
                }

                .image {
                  position: relative;
                  display: block;
                  width: auto;
                  height: 100%;

                  picture img {
                    position: relative;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .background-video {
                  left: -330px;
                  z-index: -1;
                  min-height: 442px;
                  min-width: calc(100% + 330px);
                  opacity: 1;
                }

                .content + .image {
                  margin-top: 0;
                }
              }

              .prev, .next {
                position: absolute;
                top: 50%;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $white;
                @include transform(translateY(-50%));

                &:focus {
                  outline: none;
                }

                &.disabled {
                  background-color: $gray-light;
                }
              }

              .prev {
                left: 335px;
              }

              .next {
                right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  :root {
    --article-teaser-min-height: 796px;
  }

  .article-teaser{
    height: calc(95vh - var(--rest));

    .slider-wrapper{
      height: calc(100% - 127px);

      .slider-items{
        .article-slide-item{
          &.Dark{
            .scroll-box{
              .story-content{
                .content{
                  .body{
                    .article-header-inner {
                      margin-left: -86px;
                    }

                    .stats{
                      .stat{
                        &:after{
                          content: none;
                        }

                        & + .stat{
                          &:after{
                            content: '';
                            display: block;
                            height: 100%;
                            width: 1px;
                            background-color: $white;
                            left: 0;
                            @include transform(translateX(none));
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .scroll-box{
            .story-content{
              margin-top: 60px;
              height: calc(100% - 60px);

              .limiter{
                .content{
                  max-width: 504px;

                  .body{
                    .article-header-inner {
                      margin-left: -86px !important;
                    }

                    .stats{
                      display: flex;
                      flex-direction: row;
                      justify-content: center;

                      .stat{
                        padding: 0 10px;

                        &:after{
                          content: none;
                        }

                        & + .stat{
                          &:after{
                            content: '';
                            display: block;
                            height: 100%;
                            width: 1px;
                            background-color: $dark;
                            left: 0;
                            @include transform(translateX(none));
                          }
                        }
                      }
                    }
                  }
                }

                .content-image{
                  max-width: calc(100% - 504px);

                  .image{
                    img{
                      height: 100%;
                    }
                  }
                }

                .background-video{
                  left: -504px;
                  min-width: calc(100% + 504px);
                }
              }

              .prev{
                left: 515px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .article-teaser{
    .close-featured-storie {
      margin-left: 72px;
    }

    .close{
      margin-right: 45px;
    }

    .slider-wrapper{
      .slider-items{
        .article-slide-item{
          .scroll-box{
            padding: 0;

            .story-content{
              .limiter{
                .content{
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

html.popup-article-ready{
  #popup-article{
    .article-slide-item{
      .number-title{
        opacity: 0.3;
      }

      button.close-article{
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
}

html.has-mouseover{
  &.popup-article-ready{
    #popup-article .article-slide-item button.close-article:hover{
      color: $orange;
    }

    #popup-article .article-slide-item.Dark button.close-article:hover{
      color: #ffa860;
    }
  }

  .article-teaser{
    .slider-wrapper{
      .slider-items{
        .article-slide-item{
          &.Dark{
            .scroll-box{
              .article-header{
                button:hover{
                  color: #ffa860;
                }
              }

              .story-content{
                .content{
                  .close-article:hover{
                    color: #ffa860;
                  }
                }
              }
            }
          }

          .scroll-box{
            .story-content{
              .limiter{
                .image {
                  .prev:hover,
                  .next:hover {
                    background-color: $gray-light;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover{
  .article-teaser .close-featured-stories:hover,
  .article-teaser .close a:hover{
    color: #ba4d01;
  }
}
