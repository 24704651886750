.image-gallery-generic-modal {
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .modal-dialog {
      position: fixed;
      margin: 0;
      min-width: 100%;
      height: 100%;
      padding: 0;

      .modal-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $gray-100;

        .modal-header {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 70px;

          button {
            font-size: 35px;
            padding: 20px 15px 20px 20px;

            &.close {
              color: $gray-900;
              opacity: 1;
            }

            .material-icons {
              font-size: 35px;
            }
          }
        }

        .modal-body {
          position: absolute;
          top: 70px;
          width: 100%;
          height: 100%;
          padding: 0;
          overflow-y: auto !important;
          padding-bottom: 50px;

          .container-fluid {
            padding: 0 15px 70px 15px;

            .text-gallery {
              padding-right: 35px;
              padding-left: 35px;

              h2 {
                @include rem(31);
              }
            }

            .gallery-presentation + .text-gallery {
              margin-top: 15px;

              h2 + p {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-gallery-generic-modal {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .container-fluid {
              padding-right: 40px;
              padding-left: 40px;

              .text-gallery {
                padding-right: 40px;
                padding-left: 40px;
              }

              .gallery-presentation + .text-gallery {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-gallery-generic-modal {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .container-fluid {
              padding-right: 47px;
              padding-left: 47px;

              .gallery-presentation {
                max-width: 596px;
                padding-right: 19px;
                padding-left: 47px;
              }

              .text-gallery {
                padding-right: 47px;
                padding-left: 11px;
              }

              .gallery-presentation + .text-gallery {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-gallery-generic-modal {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .container-fluid {
              padding-right: 47px;
              padding-left: 47px;

              .gallery-presentation {
                max-width: 635px;
                padding-right: 19px;
                padding-left: 0;
              }

              .text-gallery {
                padding-right: 0;
                padding-left: 11px;
              }

              .gallery-presentation + .text-gallery {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
