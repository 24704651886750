.faceted-filter-block{
  &[data-document-type="global"]{
    .section-padding{
      padding: 70px 0 40px;
    }

    .form{
      .title-section{
        .wysiwyg-field{
          *{
            margin: 0
          }
        }
      }

      .form-section{
        input{
          max-width: 100%;
        }
      }

      * + .form-section{
        margin-top: 20px;
      }
    }

    .facets-results,
    .search-results{
      padding-top: 0;

      .container-fluid{
        padding-right: 15px;
        padding-left: 15px;

        span.highlighted-text{
          font-weight: bold;
        }
      }
    }
  }

  .facets-results,
  .search-results{
    .link-items {
      .l-item {
        position: relative;
        border-top: 1px solid $gray-450;

        &:last-child {
          border-bottom: 1px solid $gray-450;
        }

        a {
          display: block;

          .item-content-wrapper {
            position: relative;
            padding: 24px 75px 24px 50px;

            .icon-doc-type{
              left: 25px;
              top: 24px;
              @include rem-2(20, 24);
            }

            .item-title {
              @include rem-2(20, 24);
              font-weight: 500;
              margin-bottom: 0;
              color: #424647;
            }

            .description{
              color: #424647;
            }

            .item-title + *,
            .description + *,
            .document-type + *{
              margin-top: 5px;
            }

            .document-type{
              margin-bottom: 0;
            }

            .date{
              margin-bottom: 0;
              color: #424647;
            }

            .item-icon{
              position: absolute;
              right: 25px;
              top: 24px;
              font-family: $material-icons;

              i{
                width: 25px;
                font-size: 25px;

                &.material-download{
                  font-family: $material-icons;
                  font-style: normal;

                  &::before{
                    content: '\e884';
                  }
                }
              }
            }
          }

          &:focus{
            background-color: #e8e8e8;
            color: $orange;

            .item-content-wrapper{
              i{
                color: $orange;
              }
            }
          }
        }

        a.styleiconempty{
          pointer-events: none;
          cursor: default;
          padding-top: 16px;
        }
      }
    }

    .pagination-block,
    .global-search-pagination{
      margin-bottom: 70px;
    }

    .global-search-pagination{
      margin-top: 30px;
    }
  }
}

html.has-mouseover{
  .faceted-filter-block{
    .facets-results,
    .search-results{
      .link-items {
        .l-item {
          a{
            .item-content-wrapper:hover{
              background-color: #e8e8e8;
              color: inherit;

              .item-icon{
                i{
                  color: $orange;
                }
              }
            }
          }

          a:hover{
            color: inherit;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md){
  .faceted-filter-block{
    &[data-document-type="global"]{
      .section-padding{
        max-width: 770px;
        margin: 0 auto;

        .form{
          max-width: 620px;
        }
      }

      .facets-results,
      .search-results{
        max-width: 770px;
        margin: 0 auto;
        padding: 0;

        .container-fluid{
          padding-right: 40px;
          padding-left: 40px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .faceted-filter-block{
    &[data-document-type="global"]{
      .section-padding{
        max-width: 870px;

        .form{
          max-width: 640px;
        }
      }

      .facets-results,
      .search-results{
        max-width: 870px;

        .container-fluid{
          padding-right: 50px;
          padding-left: 50px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .faceted-filter-block{
    &[data-document-type="global"]{
      .section-padding{
        max-width: 1020px;
      }

      .facets-results,
      .search-results{
        max-width: 1020px;
      }
    }
  }
}
