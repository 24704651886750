.link-list-generic-block {
  overflow-wrap: break-word;

  .section-padding {
   * {
      margin-bottom: 0;
    }

    .content {
      width: 100%;
      padding: 0 15px;

      .title {
        word-break: break-word;

        + * {
          margin-top: 25px;
        }
      }

      .link-items {
        .l-item {
          position: relative;
          border-top: 1px solid $gray-450;

          &:last-child {
            border-bottom: 1px solid $gray-450;
          }

          a {
            display: block;
            color: #424647;

            .item-content-wrapper {
              position: relative;
              padding: 24px 75px 20px 25px;

              .item-title {
                @include rem-2(16, 24);
                font-weight: 500;
              }

              .item-icon {
                position: absolute;
                top: 50%;
                right: 25px;
                @include transform(translateY(-50%));
                font-family: $material-icons;

                i {
                  color: #424647;
                  width: 25px;
                  font-size: 25px;

                  &.material-download {
                    font-family: $material-icons;
                    font-style: normal;

                    &::before{
                      content: '\e884';
                    }
                  }
                }
              }
            }

            &:focus {
              background-color: #e8e8e8;
              color: #424647;

              .item-content-wrapper{
                i {
                  color: $orange;
                }
              }
            }
          }

          a.styleiconempty{
            pointer-events: none;
            cursor: default;
            padding-top: 16px;
          }
        }
      }

      * + .link-items {
        margin-top: 30px;
      }

      * + .body {
        margin-top: 30px;
      }
    }
  }
}

.theme-dark{
  .link-list-generic-block {
    .content {
      .link-items {
        .l-item {
          a {
            color: $white;

            .item-content-wrapper {
              .item-icon {
                i {
                  color: $white;
                }
              }
            }

            &:focus {
              background-color: #e8e8e8;
              color: $white;
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .link-list-generic-block {
    .section-padding {
      .content {
        .link-items {
          .l-item {
            a {
              .item-content-wrapper:hover {
                background-color: #e8e8e8;
                color: #424647;

                i {
                  color: $orange;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .link-list-generic-block {
    .section-padding {
      .content {
        max-width: 720px;
        margin: 0 auto;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .link-list-generic-block {
    .section-padding {
      .limiter-1056 {
        .container-fluid {
          .row {
            .content {
              max-width: 800px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .link-list-generic-block {
    .section-padding {
      .limiter-1056 {
        .container-fluid {
          .row {
            .content {
              max-width: 950px;
            }
          }
        }
      }
    }
  }
}
