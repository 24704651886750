.newsroomUpdatesForm {
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 1031;
  opacity: 0;
  transition: all .5s;
  top: 86px;
  left: 25px;
  right: 25px;
  max-width: 1110px;
  //margin: auto;

  .newsroom-updates {
    position: relative;
    z-index: 1;

    .close {
      margin: 10px 10px 0 0;

      span {
        color: #424647;
        @include rem(35);
      }

      &:hover {
        span {
          color: #ba4d01;
        }
      }
    }

    .sign-body {
      max-width: 426px;
      margin: auto;

      p + p {
        margin-top: 20px;
      }
    }

    .formulate-wrapper {
      max-width: none;

      .formulate__form {
        .formulate__row {
          .formulate__cell {
            .formulate__field--text {
              max-width: 445px;
              margin: 0 auto;
              margin-bottom: 24px;

              input {
                width: 100%;
                max-width: 445px;
              }

              .error-message {
                position: absolute;
                color: red;
              }
            }

            .formulate__field--checkbox-list {
              legend {
                display: none;
              }

              label {
                display: block;
                position: relative;
                width: 50%;
                -webkit-box-flex: 0;
                -webkit-flex: 0 0 50%;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                float: left;
                padding: 0 20px;
                overflow-wrap: break-word;
                word-break: break-all;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }

    .sign-body + .formulate-wrapper {
      margin-top: 30px;
    }
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}

@include media-breakpoint-up (md) {
  .newsroomUpdatesForm {
    margin: 0 auto;

    .newsroom-updates {
      .formulate-wrapper {
        .formulate__form {
          .formulate__row {
            .formulate__cell {
              .formulate__field--checkbox-list {
                label {
                  width: 33.33333%;
                  -webkit-box-flex: 0;
                  -webkit-flex: 0 0 33.33333%;
                  -ms-flex: 0 0 33.33333%;
                  flex: 0 0 33.33333%;
                  max-width: 33.33333%;
                  float: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up (lg) {
  .newsroomUpdatesForm {
    .newsroom-updates {
      .formulate-wrapper {
        .formulate__form {
          .formulate__row {
            .formulate__cell {
              .formulate__field--checkbox-list {
                label {
                  width: 25%;
                  -webkit-box-flex: 0;
                  -webkit-flex: 0 0 25%;
                  -ms-flex: 0 0 25%;
                  flex: 0 0 25%;
                  max-width: 25%;
                  float: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

html.fixed {
  overflow: hidden;
}

a.overlay-form {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  z-index: 1030;
  width: 100%;
  display: block;
  height: 10000%;
  opacity: .8;
}
