.faceted-filter-block{
  &[data-document-type="fact_sheets_publications"]{
    .facets-results{
      .result-item{
        margin-top: 30px;

        &.fact-sheet-and-report{
          position: relative;
          background-color: $white;
          color: $gray-900;
          display: flex;
          flex-direction: row;
          border-left: solid $color-text-dark-orange 5px;
          min-height: 335px;

          .cart-content-img {
            position: relative;

            img {
              display: none;
              position: relative;
            }
          }

          .cart-content-text {
            width: 100%;
            padding: 30px 30px 74px;
            position: relative;

            .title {
              font-family: $font-family-sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5rem;
              text-transform: uppercase;
            }

            .sup-title {
              font-family: $font-family-sans-serif;
              font-size: 20px;
              font-weight: 500;
              line-height: 1.2rem;
              letter-spacing: 0.15px;
            }

            * + .sup-title {
              margin-top: 5px;
            }

            .text-body {
              font-family: $font-family-sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5rem;
              overflow: hidden;
            }

            * + .text-body {
              margin-top: 10px;
            }

            * + .content-btn-download {
              margin-top: 10px;
            }

            .content-btn-download {
              position: absolute;
              right: 40px;
              bottom: 40px;
              left: 0;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;

              a, button {
                color: $link-default;
              }

              .btn-download {
                max-width: 50%;
                background-color: transparent;
                border: none;
                padding: 0;
                cursor: pointer;
              }

              .line-divider {
                border-left: solid #5a5b5d 1px;
                margin: auto 20px;
                height: 18px;
              }
            }
          }

          + .cart-content {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    &[data-document-type="fact_sheets_publications"]{
      .facets-results{
        padding-right: 40px;
        padding-left: 40px;

        .result-item{
          &.fact-sheet-and-report{
            padding-left: 0;
            width: 100%;

            .cart-content-text {
              .title {
                font-size: 16px;
                font-weight: 500;
              }

              .sup-title {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2rem;
                letter-spacing: 0.15px;
              }

              * + .sub-title {
                margin-top: 5px;
              }

              .text-body {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5rem;
              }

              * + .text-body {
                margin-top: 10px;
              }

              * + .content-btn-download {
                margin-top: 10px;
              }
            }
          }
        }

        * + .result-item{
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .faceted-filter-block{
    &[data-document-type="fact_sheets_publications"]{
      .facets-results{
        padding-right: 0;
        padding-left: 0;

        .result-item{
          &.fact-sheet-and-report{
            position: relative;
            min-height: 229px;

            .cart-content-img {
              position: relative;
              overflow: hidden;
              max-width: 205px;
              width: 100%;
              min-height: 100%;

              img {
                display: initial;
                position: absolute;
                height: 100%;
                width: auto;
                left: 50%;
                @include transform(translateX(-50%));

              }
            }

            .cart-content-text {
              padding: 30px 30px 64px;

              .title {
                font-size: 16px;
                font-weight: 500;
              }

              .sup-title {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2rem;
                letter-spacing: 0.15px;
              }

              .content-btn-download{
                bottom: 30px;
              }

              * + .sub-title {
                margin-top: 5px;
              }

              .text-body {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5rem;
              }

              * + .text-body {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .faceted-filter-block{
    &[data-document-type="fact_sheets_publications"]{
      .facets-results{
        padding-right: 0;
        padding-left: 0;

        .result-item{
          &.fact-sheet-and-report{
            border-left: solid $color-text-dark-orange 5px;
            min-height: 205px;

            .cart-content-text {
              padding: 30px 30px 64px 35px;

              .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5rem;
              }

              .sup-title {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2rem;
                letter-spacing: 0.15px;
              }

              * + .sub-title {
                margin-top: 5px;
              }

              .text-body {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5rem;
              }

              * + .text-body {
                margin-top: 10px;
              }

              .content-btn-download {
                right: 30px;
              }
            }
          }
        }
      }
    }
  }
}

