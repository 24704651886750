/**
* Mobile Styles for Headings.
*/
h1,
.h1,
h1.h1,
h2.h1,
h3.h1,
h4.h1,
h5.h1,
h6.h1{
  @include rem-2(34, 44);
}

h2,
.h2,
h1.h2,
h2.h2,
h3.h2,
h4.h2,
h5.h2,
h6.h2{
  @include rem-2(34, 44);
}

h3,
.h3,
h1.h3,
h2.h3,
h3.h3,
h4.h3,
h5.h3,
h6.h3{
  @include rem-2(25, 33);
}

h4,
.h4,
h1.h4,
h2.h4,
h3.h4,
h4.h4,
h5.h4,
h6.h4{
  @include rem-2(20, 27);
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

h5,
.h5,
h1.h5,
h2.h5,
h3.h5,
h4.h5,
h5.h5,
h6.h5{
  @include rem-2(20, 24);
}

h6,
.h6,
h1.h6,
h2.h6,
h3.h6,
h4.h6,
h5.h6,
h6.h6{
  @include rem-2(16, 20);
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

.subheading{
  margin: 0;
  color: #424647;
  @include rem-2(24, 28);
  font-weight: 500;
}

h1.social-header,
h2.social-header,
h3.social-header,
h4.social-header,
h5.social-header,
h6.social-header{
  @include rem-2(20, 22);
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

h1.p,
h2.p,
h3.p,
h4.p,
h5.p,
h6.p{
  @include rem-2(17, 22);
  font-family: $font-family-sans-serif;
  font-weight: 400;
}

.number-title{
  font-family: $font-family-sans-serif;
  font-weight: 700;
  @include rem-2(41, 40);
  letter-spacing: 0;
  color: #000000;
  position: relative;
  opacity: 0.3;
  padding-left: 10px;
  padding-bottom: 7px;
  display: inline-block;

  &:after{
    content: "";
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000;
  }
}

p.large{
  color: $gray-600;
  @include rem-2(20, 25);
}

.caption{
  font-weight: normal;
  @include rem-2(14, 16);
}

.theme-dark{
  .number-title{
    color: white;

    &:after{
      background: white;
    }
  }

  h1.h7,
  h2.h7,
  h3.h7,
  h4.h7,
  h5.h7,
  h6.h7{
    color: white;
  }

  .caption{
    color: white;
  }
}

/**
* Tablet Styles for Headings.
*/
@include media-breakpoint-up(md) {
  h1,
  .h1,
  h1.h1,
  h2.h1,
  h3.h1,
  h4.h1,
  h5.h1,
  h6.h1{
    @include rem-2(42, 52);
  }

  h2,
  .h2,
  h1.h2,
  h2.h2,
  h3.h2,
  h4.h2,
  h5.h2,
  h6.h2{
    @include rem-2(36, 46);
  }

  h3,
  .h3,
  h1.h3,
  h2.h3,
  h3.h3,
  h4.h3,
  h5.h3,
  h6.h3{
    @include rem-2(27, 35);
  }
}

/**
* Desktop Styles for Headings.
*/
@include media-breakpoint-up(lg) {
  h1,
  .h1,
  h1.h1,
  h2.h1,
  h3.h1,
  h4.h1,
  h5.h1,
  h6.h1{
    @include rem-2(50, 60);
  }

  h2,
  .h2,
  h1.h2,
  h2.h2,
  h3.h2,
  h4.h2,
  h5.h2,
  h6.h2{
    @include rem-2(40, 50);
  }

  h3,
  .h3,
  h1.h3,
  h2.h3,
  h3.h3,
  h4.h3,
  h5.h3,
  h6.h3{
    @include rem-2(31, 39);
  }
}

