.image-gallery-generic-block {
  p {
    overflow-wrap: break-word;
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0;
    overflow-wrap: break-word;

    + * {
      margin-top: 40px;
    }

    + p.copy {
      overflow-wrap: break-word;
      margin-top: 15px;
    }
  }

  div.gallery-presentation {
    margin: 0 auto;
    min-height: 285px;
  }

  div .row {
    div + div.gallery-presentation {
      margin-top: 40px;
    }
  }

  .image-gallery-slider-wrapper {
    position: relative;

    .image-gallery-slider {
      white-space: nowrap;
      display: inline-flex;

      .image-gallery-item {
        display: inline-block;
        white-space: nowrap;

        + .image-gallery-item {
          margin-left: 15px;
        }

        .image {
          position: relative;
          overflow: hidden;
          height: 216px;
          width: 319px;
          @include border-radius(3px);

          a.show-more-circle {
            height: 49px;
            width: 49px;
            border-radius: 50px;
            display: block;
            background: transparent;
            position: absolute;
            right: 10px;
            left: auto;
            bottom: 10px;
            color: white;
            z-index: 2;
            transition: all .1s;

            &:before{
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 0;
              opacity: 0.3;
              background-color: #d8d8d8;
              border-radius: 50%;
            }

            i {
              margin: 13px;
            }
          }

          img {
            height: auto;
            width: 100%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            z-index: 1;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.7;
            border-radius: 3px;
            background-color: #000000;
            z-index: 1;
          }

          + *{
            margin-top: 5px;
          }
        }

        * + p.caption {
          margin-top: 5px;
          overflow-wrap: break-word;
        }

        .caption {
          max-width: 319px;
        }

        .caption-container{
          min-height: 30px;
          overflow: hidden;
        }

        &.cycle-slide-active {
          .image {
            &::after {
            display: none;
            }
          }
        }
      }

      .cycle-slide-active + .image-gallery-item {
        margin-left: 20px;
      }

      + div.image-gallery-arrow-buttons {
        margin-top: 10px;
        margin-left: 15px;
        display: flex;
        align-items: center;

        button {
          height: 35px;
          width: 35px;
          padding: 5px 0;
          border: none;
          background-color: rgba(0, 0, 0, 0.05);

          span {
            .material-icons {
              line-height: normal;
            }
          }
        }

        > span.caption-template {
          display: flex;
          span {
            background-color: rgba(0, 0, 0, 0.05);
            width: 10px;
            height: 35px;
            padding: 5px 0;
          }
        }
      }
    }
  }

  &.style-2 {
    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        width: 100% !important;

        .image-gallery-item {
          .image {
            img {
              height: auto;
              width: 345px;
            }

            &::after {
              background-color: transparent;
            }
          }
        }

        + div.image-gallery-arrow-buttons {
          margin-left: 0;
        }
      }
    }
  }
}

.theme-dark {
  .image-gallery-generic-block {
    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        + div.image-gallery-arrow-buttons {
          button {
            span {
              .material-icons {
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .image-gallery-generic-block .image-gallery-slider-wrapper .image-gallery-slider .image-gallery-item .image a.show-more-circle:hover{
    transform: scale(1.081632653061224);

    &:before{
      opacity: 0.45;
    }
  }

  .image-gallery-generic-block {
    .image-gallery-arrow-buttons {
      button.left-arrow:hover,
      button.right-arrow:hover,
      button.left-arrow:focus,
      button.right-arrow:focus {
        color: $orange;
      }
    }
  }

  .theme-dark {
    .image-gallery-generic-block {
      .image-gallery-arrow-buttons {
        button.left-arrow:hover,
        button.right-arrow:hover,
        button.left-arrow:focus,
        button.right-arrow:focus {
          color: $orange-bright;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-gallery-generic-block {
    .section-padding .limiter .container-fluid .row {
      flex-wrap: nowrap;

      > div {
        padding-right: 15px;
        margin-top: 0;
      }

      div.gallery-presentation {
        min-height: 330px;
      }

      .section-title {
        margin-top: 0;
        padding-left: 47px;
        padding-right: 7px;
      }

      .h1 {
        @include rem(36);
        line-height: 46px;
      }
    }

    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        .image-gallery-item {
          .image {
            width: 390px;
            height: 262px;
          }

          + .image-gallery-item {
            margin-left: 30px;
          }

          .caption {
            max-width: 390px;
          }
        }

        + div.image-gallery-arrow-buttons {
          margin-left: 30px;
        }
      }
    }

    &.style-2 {
      .image-gallery-slider-wrapper {
        .image-gallery-slider {
          .image-gallery-item {
            .image {
              img {
                width: 690px;
              }
            }

            + .image-gallery-item {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-gallery-generic-block {
    .section-padding .limiter .container-fluid .row {
      .h1 {
        @include rem(40);
        line-height: 50px;
      }

      div.gallery-presentation {
        min-height: 369px;
      }

      .section-title {
        margin-top: 0;
        padding-left: 47px;
        padding-right: 4px;
      }
    }

    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        .image-gallery-item {
          .image {
            width: 445px;
            height: 300px;
          }

          .caption {
            max-width: 445px;
          }
        }
      }
    }

    &.style-2 {
      .image-gallery-slider-wrapper {
        .image-gallery-slider {
          .image-gallery-item {
            .image {
              img {
                width: 530px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-gallery-generic-block {
    max-width: 1440px;
    margin: 0 auto;

    &.style-2 {
      .image-gallery-slider-wrapper {
        .image-gallery-slider {
          .image-gallery-item {
            .image {
              img {
                width: 635px;
              }
            }
          }
        }
      }
    }

      .section-padding .limiter .container-fluid .row .section-title {
        min-width: 522px;
        padding: 0 7px 0 165px;
      }
  }
}
