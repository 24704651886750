.press-release-block {
  padding: 0 15px;
  width: 100%;

  .section-padding {
    padding-bottom: 70px;
    padding-top: 40px;
  }

  .press-release-container {
    .main-title {
      margin-bottom: 30px;
    }

    .press-release-slider {
      .press-release-slide {
        .press-release-slide-left {
          margin-bottom: 20px;
          position: relative;

          .left-slider-container{
            height: 100%;
            position: relative;

            a{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 5;
            }

            .left-slider{
              height: 100%;

              .content-left{
                height: 100%;
              }
            }
          }

          .content-left {
            position: relative;
            background-color: $blue;
            padding: 40px;

            .info {
              position: relative;
              max-width: calc(100vw - 110px);
              white-space: normal;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 4;

              .date {
                font-family: $font-family-sans-serif;
                @include rem-2(20, 24);
                font-weight: 500;
                letter-spacing: 0.15px;
                color: $orange-bright;
                margin-bottom: 15px;
              }

              .content {
                font-family: $headings-font-family;
                @include rem-2(25, 27);
                color: $white;
                margin-bottom: 0;

                a {
                  color: $white;
                }
              }
            }

            .image {
              position: absolute;
              z-index: 1;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              overflow: hidden;
              background-color: $blue;

              picture {
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                z-index: 1;
                transform: translateY(-50%);

                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }

          &:after {
            content: "";
            background: $blue;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 3;
            opacity: 0.9;
          }
        }

        .right {
          position: relative;

          .content-right {
            border-top: 3px solid $color-text-dark-orange;
            background: $gray-100;
            padding: 40px 40px 34px 40px;

            .right-slider {
              min-height: 162px;
            }

            .right-slide {
              max-width: calc(100vw - 110px);
              white-space: initial;
              width: 100%;

              .date {
                font-family: $font-family-sans-serif;
                @include rem-2(16, 24);
                font-weight: 500;
                letter-spacing: 0.15px;
                color: $color-text-dark-orange;
                margin-bottom: 0;
              }

              .content-right-copy {
                font-family: $font-family-sans-serif;
                @include rem-2(16, 24);
                font-weight: 500;
                color: $gray-900;
                margin-bottom: 15px;
                min-width: 265px;
              }

              .arrow-right {
                @include rem-2(18, 16);
                font-weight: 500;
                color: $gray-900;
              }
            }
          }

          .controls {
            width: 100%;
            margin: 0;
            display: flex;

            .image-gallery-arrow-buttons {
              display: flex;
              align-items: center;

              > span.caption-template {
                display: flex;

                .pagination-count,
                .pagination-limit,
                .pagination-divider {
                  background-color: rgba(0, 0, 0, 0.05);
                  width: 10px;
                  height: 35px;
                  padding: 5px 0;
                }
              }

              button {
                height: 35px;
                width: 35px;
                padding: 5px 0;
                border: none;
                background-color: rgba(0, 0, 0, 0.05);

                span {
                  .material-icons {
                    line-height: normal;
                  }
                }
              }
            }

            .view-all {
              margin-left: auto;

              .link {
                padding-top: 10px;
                min-width: 100px;

                .view-all-link {
                  @include rem-2(18, 16);
                  z-index: 5;
                  padding: 0 30px 0 0;
                  position: relative;
                }
              }
            }
          }

          * + .controls {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.bg-neutral-medium {
  .press-release-block {
    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          .right {
            .content-right {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

.bg-neutral-dark {
  .press-release-block {
    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          .right {
            .content-right {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

.bg-dark-blue {
  .press-release-block {
    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          .right {
            .content-right {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

.theme-dark {
  .press-release-block {
    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          .right {
            .controls {
              .image-gallery-arrow-buttons {
                .left-arrow,
                .right-arrow {
                  span {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .press-release-block {
    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          .press-release-slide-left:hover {
            &:after {
              opacity: 0.7;
            }
          }

          .right {
            .controls {
              .image-gallery-arrow-buttons {
                .left-arrow,
                .right-arrow {
                  span:hover {
                    color: $orange;
                  }
                }
              }
            }

            .content-right {
              .right-slide {
                .arrow-right:hover {
                  color: $orange;
                }
              }
            }
          }
        }
      }
    }
  }

  .theme-dark {
    .press-release-block {
      .press-release-container {
        .press-release-slider {
          .press-release-slide {
            .right {
              .controls {
                .image-gallery-arrow-buttons {
                  .left-arrow,
                  .right-arrow {
                    span:hover {
                      color: $orange-bright;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .press-release-block{
    padding: 0 40px;

    .section-padding{
      padding-top: 70px;
      padding-bottom: 70px;
    }

    .press-release-container {
      margin-left: auto;

      .press-release-slider {
        .press-release-slide {
          .press-release-slide-left {
            margin-bottom: 10px;

            .content-left {
              min-height: 235px;

              .info {
                max-width: calc(100vw - 368px);

                .content {
                  a {
                    @include rem-2(27, 29);
                    color: $white;
                  }
                }
              }
            }
          }

          .right {
            .content-right {
              .right-slider {
                min-height: 138px;
              }

              .right-slide {
                .content-right-copy {
                  min-width: 400px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .press-release-block {
    .left-slider-cpntainer{
      padding: 30px;
      margin: 30px !important;
    }

    .press-release-container {
      .press-release-slider {
        .press-release-slide {
          display: flex;

          .press-release-slide-left {
            margin-bottom: 0px;
            margin-right: 30px;

            .content-left {
              max-width: 420px;

              + * {
                margin-top: 0px;
              }

              .image{
                picture {
                  width: 100%;
                  height: 100%;
                }
              }

              .info {
                .content {
                  @include rem-2(31, 33);
                  white-space: normal;
                  width: 100%;

                  a {
                    @include rem-2(31, 33);
                    color: $white;
                  }
                }
              }
            }
          }

          .right {
            .content-right {
              max-width: 278px;

              .right-slide {
                max-width: 198px;

                .content-right-copy {
                  min-width: 198px;
                }
              }

              .right-slider {
                min-height: 153px;

                .cycle-carousel-wrap {
                  .right-slide {
                    white-space: normal;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .press-release-block {
    .press-release-container {
      max-width: 979px;

      .press-release-slider {
        .press-release-slide {
          .press-release-slide-left {
          .content-left {
            max-width: 539px;
            padding-bottom: 89px;
          }
        }

          .right {
            .date {
              font-size: 16px;
              font-weight: bold;
            }

            .content-right {
              min-width: 350px;

              .right-slider {
                min-height: 164px;
              }

              .right-slide {
                max-width: 270px;

                .content-right-copy {
                  min-width: 270px;
                  margin-bottom: 21px;
                }
              }

              .arrow-right {
                @include rem-2(18, 16);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

    .sidebar-content{
      .section-row{
        .press-release-block{
          .section-padding{
            .press-release-container{
              max-width: 979px;
          }
        }
      }
    }
  }
}
