.text-image-two-columns{
  .section-padding {
    padding-bottom: 0;
    padding-top: 0;

    .col-title{
      > .title{
        margin: 0;

        .sub-title{
          max-width: 260px;
          margin-left: auto;
        }

        + .sub-title{
          margin-top: 20px;
        }
      }
    }

    .col-image{
      border-top: solid 1px #ffffff;
      position: relative;
      padding-top: 45px;
      padding-bottom: 60px;

      .text-field{
        + .image{
          margin-top: 20px;
        }
      }

      .image{
        picture{
          width: 100%;
          height: auto;
          display: block;

          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .text-image-two-columns{
    .col-image{
      flex: 1;
      max-width: 100%;
      padding: 60px 40px 60px 60px;
      border-top: none;
      border-left: solid 1px $gray-450;
    }
  }

  .theme-dark {
    .text-image-two-columns{
      .col-image{
        border-left: solid 1px $white;
        border-top: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .text-image-two-columns{
    .col-image{
      .text-field{
        padding-right: 70px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .text-image-two-columns{
    .col-image{
      padding: 70px 95px 70px 60px;

      .text-field{
        max-width: 680px;
        margin-right: auto;
        padding-right: 0;
      }
    }
  }
}
