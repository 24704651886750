.time-line-slider-generic-block {
  p {
    margin-bottom: 0;
  }

  .date-content-slider-wrapper {
    .date-content-slider {
      position: relative;
      right: 290px;
      white-space: nowrap;

      .date-content-item {
        display: inline-block;
        white-space: normal;
        position: relative;
        color: $white;
        @include border-radius(4px);

        .slide-content {
          position: relative;
        }

        .image {
          position: relative;

          img {
            height: auto;
            width: 305px;
          }

          &::after {
            content: "";
            position: absolute;
            background-color: rgba(28, 34, 64, 0.9);
            top: 265px;
            bottom: 0;
            right: 0;
            left: 0;
            @include border-radius(4px);
          }
        }

        .text-wrapper-teaser {
          position: absolute;
          top: 265px;
          padding: 12px 20px;
          text-align: center;

          h2.h4 {
            margin-bottom: 0;
          }
        }

        .text-wrapper-content {
          display: none;
          position: absolute;
          background-color: rgba(28, 34, 64, 0.9);
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 30px 30px 70px;
          @include border-radius(4px);

          h2.h2 {
            margin-bottom: 0;
          }

          a {
            display: inline-block;
            color: $white;
          }

          * + * {
            margin-top: 15px;
          }
        }

        &:hover {
          .image::after {
            display: none;
          }

          .text-wrapper-content {
            display: block;
          }

          .text-wrapper-teaser {
            display: none;
          }
        }

        + .date-content-item {
          margin-left: 15px;
        }
      }
    }
  }

  .time-line-slider-wrapper {
    .date-container {
      white-space: nowrap;
      position: relative;
      left: 38px;
      margin-bottom: 22px;
      overflow: unset !important;

      .date-item {
        display: inline-block;
        white-space: nowrap;

        &.active-item {
          @extend .h3;
        }

        + .date-item {
          margin-left: 90px;
        }

        + .date-item.active-item {
          margin-left: 80px;
        }

        &.active-item + .date-item {
          margin-left: 90px;
        }

        .date-item-content {
          position: relative;
          top: 36px;
        }

        & .date {
          position: relative;
        }

        .circle-node {
          position: relative;
          display: inline-block;
          border-radius: 50px;
          height: 10px;
          width: 10px;
          background-color: $gray-400;
          left: 50%;
          @include transform(translateX(-50%));
        }

        &.active-item .date {
          &::after {
            display: none;
          }
        }
      }
    }

    .line-container {
      height: 2px;
      background-color: $gray-400;
      white-space: nowrap;
      position: relative;
      left: 0;
      right: 0;
    }
  }

  .section-padding {
    .date-content-slider-wrapper + .time-line-slider-wrapper {
      margin-top: 30px;
    }
  }

  @include media-breakpoint-up(md) {
    .date-content-slider-wrapper {
      .date-content-slider {
        right: 495px;
        .date-content-item {
          .image {
            img {
              height: auto;
              width: 570px;
            }

            &::after {
              top: 245px;
            }
          }

          + .date-content-item {
            margin-left: 20px;
          }

          .text-wrapper-teaser {
            top: 245px;
            right: 0;
            left: 0;
          }

          .text-wrapper-content {
            padding: 40px 40px 0;
          }
        }
      }
    }

    .time-line-slider-wrapper {
      .date-container {
        left: 180px;

        .date-item {
          + .date-item {
            margin-left: 150px;
          }

          + .date-item.active-item {
            margin-left: 140px;
          }

          &.active-item + .date-item {
            margin-left: 150px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .date-content-slider-wrapper {
      .date-content-slider {
        right: 454px;
        @include transform(translateX(-50%));
      }
    }

    .time-line-slider-wrapper {
      .date-container {
        left: 300px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .date-content-slider-wrapper {
      .date-content-slider {
        right: 830px;
        @include transform(translateX(50%));
      }
    }

    .time-line-slider-wrapper {
      .date-container {
        left: 560px;
      }
    }
  }
}
