.dropdown-toggle{
  position: relative;
  padding-right: 25px;

  &:after{
    display: block;
    margin-left: 0;
    vertical-align: baseline;
    content: "\e5cf";
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    font-family: $material-icons;
    @include rem(20);
    position: absolute;
    right: 0;
    top: 50%;
    @include transform(translateY(-50%));
  }

  &.dropdown-no-icon{
    padding-right: 0;

    &:after{
      display: none;
    }
  }
}

.dropdown{
  &.show .dropdown-toggle:after{
    content: "\e5ce";
  }
}

.dropdown-menu.megamenu{
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  padding: 0;
  margin: 0;
  border: none;
  @include border-radius(0 0 0 0);

  .mainnav-close{
    position: absolute;
    top: 30px;
    right: 20px;
    color: white;
    border: none;
    @include border-radius(0 0 0 0);
    padding: 0;
    text-align: center;

    .material-icons{
      @include rem(35);
    }
  }

  .limiter{
    padding-top: 125px;
    max-width: 1255px;
    margin: 0 auto;
  }

  .menus .menu{
    width: 225px;

    > ul{
      list-style: none;
      padding: 0;
      margin: 0;

      > li.menu-item{
        a.menu-link{
          @include rem-2(16, 24);
        }

        + li.menu-item{
          margin-top: 15px;
        }

        &.parent{
          a.menu-link{
            font-family: $headings-font-family;
            @include rem-2(20, 24);
            color: $orange-bright;
          }

          + li.menu-item{
            margin-top: 20px;
          }
        }
      }
    }

    + .menu{
      margin-left: 20px;
    }
  }
}
