.modal-gallery-detail{
  display: none !important;
}

.jquery-modal {
  &.blocker {
    z-index: 1029 !important;
  }

  .modal-gallery-detail {
    display: inline-block !important;
    width: 100%;
    max-width: 100%;
    overflow-y: scroll;
    padding: 70px 0;
    border-radius: 0;
    background-color: #f4f4f4;

    .close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 20px;

      span {
        @include rem(35);
      }
    }

    .close-modal {
      display: none;
    }

    .gallery-presentation {
        .image {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }

        img{
          position: relative;
          @include border-radius(3px);
          width: 100%;
          height: auto;
          overflow: hidden;
        }

      .image-gallery-detail-arrow-buttons {
        margin-top: 10px;
        margin-left: 15px;
        display: flex;
        flex-direction: column;

        .pager-wrapper {
          display: flex;
          align-self: flex-end;

          button {
            height: 35px;
            width: 35px;
            padding: 5px 0;
            border: none;
            background-color: rgba(0, 0, 0, 0.05);

            span {
              .material-icons {
                line-height: normal;
              }
            }
          }

          span.caption-template-items {
            display: flex;
            span {
              background-color: rgba(0, 0, 0, 0.05);
              width: 10px;
              height: 35px;
              padding: 5px 0;
            }
          }
        }
      }
    }

    .gallery-presentation + .text-gallery {
      margin-top: 20px;
    }
  }
}

html.has-mouseover {
  .modal-gallery-detail {
    .gallery-presentation {
      .image-gallery-detail-arrow-buttons {
        button.prev-arrow:hover,
        button.next-arrow:hover,
        button.prev-arrow:focus,
        button.next-arrow:focus {
          color: $orange;
        }
      }
    }
  }

  .theme-dark {
    .modal-gallery-detail {
      .gallery-presentation {
        .image-gallery-detail-arrow-buttons {
          button.prev-arrow:hover,
          button.next-arrow:hover,
          button.prev-arrow:focus,
          button.next-arrow:focus  {
            color: $orange-bright;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .jquery-modal .modal-gallery-detail .gallery-presentation{
    .image{
      height: 464px;
    }
  }

  .image-gallery-detail-arrow-buttons {
    margin-top: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .jquery-modal {
    &.blocker {
      .modal-gallery-detail {
        .gallery-presentation {
          padding: 0 15px;

          img{
            width: 100%;
            height: auto;
          }
        }

        .text-gallery{
          padding: 0 15px;
        }

        .gallery-presentation + .text-gallery {
          margin-top: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .jquery-modal .modal-gallery-detail .gallery-presentation{
    .image{
      height: 427px;
    }
  }
}

