.met-in-the-news {
  padding: 70px 15px 152px;
  word-break: break-word;

  .view {
    position: relative;

    .down-button {
      position: absolute;
      top: 66px;
      right: 0;
      color: $gray-900;
    }
  }

  .header-title {
    margin-bottom: 32px;

    .title {
      font-family: $headings-font-family;
      @include rem-2(25, 27);
      color: $gray-990;

      .color {
        color: $color-text-dark-orange;
        display: inline;
      }
    }
  }

  .carousel-meet-in-te-news {
    .carousel-indicators {
      position: absolute;
      bottom: -56px;

      li{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $gray-500;
        margin: 0 5px 0;
      }

      li.active {
        background-color: $color-text-dark-orange;
      }
    }

    .carousel-inner {
      .carousel-item {
        .carousel-caption {
          text-align: left;
          padding: 0;
          top: 39px;
          left: 43px;

          a.link-arrow {
            color: $white;
          }

          .title {
            font-family: $font-family-sans-serif;
            @include rem-2(16, 24);
            font-weight: 500;
            color: $orange-bright;
          }

          .body {
            font-family: $font-family-sans-serif;
            @include rem-2(20, 24);
            font-weight: 500;
            letter-spacing: 0.15px;
            color: $white;
          }

          * + .body {
            margin-top: 11px;
          }

          .main-button {
            color: $white;
          }

          * + .main-button {
            margin-top: 28px;
          }
        }

        .carousel-cart {
          min-height: 257px;
          padding: 43px 40px 76px;
          width: 100%;
          background-color: $white;

          .title {
            font-family: $font-family-sans-serif;
            @include rem-2(16, 24);
            font-weight: 500;
            color: $color-text-dark-orange;
          }

          .body {
            font-family: $font-family-sans-serif;
            @include rem-2(20, 24);
            font-weight: 500;
            letter-spacing: 0.15px;
            color: $gray-900;
          }

          * + .body {
            margin-top: 11px;
          }

          * + .item-button {
            margin-top: 28px;
          }
        }
      }

      .image {
        background-color: $black;

        img {
          opacity: .25;
        }
      }
    }
  }
}

html.has-mouseover{
  .met-in-the-news{
    .carousel-meet-in-te-news {
      .carousel-inner {
        .carousel-item {
          .carousel-caption {
            a.link-arrow:hover{
              color: $color-text-dark-orange;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .met-in-the-news {
    padding: 70px 39px 116px 257px;

    .view {
      position: relative;

      .down-button {
        position: absolute;
        top: 30px;
        right: 0;
        color: $gray-900;
      }
    }

    .header-title {
      margin-bottom: 32px;

      .title {
        font-family: $headings-font-family;
        @include rem-2(27, 29);
        color: $gray-990;

        .color {
          color: $color-text-dark-orange;
          display: inline;
        }
      }
    }

    .carousel-meet-in-te-news {
      .carousel-indicators {
        display: none;
      }

      .carousel-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        .carousel-item.image {
          position: relative;
          display: block;
          max-height: 207px;
          margin-top: 0;
          background-color: $black;

          img {
            max-height: 207px;
          }

          .carousel-caption {
            text-align: left;

            .title {
              font-family: $font-family-sans-serif;
              @include rem-2(16, 24);
              font-weight: 500;
              color: $orange-bright;
            }

            .body {
              font-family: $font-family-sans-serif;
              @include rem-2(20, 24);
              font-weight: 500;
              letter-spacing: 0.15px;
              color: $white;
              min-width: 392px;
            }

            .main-button {
              color: $white;
            }
          }
        }

        .block-right {
          display: contents;

          .carousel-item {
            position: relative;
            padding: 0;
            display: block;
            max-height: 194px;
            min-width: 100%;
            background-color: $gray-100;

            .carousel-cart {
              position: relative;
              max-height: 194px;
              padding: 40px 40px 76px;
              width: 100%;
              background-color: $white;
              margin-top: 10px;

              .item-button {
                color: $gray-900;
              }

              .title {
                font-family: $font-family-sans-serif;
                @include rem-2(16, 24);
                font-weight: 500;
                color: $color-text-dark-orange;
              }

              .body {
                font-family: $font-family-sans-serif;
                @include rem-2(17, 22);
                font-weight: normal;
                letter-spacing: normal;
                color: $gray-900;
              }

              * + .body {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .met-in-the-news {

    .header-title {
      .title {
        @include rem-2(31, 33);
      }
    }

    .carousel-meet-in-te-news {
      .carousel-inner {
        flex-direction: row;

        .carousel-item.image {
          min-width: auto;
          width: auto;
          max-width: 225px;
          min-height: 395px;
          margin: 0;
          padding: 0;

          img {
            max-width: 225px;
            min-height: 395px;
          }

          .carousel-caption {
            min-width: auto;
            width: auto;
            padding: 0;
            top: 39px;
            left: 40px;

            .body {
              min-width: auto;
              @include rem-2(20, 24);
              font-weight: 500;
              letter-spacing: 0.15px;
            }

            .main-button {
              margin-top: 92px;
            }
          }
        }

        .block-right {
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: auto;
          width: auto;
          max-width: 472px;
          margin: 0 0 0 23px;
          padding: 0;

          .carousel-item {
            position: relative;
            padding: 40px;
            min-width: 472px;
            width: auto;
            min-height: 194px;
            background-color: $white;

            .carousel-cart {
              padding: 0;
              position: relative;
              margin: 0;
              width: 100%;
              max-height: 114px;
              max-width: 392px;
              display: table-cell;
            }
          }

          * + .carousel-item {
            margin-top: 7px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .met-in-the-news {
    padding: 70px 165px 116px 355px;

    .limiter {
      max-width: 920px;

      .carousel-meet-in-te-news {
        .carousel-inner {
          .carousel-item.image {
            max-width: 287px;
            min-width: 287px;
            min-height: 395px;

            img {
              max-width: 287px;
              min-height: 395px;
            }

            .carousel-caption {
              top: 40px;
              left: 40px;

              .main-button {
                margin-top: 140px;
              }
            }
          }

          .block-right {
            max-width: 603px;
            margin: 0 0 0 30px;

            .carousel-item {
              min-width: 603px;

              .carousel-cart {
                max-width: 523px;
              }
            }
          }
        }
      }
    }
  }
}
