.megamenu{
  .faceted-filter-block{
    background-color: #1c2240;

    .section-padding{
      background-color: #1c2240;

      .filters-buttons{
        .show-filters-text,
        .hide-filters-text{
          color: $white;
        }
      }
    }

    .facets-results{
      background-color: #f4f4f4;
    }

    .limiter-1220{
      padding-bottom: 26px;
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 130px;
    }

    form{
      max-width: 345px;
      margin: 0 auto;
    }
  }
}

.faceted-filter-block{
  &[data-document-type="all_documents"]{
      .facets-results{
      .pagination-block{
        margin-bottom: 0;
      }
    }
  }

  .section-padding{
    padding: 40px 0;
  }

  .limiter{
    margin: 0 auto;
    max-width: 770px;

    .form{
      .col-12{
        padding-right: 50px;
        padding-left: 50px;
      }
    }
  }

  #search-facets,
  .global-search{
    input{
      padding: 14px;
      border-radius: 6px;
      border: solid 1px #757575;
      background-color: #ffffff;
      height: auto;
      width: 100%;
      margin: 0 0 5px 0;
    }

    .submit-button{
      right: 25px;
      top: 50%;
      @include transform(translateY(-50%));
      padding: 0;
      margin: 0;
      border: none;
      background-color: $white;
      width: 24px;
      height: 29px;
    }
  }

  .title + .form{
    margin-top: 20px;
  }

  * + .filters-buttons{
    margin-top: 25px;
  }

  .switch-filters-block{
    .filtered-text-switcher{
      .hide-filters-text{
        display: none;
      }
    }

    .container-switcher-button{
      min-height: 40px;

      .open-filters{
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 50%;
        background-color: #ffffff;
        @include transform(rotate(0deg));

        span{
          left: 50%;
          top: 50%;
          @include transform(translate(-50%, -50%));
          color: #424647;
          width: 25px;
          height: 20px;
        }
      }
    }

    .filtered-text-switcher + .container-switcher-button{
      margin-top: 8px;
    }

    &.opened{
      .filtered-text-switcher{
        .hide-filters-text{
          display: block;
        }

        .show-filters-text{
          display: none;
        }
      }

      .container-switcher-button{
        .open-filters{
          @include transform(rotate(180deg));
        }
      }
    }
  }

  .filters-section{
    .tab-content{
      .tab-pane{
        .container-dates{
          .label-dates{
            color: $white;
          }
        }
      }
    }

    .limiter-1210{
      padding-right: 18px;
      padding-left: 18px;
    }

    .filter-actions{
      .clear-filters{
        line-height: 51px;
        display: inline-block;
      }

      .clear-filters + .apply-filters{
        margin-left: 30px;
      }
    }

    &.has-filters{
      .filter-actions{
        .clear-filters{
          display: inline-block;
        }
      }
    }

    .nav{
      display: -webkit-box;
      flex-wrap: unset;
      width: 100%;
      overflow-x: scroll;
      padding-left: 20px;
      padding-right: 20px;

      .nav-item{
        .nav-link{
          margin-bottom: 0 !important;
          border: 2px solid transparent !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          padding: 0 0 15px 0;

          &.active{
            border-bottom: 2px solid $orange-bright !important;
          }

          &:first-child{
            margin-left: 15px;
          }

          &:last-child{
            margin-right: 15px;
          }
        }

        & + .nav-item{
          margin-left: 60px;
        }
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .nav::-webkit-scrollbar{
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .nav{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .tab-content{
      .tab-pane{
        padding-top: 40px;

        .filter-item{
          .filter-button{
            padding: 7px 20px;
            border-radius: 21.5px;
            border: solid 1px #ffffff;
            background-color: #13172c;
            color: #ffffff;

            .filter-name{
              display: block;
              margin: 0;
              padding: 0;
              text-align: left;
              text-transform: capitalize;
            }

            .check-icon{
              display: none;
              right: 25px;
              top: 50%;
              @include transform(translateY(-50%));
            }
          }

          &.active{
            .filter-button{
              border: solid 1px #ffffff;
              background-color: #ffffff;

              .filter-name{
                color: #000000;
              }

              .check-icon{
                display: block;
                color: #000000;
              }
            }
          }

          & + .filter-item {
            margin-top: 20px;
          }
        }
      }
    }

    .filter-actions{
      margin-top: 40px;
    }
  }

  * + .filters-section{
    margin-top: 40px;
  }

  .facets-results{
    &.container-fluid{
      padding: 70px 15px;
    }

    .row{
      margin: 0 -15px;
    }

    .choose-view{
      .btn-group{
        button{
          color: #424647;
          background-color: #ececec;
          border-color: #c1c1c1;

          &:before {
            font-family: $awesome-icons-font;
          }

          &.active{
            background-color: #c1c1c1;
            border-color: #c1c1c1;
          }

          &:focus{
            box-shadow: none;
          }
        }
      }
    }

    .result-item{
      margin-top: 40px;
      padding: 0 15px;

      .container-card{
        border-top: 3px solid #ba4d01;
        padding: 40px 30px 86px;
        height: 100%;

        .categories{
          .title-container{
            color: #ba4d01;
            @include rem-2(16, 24);
            font-weight: 500;
            margin: 0;
            overflow: hidden;

            span.material-icons{
              @include rem-2(7, 24);
            }
          }

          .card-option{
            margin: 0;
            @include rem-2(16, 24);
            color: #424647;
            font-weight: 500;
            position: absolute;
            right: 0;
            top: -27px;
            left: 0;
            text-align: right;
            overflow: hidden;
            background-color: #f4f4f4;
          }
        }

        picture{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          overflow: hidden;
          left: 0;

          img{
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
            width: auto;
            height: 100%;
            @include transform(translateX(-50%));
          }
        }

        .title{
          overflow: hidden;
          @include rem-2(20, 24);
          color: #424647;
          margin: 0;
        }

        * + .title{
          margin-top: 20px;
        }

        .date{
          @include rem-2(14, 24);
          color: #424647;
          margin: 0;
          overflow: hidden;
        }

        * + .date{
          margin-top: 10px;
        }

        .description{
          overflow: hidden;
          color: #424647;
        }

        * + .description{
          margin-top: 10px;
        }

        .cta{
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
          padding: 0 30px;

          a{
            color: #424647;
          }
        }
      }

      button.download-button{
        position: relative;
        text-align: left;
        width: 100%;
        border-bottom: 1px solid #000;
        padding: 20px 50px 20px 30px;
      }

      & + .result-item{
        margin-top: 40px;
      }
    }

    .pagination-block{
      margin-right: 45px;
      margin-left: 45px;
    }

    * + .pagination-block{
      margin-top: 50px;
    }

    &.grids{
      .results{
        .result-item{
          &.has-image{
            .categories{
              .title-container{
                position: relative;
                z-index: 3;
                color: #ffa860;
              }
            }

            .date{
              position: relative;
              z-index: 3;
              color: #ffffff;
            }

            .description{
              position: relative;
              z-index: 3;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  &[data-document-type="press_releases"],
  &[data-document-type="newsletters"],
  &[data-document-type="news"],
  &[data-document-type="fact_sheets_publications"]{
    .facets-results{
      .result-item{
        .container-card{
          .categories{
            .title-container{
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #424647;
            }
          }

          .date{
            color: #ba4d01;
          }
        }
      }
    }
  }
}

.megamenu{
  .faceted-filter-block{
    &[data-document-type="all_documents"]{
      .facets-results{
        .results{
          .result-item{
            .container-card{
              background-color: $white;

              .cta a{
                color: #424647;
              }
            }

            &.has-image{
              &.hide-image{
                .container-card{
                  background-color: #fff;
                  color: #424647;

                  picture{
                    display: none;
                  }

                  .title{
                    color: #424647;
                  }

                  .cta a{
                    color: #424647;
                  }

                  &:after{
                    display: none;
                  }
                }
              }

              .container-card{
                background-color: #000000;

                .title{
                  position: relative;
                  z-index: 3;
                  color: #ffffff;
                }

                .cta a{
                  color: #ffffff;
                  z-index: 3;
                }

                &:after{
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  opacity: 0.75;
                  background-color: #000000;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
  }
}

.faceted-filter-block{
  &[data-document-type="post"],
  &[data-document-type="fact_sheets_publications"],
  &[data-document-type="newsroom_|_news"],
  &[data-document-type="newsroom_|_blogs"],
  &[data-document="blog"],
  &[data-document="news"],
  &[data-document="newsletter"],
  &[data-document-type="newsroom_|_videos"],
  &[data-document-type="newsroom_|_newsletters"],
  &[data-document-type="newsroom_press_releases"],
  &[data-document-type="newsroom_|_press_releases"]{
    .facets-results{
      .date-order{
        .select2-selection{
          background-color: $white !important;
        }
      }
    }
  }
}

.faceted-filter-block{
  &[data-document-type="post"],
  &[data-document-type="newsroom_|_videos"],
  &[data-document-type="newsroom_|_blogs"],
  &[data-document="blog"],
  &[data-document="news"],
  &[data-document="newsletter"],
  &[data-document-type="fact_sheets_publications"],
  &[data-document-type="general_counsel"],
  &[data-document-type="general_manager"],
  &[data-document-type="general_auditor"],
  &[data-document-type="budget_&_finance"],
  &[data-document-type="newsroom_|_news"],
  &[data-document-type="newsroom_|_newsletters"],
  &[data-document-type="newsroom_press_releases"],
  &[data-document-type="newsroom_|_press_releases"],
  &[data-document-type="board_documents"],
  &[data-document-type="other"],
  &[data-document-type="ethics_officer"],
  &[data-document-type="transparency"],
  &[data-document-type="legislative_services"],
  &[data-document-type="newsroom_stories"],
  &[data-document-type="education_resources"],
  &[data-document-type="all_documents"]{
    .facets-results{
      background-color: #f4f4f4;
    }
  }
}

.faceted-filter-block{
  &[data-document-type="newsroom_|_news"],
  &[data-document-type="newsroom_|_newsletters"],
  &[data-document-type="newsroom_press_releases"],
  &[data-document="news"],
  &[data-document="newsletter"],
  &[data-document-type="newsroom_|_press_releases"],
  &[data-document-type="board_documents"],
  &[data-document-type="other"]{
    .facets-results{
      .results{
        .result-item{
          .container-card{
            background-color: $white;
          }
        }
      }
    }
  }
}

.faceted-filter-block{
  &[data-document-type="general_counsel"],
  &[data-document-type="general_manager"],
  &[data-document-type="budget_&_finance"],
  &[data-document-type="board_documents"],
  &[data-document-type="other"],
  &[data-document-type="education_resources"],
  &[data-document-type="newsroom_stories"],
  &[data-document-type="legislative_services"],
  &[data-document-type="transparency"],
  &[data-document-type="ethics_officer"],
  &[data-document-type="general_auditor"],
  &[data-document-type="all_documents"]{
    .facets-results{
      &.grids{
        .results{
          .result-item{
            height: 459px;

            .content{
              height: calc(100% - 30px);
              overflow: hidden;
              position: relative;
            }
          }
        }
      }

      .results{
        .result-item{
          .container-card{
            background-color: $white;

            .cta a{
              color: #424647;
            }
          }

          &.has-image{
            &.hide-image{
              .container-card{
                background-color: #fff;
                color: #424647;

                picture{
                  display: none;
                }

                .title{
                  color: #424647;
                }

                .cta a{
                  color: #424647;
                }

                &:after{
                  display: none;
                }
              }
            }

            .container-card{
              background-color: #000000;

              .title{
                position: relative;
                z-index: 3;
                color: #ffffff;
              }

              .cta a{
                color: #ffffff;
                z-index: 3;
              }

              &:after{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0.75;
                background-color: #000000;
                z-index: 2;
              }
            }
          }
        }
      }
    }
  }
}

.faceted-filter-block{
  &[data-document-type="post"],
  &[data-document-type="newsroom_|_blogs"],
  &[data-document="blog"],
  &[data-document-type="newsroom_|_videos"]{
    .facets-results{
      .date-order{
        .select2-selection{
          background-color: $white !important;
        }
      }

      .results{
        .result-item{
          .container-card{
            background-color: $white;

            .content{
              .cta{
                a{
                  color: #424647;
                }
              }
            }
          }
        }
      }
    }
  }
}

.faceted-filter-block{
  .facets-results{
    .results,
    .link-items{
      .no-results{
        color: #1c2240;
      }
    }
  }

  &.theme-dark{
    .facets-results{
      background-color: #ececec;

      .result-item{
        .container-card{
          background-color: #ffffff;
          color: #424647;

          .cta a{
            color: #424647;
          }
        }

        &.has-image{
          &.hide-image{
            .container-card{
              background-color: #fff;
              color: #424647;

              picture{
                display: none;
              }

              .title{
                color: #424647;
              }

              .cta a{
                color: #424647;
              }

              &:after{
                display: none;
              }
            }
          }

          .container-card{
            background-color: #000000;

            .title{
              position: relative;
              z-index: 3;
              color: #ffffff;
            }

            .cta a{
              color: #ffffff;
              z-index: 3;
            }

            &:after{
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              opacity: 0.75;
              background-color: #000000;
              z-index: 2;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .hide-on-mobile{
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    &[data-document-type="general_counsel"],
    &[data-document-type="general_manager"],
    &[data-document-type="budget_&_finance"],
    &[data-document-type="board_documents"],
    &[data-document-type="other"],
    &[data-document-type="education_resources"],
    &[data-document-type="newsroom_stories"],
    &[data-document-type="legislative_services"],
    &[data-document-type="transparency"],
    &[data-document-type="ethics_officer"],
    &[data-document-type="general_auditor"],
    &[data-document-type="all_documents"]{
      .facets-results{
        &.grids{
          .results{
            .result-item{
              height: 490px;
            }
          }
        }
      }
    }
  }

  .megamenu{
    .faceted-filter-block{
      .limiter-1220{
        max-width: 648px;
      }

      form{
        max-width: 560px;
      }
    }
  }

  .faceted-filter-block .facets-results .result-item{
    margin-top: 40px;
  }

  .faceted-filter-block .facets-results .pagination-block{
    margin-right: 0;
    margin-left: 0;
  }

  .faceted-filter-block{
    .limiter{
      max-width: 590px;

      .title{
        .col-12{
          padding-right: 25px;
          padding-left: 25px;
        }
      }
    }

    .filters-section{
      .limiter-1220{
        max-width: 654px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    &[data-document-type="documents"]{
      .facets-results{
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    .filters-section{
      .tab-content{
        .tab-pane{
          .filter-item{
            margin-top: 20px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.faceted-filter-block {
  .section-padding {
    .tab-container{
      display: none;

      &.active{
        display: block;
      }
    }

    .limiter {
      .container-fluid {
        .form-group {
          margin: 0 auto;
          max-width: 540px;

          &.search-form{
            padding: 0 25px;
          }


          .input-field {
            margin: 20px auto;
            width: 100% !important;

            .input-group-prepend {
              border: none;
              background-color: transparent;

              .input-group-text {
                position: absolute;
                top: 25%;
                padding-top: 3px;
                right: 0;
                background: none;
                border: none;

                span {
                  display: none;
                }
              }
            }

            &.input-outlined {
              > input {
                @include border-radius(6px);
                margin: 0;
              }
            }

            &::before {
              content: "\e8b6";
              font-family: $material-icons;
              @include rem(26);
              color: $gray-901;
              display: block;
              width: 26px;
              height: 26px;
              position: absolute;
              right: 15px;
              line-height: 100%;
              top: 50%;
              @include transform(translateY(-50%));
            }
          }
        }

        .faceted-hide-filter {
          text-align: center;
          color: $white;
          background-color: transparent;
          display: block;
          margin: 0 auto;
          outline: none;
          border: none;

          p {
            margin-bottom: 8px;
            overflow-wrap: break-word;
          }

          .text-1 {
            display: none;
          }

          &.active {
            .text-1 {
              display: block;
            }

            .text-2 {
              display: none;
            }

            span.material-icons {
              @include transform (rotate(180deg));
            }
          }

          .icon-faceted-filter {
            width: 40px;
            height: 40px;
            @include border-radius(50px);
            color: $gray-900;
            background-color: $white;
            padding: 8px 7px 7px 8px;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.results{
    .pagination-buttons-container {
      margin-top: 70px;
      display: flex;
      justify-content: center;

      .link-default {
        background-color: transparent;
        color: $gray-900;

        .material-icons {
          vertical-align: middle;
        }

        &.jplist-disabled {
          color: rgba(66, 70, 71, 0.2);
        }
      }

      .jplist-holder {
        display: flex;
        justify-content: space-around;

        button {
          padding: 0 7px;//here you can change the space between each pagination button.

          &.jplist-selected {
            color: $color-text-dark-orange;
            border-bottom: 1px solid $color-text-dark-orange;
          }
        }
      }
    }
  }

  .card-view{
    .card-container{
      .card {
        border: none;
      }

      .card-title{
        margin-bottom: 0;

        .title.h3 {
          margin-bottom: 22px;
        }
      }

      .card-content{
        border: none;

        .card-body{
          border-bottom: 1px solid $gray-450;

          .card-download {
            .material-download {
              font-family: "Material Icons",sans-serif;
              font-style: normal;
              width: 25px;
              font-size: 25px;

              &::before {
                content: '\e884';
              }
            }
          }
        }
      }

      & + .section-letter {
        margin-top: 65px;
      }
    }
  }

  .tab-container {
    .tabs-wrapper {
      .nav-tabs {
        display: -webkit-box;
        flex-wrap: unset;
        width: 100%;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        padding-left: 20px;

        &::-webkit-scrollbar {
          display: none;
        }

        .nav-item {
          .nav-link {
            color: $white;
            font-weight: 500;
            padding-right: 0;
            padding-left: 0;
            font-size: 20px;
            line-height: 1.35;
            padding-bottom: 15px;
            letter-spacing: 0.15px;
            border: none;
            border-bottom: 2px solid transparent;

            &.active {
              background-color: transparent;
              border: none;
              color: $orange-bright;
              border-bottom: 2px solid $orange-bright;
            }

            &:focus{
              color: #ffa860;
              outline: none;
              border: none;
              border-bottom: 2px solid #ffa860;
            }

            &:hover{
              color: #ffa860;
              outline: none;
              border: none;
              border-bottom: 2px solid #ffa860;
            }
          }

          & + .nav-item {
            margin-left: 60px !important;
          }

          &:last-child{
            padding-right: 20px;
          }
        }
      }

      .apply-search {
        a {
          color: $blue-light;
        }

        button.link-default {
          color: $blue-light;
          background-color: transparent;
          margin-right: 20px;
        }

        .btn {
          padding: 9px 65px 8px 40px;

          &::after {
            position: absolute;
            display: block;
            top: 50%;
            right: 35px;
            @include transform(translateY(-50%));
            font-family: 'IcoFont',sans-serif;
            font-size: 1.25rem;
            content: "\ea5d";
          }
        }

        a + .btn {
          margin-left: 30px;
        }

        a.d-none + .btn {
          margin-left: 0;
        }
      }

      .tab-content {
        background-color: $blue-dark;
        padding-top: 21px;
        padding-bottom: 39px;

        .tab-pane {
          .container-fluid {
            overflow: scroll;
            max-height: 232px;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
              display: none
            }

            .radio-group {
              margin: 0 auto;
              max-width: 275px;

              label {
                width: fit-content;
                float: left;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                color: $white;
              }

              .btn {
                background-color: transparent;
                border: 1px solid $white;
                @include border-radius(21.5px);
                max-width: 289px;
                margin-top: 20px;

                input {
                  position: relative;
                  width: 0;
                }

                .input-group-prepend {
                  .input-group-text {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    background: none;
                    border: none;
                    @include transform(translateY(-50%));

                    span {
                      @include rem(19);
                      background-color: $white;
                      @include border-radius(50px);
                      font-weight: 600;
                      padding-top: 2px;
                      height: 19px;
                      width: 19px;
                      font-size: 15px;
                      color: $black;
                    }
                  }
                }

                &.active {
                  background-color: $white;
                  color: $gray-900;

                  .input-group-prepend {
                    .input-group-text {
                      span {
                        background-color: $gray-900;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }

            .radio-group.date {
              padding-top: 30px;
              margin: 0;
            }

            &.split-fields {
              align-items: initial;

              .form-elements-styles-block {
                padding-top: 30px;
                padding-left: 60px;

                form {
                  margin-left: 100px;
                }

                .field-group-date {
                  width: fit-content;
                  font-family: $font-family-sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 1.25;
                  margin-bottom: 20px;

                  label {
                    color: $white;
                    margin: 0;
                  }
                }

                .date-input {
                  display: inline-block;

                  .date-input-label {
                    margin-bottom: 5px;
                    width: fit-content;
                    font-family: $font-family-sans-serif;
                    font-size: 14px;
                    line-height: 1.14;
                    color: rgba(255, 255, 255, 0.9);
                  }

                  .input-field {
                    margin-top: 0;
                    width: 104px;

                    .datepicker.validate.valid {
                      font-family: $font-family-sans-serif;
                      font-size: 16px;
                      line-height: 1.5;
                      color: $gray-900;
                    }

                    label {
                      font-size: 12px;
                      line-height: 1.33;
                      color: $color-text-dark-orange;
                    }
                  }
                }

                & + .radio-group {
                  .group-title {
                    width: 100%;
                  }

                  .btn {
                    height: 43px;
                    padding-top: 9px;

                    .input-group-text {
                      padding: 11px 20px;
                    }

                    & + .btn {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .tab-pane + .apply-search {
          margin: 45px;
        }
      }
    }
  }
}

.bg-dark-blue .faceted-filter-block{
  &.results{
    color: $gray-900;
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block {
    &[data-document-type="documents"]{
      .facets-results{
        padding-right: 52px;
        padding-left: 52px;
      }
    }

    .section-padding {
      .limiter {
        .container-fluid {
          .form-group {
            padding: 0;
          }
        }
      }
    }

    .tab-container {
      .tabs-wrapper {
        .tab-content {
          .tab-pane {
            .container-fluid {
              .form-group {
                .input-field {
                  width: 540px;
                }
              }

              .radio-group {
                max-width: 598px;

                .btn {
                  &:nth-of-type(2n){
                    margin-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .faceted-filter-block{
    &[data-document-type="general_counsel"],
    &[data-document-type="general_manager"],
    &[data-document-type="budget_&_finance"],
    &[data-document-type="board_documents"],
    &[data-document-type="other"],
    &[data-document-type="education_resources"],
    &[data-document-type="newsroom_stories"],
    &[data-document-type="legislative_services"],
    &[data-document-type="transparency"],
    &[data-document-type="ethics_officer"],
    &[data-document-type="general_auditor"],
    &[data-document-type="all_documents"]{
      .facets-results{
        &.grids{
          .results{
            .result-item{
              height: 531px;
            }
          }
        }
      }
    }
  }

  .megamenu{
    .faceted-filter-block{
      .limiter-1220{
        max-width: 957px;
      }
    }
  }

  .faceted-filter-block {
    .limiter{
      padding-top: 23px !important;
    }

    .filters-section{
      .limiter-1220{
        max-width: 963px;
      }
    }

    .tab-container {
      .tabs-wrapper {
        .tab-content {
          .tab-pane {
            .container-fluid {
              & > .col-xl-12{
                padding-left: 0;
                padding-right: 0;
              }

              .radio-group {
                max-width: 910px;

                .btn {
                  margin-left: 20px;

                  &:nth-of-type(3n+1){
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .faceted-filter-block{
    &[data-document-type="general_counsel"],
    &[data-document-type="general_manager"],
    &[data-document-type="budget_&_finance"],
    &[data-document-type="board_documents"],
    &[data-document-type="other"],
    &[data-document-type="education_resources"],
    &[data-document-type="newsroom_stories"],
    &[data-document-type="legislative_services"],
    &[data-document-type="transparency"],
    &[data-document-type="ethics_officer"],
    &[data-document-type="general_auditor"],
    &[data-document-type="all_documents"]{
      .facets-results{
        &.grids{
          .results{
            .result-item{
              height: 459px;
            }
          }
        }
      }
    }
  }

  .megamenu{
    .faceted-filter-block{
      .limiter-1220{
        max-width: 1266px;
      }
    }
  }

  .faceted-filter-block {
    &[data-document-type="documents"]{
      .facets-results{
        .limiter-1110{
          max-width: 920px !important;
        }
      }
    }

    .filters-section{
      .limiter-1220{
        max-width: 1272px;
      }
    }

    .tab-container {
      .tabs-wrapper {
        .tab-content {
          .tab-pane {
            .container-fluid {
              max-height: 100% !important;

              .radio-group {
                max-width: 1216px;

                .btn,
                .btn:nth-of-type(3n+1) {
                  margin-left: 20px;

                  &:nth-of-type(4n+1){
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .faceted-filter-block {
    .section-padding {
      .limiter {
        .container-fluid {
          .faceted-hide-filter {
            .icon-faceted-filter:hover {
              background-color: $gray-light;
            }
          }
        }
      }
    }
  }

  .faceted-filter-block .facets-results .result-item .container-card .cta a:hover{
    color: $orange !important;
  }

  .faceted-filter-block .facets-results .result-item .cart-content-text .content-btn-download a:hover{
    color: #005486;
  }

  .faceted-filter-block .facets-results .result-item.has-image .container-card .cta a:hover{
    color: #ffa860 !important;
  }

  .faceted-filter-block .facets-results .result-item.hide-image .container-card .cta a:hover{
    color: $orange !important;
  }

  .faceted-filter-block .tab-container .apply-search button.link-default:hover {
    color: $blue-light-hover;
  }

  .faceted-filter-block.results .pagination-buttons-container button:hover {
    color: $color-text-dark-orange;
  }

  .faceted-filter-block.results .pagination-buttons-container .jplist-holder button:hover {
    color: $color-text-dark-orange;
  }

  .faceted-filter-block.results .pagination-buttons-container button.jplist-disabled:hover{
    color: rgba(66,70,71,.2);
  }

  .faceted-filter-block .facets-results.grids .results .result-item.has-image .cta a:hover{
    color: #ffa860;
  }
}
