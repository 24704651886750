.blog-block {
  padding-right: 15px;
  padding-left: 15px;

  .section-padding {
    .limiter-blog {
      margin: 0 auto;

      .item-container {
        max-width: 374px;
        margin: 0 auto;

        .title-blog {
          @include rem-2(31, 33);
          margin-bottom: 30px;
        }

        .content-block {
          .slide-item,
          .slide-item.cycle-slide {
            .slide-wrapper {
              width: 345px;
              height: 100%;
              padding-bottom: 25px;
              border-bottom: 2px solid $color-text-dark-orange;

              .category {
                @include rem-2(16, 24);
                text-transform: uppercase;
                font-weight: 500;
                text-align: right;
              }

              .image {
                position: relative;
                min-width: 245px;
                min-height: 194px;
                overflow: hidden;

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  @include transform(translate(-50%, -50%));
                  width: 100%;
                  height: auto;
                }
              }

              .wrapper {
                white-space: initial;
                padding: 20px 0 0;
                overflow-wrap: break-word;

                .date {
                  @include rem-2(16, 24);
                  font-weight: bold;
                }

                .body {
                  @include rem-2(20, 24);
                  font-weight: 500;
                }

                .cta {
                  .link-arrow {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .cycle-carousel-wrap {
            padding: 0 15px;
            display: flex;

            .slide-item {
              white-space:normal;
            }
          }
        }

        .nav {
          max-width: 50px;
          margin: 0 auto;

          span {
            display: inline-block;
            color: $gray-500;
            @include border-radius(50%);
            @include rem(35);
            width: 10px;
            height: 0;
            cursor: pointer;
            font-weight: 900;
            padding-bottom: 30px;

            & + span {
              margin-left: 10px;
            }

            &.cycle-pager-active {
              color: $color-text-dark-orange;
            }
          }
        }

        .view-all{
          margin-top: 10px;
          text-align: right;
        }
      }
    }
  }
}

@include media-breakpoint-up (md) {
  .blog-block {
    padding-right: 0;
    padding-left: 0;

    .section-padding {
      .limiter-blog {
        max-width: 729px;

        .item-container {
          max-width: 480px;
          margin: 0;

          .content-block {
            display: flex;

            .slide-item,
            .slide-item.cycle-slide {
              margin-right: 34px;
              min-width: 224px;
              min-height: 125px;

              .slide-wrapper {
                max-width: 224px;

                .image {
                  min-width: 224px;
                  min-height: 125px;
                }
              }
            }
          }

          .nav {
            max-width: 30px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up (lg) {
  .blog-block {
    .section-padding {
      .limiter-blog {
        max-width: 977px;

        .item-container {
          max-width: 728px;
          margin: 0 0 auto;

          .nav {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up (xl) {
  .blog-block {
    .section-padding {
      .limiter-blog {
        .item-container {
          max-width: 979px;

          .content-block {
            .slide-item,
            .slide-item.cycle-slide {
              min-width: 285px;
              min-height: 161px;

              .slide-wrapper {
                max-width: 285px;

                .image {
                  min-width: 285px;
                  min-height: 161px;
                }
              }
            }
          }
        }
      }
    }
  }
}
