.skiptranslate{
  display: none !important;
}

.fixed-top{
  z-index: 1020;
}

header {
  &:after{
    position: absolute;
    background: $blue;
    content: "";
    opacity: 0.9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

.gtranslate{
  border: none;
  top: 10px;

  .select2-results{
    background-color: rgba(11, 16, 33, 0.9);
    color: white;

    .select2-results__options{
      color: $blue-light;
      background-color: rgba(11, 16, 33, 0.9);

      .select2-results__option--selected{
        color: $blue-light !important;
        background-color: rgba(11, 16, 33, 0.9) !important;
      }

      .select2-results__option--highlighted{
        color: $blue-light !important;
        background-color: rgba(11, 16, 33, 0.9) !important;

      }
    }
  }
}

#g-translate-section{
  #google_translate_element2{
    display: none !important;
  }

    .select2-container{
      width: auto !important;
      }


    .select2-selection{
      background-color: transparent;
      border: none;
      color: white;
    }

    .select2-selection__rendered{
      color: white;
      width: auto;

      .full-lang{
        display: none;
      }
    }

    .select2-container{
      width: auto !important;
      }


    .select2-selection{
      background-color: transparent;
      border: none;
      color: white;
    }

    .select2-selection__rendered{
      color: white;
      width: auto;

      .full-lang{
        display: none;
      }
    }

    select.toggle-language-select-control{
      display: none;
    }

  .toggle-language-select-control{
    color: #fff;
    position: relative;
    padding-left: 26px;
    background: none;
    border: none;

    span.material-icons{
      &.lang-icon{
        position: absolute;
        top: 50%;
        left: 0;
        @include transform(translateY(-50%));
      }

      &.arrow-icon{
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        @include transform(translateY(-50%));
      }
    }

    &.opened{
      span.material-icons.arrow-icon{
          @include transform(translateY(-50%) rotate(180deg));
      }
    }
  }

  .lang-result{
    display: none;
    touch-action: none;
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: 0 0;
    border-radius: 0 0 3px 3px;
    margin: 0;
    border: none;
    width: 215px;
    top: 100%;
    overflow: hidden;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    left: 0;
    height: 0;

    .lang-results-wrapper{
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
      z-index: -9999999;
    }

    .item-link{
      text-align: left;
      width: 50%;
      max-width: none;
      padding: 0;
      margin: 0;
      margin-top: 10px;

      button{
        color: #60c4ff;
        background: none;
        border: none;
      }
    }

    &.opened{
      display: block;
      opacity: 1;
      touch-action: auto;
      height: auto;

      .lang-results-wrapper{
        z-index: 1;
      }
    }

    &:after{
      position: absolute;
      background: #0b1021;
      content: "";
      opacity: .9;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}

.google-translate-element{
  position: relative;
  z-index: 0;
}

#google_translate_element{
  background: $blue;
  position: absolute;
  z-index: 1;

  .skiptranslate{
    .goog-te-gadget-simple{
      background-color: transparent;
      color: #fff;
      border: none;

      img{
        display: none;
      }

      span{
        a.goog-te-menu-value{
          img {
            display: none;
          }

          span{
            color: $white;
            @include rem-2(16, 21);
            font-family: $font-family-sans-serif;
            font-weight: 500;
          }
        }
      }
    }
  }
}

header > .navbar{
  opacity: 1;
  z-index: 1010;
  padding: 0;
  width: 100%;

  .navbar-1{
    width: 100%;
    border-bottom: solid 1px #fff;
  }

  .logo{
    margin-top: 0;
    margin-left: 10px;
    height: 60px;

    .navbar-brand{
      padding: 18px 0;
      margin: 0;
      display: inline-block;

      img{
        display: block;
        height: 24px;
        width: auto;
      }
    }
  }

  .search-opener{
    margin: 0;
    border: none;
    padding: 0;
    @include border_radius(0 0 0 0);
    width: 46px;
    height: 60px;
    color: #fff;
    position: relative;

    .material-icons{
      @include rem(26);
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }

    &:focus{
      outline: none;
    }
  }

  .menu-opener{
    position: relative;
    width: 58px;
    height: 60px;
    padding-left: 0;
    margin: 0;
    @include border_radius(0 0 0 0);
    background: transparent;
    color: #fff;
    border: none;
    border-left: solid 1px #fff;

    &:focus{
      outline: none;
    }

    span{
      @include rem(35);
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }
  }

  .navbar-2{
    width: 100%;
    padding: 0;

    .navbar-link{
      @include rem-2(16, 21);
      font-family: $font-family-sans-serif;
      font-weight: 500;
      background: transparent;
      color: #fff;
      padding: 0;
      border: none;
      @include border_radius(0 0 0 0);

      &.dropdown-zip-toggler{
        outline: none;

        &:after{
          display: none;
          margin-left: 0;
          vertical-align: baseline;
          content: "\e5cf";
          border-top: none;
          border-right: none;
          border-bottom: none;
          border-left: none;
          font-family: $material-icons;
          @include rem(20);
          position: absolute;
          right: 0;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }
    }

    .link-item{
      white-space: nowrap;
      padding: 10px 14px;
      text-align: center;

      + .link-item{
        border-left: solid 1px #fff;
      }

      .navbar-link.dropdown-zip-toggler{
        &:after{
          display: none;
        }
      }
    }

    .dropdown-menu-zip {
      opacity: 1;
      position: absolute;
      background: transparent;
      @include border-radius (0 0 3px 3px);
      margin: 0;
      border: none;
      width: 285px;
      top: 100%;
      overflow: hidden;
      transition: all 0.5s;
      left: 0;

      &:after{
        position: absolute;
        background: $carbon;
        content: "";
        opacity: 0.9;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
        @include border-radius(6px);
        border: solid 1px $gray-901;
        @include rem(16);
        line-height: 24px;
        color: $gray-900 !important;
        max-width: 100%;
        width: 228px;
        transition: all 0.5s;
      }

      .search-result{
        text-align: left;

        .message{
          color: $white;
          font-family: $font-family-sans-serif;
          font-size: 14px;
          line-height: 16px;
          overflow-wrap: break-word;

          + *{
            margin-top: 10px;
          }
        }

        .your-water{
          .your-water-info{
            .water{
              .water-zip{
                color: $white;
                margin: 10px 0;
                font-family: $font-family-sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                overflow-wrap: break-word;
                white-space: pre-wrap;
              }

              a {
                color: $blue-light;
                overflow-wrap: break-word;
                white-space: pre-wrap;
                padding-left: 26px;
                position: relative;

                svg{
                  fill: $blue-light;
                  position: absolute;
                  left: 0;
                  height: 24px;
                  width: 16px;
                }
              }
            }
          }
        }
      }

      .zip-code-search {
        position: relative;
        overflow: hidden;
        transition: all 0.5s;

        input[type="text"]{
          padding: 0 0 0 15px;
          height: 100%;
        }

        input[type=text]::-ms-clear {
          display: none;
          width : 0;
          height: 0;
        }

        input[type=text]::-ms-reveal {
          display: none;
          width : 0;
          height: 0;
        }
      }

      .hidden{
        display: none;
      }

      &.closed{
        height: 0;
        padding: 0 20px;

        .zip-code-search{
          height: 0;
        }
      }

      &.opened{
        height: auto;
        padding: 20px 20px 41px 20px;
        z-index: 1;

        .zip-code-search{
          padding-top: 3.7rem;
        }
      }
    }
  }

  #navbarSearch{
    position: absolute;
    right: 58px;
    top: 0;
    width: 100%;
    max-width: calc(100% - 58px);
    z-index: 1;

    form{
      position: relative;

      .search-icon{
        position: absolute;
        left: 20px;
        color: $white;
        top: 50%;
        @include transform(translateY(-50%));
      }

      input{
        height: 59px;
        padding: 0 46px 0 56px;
        color: $white;
        background-color: #0f1426;
        width: 100%;
        border: none;

        &:focus{
          outline: none;
          border-bottom: 1px solid #26a69a;
          -webkit-box-shadow: 0 1px 0 0 #26a69a;
          box-shadow: 0 1px 0 0 #26a69a;
          outline: none;
        }
      }

      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }

      button{
        position: absolute;
        right: 20px;
        background: 0 0;
        box-shadow: none;
        padding: 0;
        border: 0;
        top: 50%;
        @include transform(translateY(-50%));
        height: 19px;
        width: 19px;

        &:focus{
          outline: none;
        }

        .icon-close{
          color: $white;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  header > .navbar .navbar-2 .navbar-link{
    @include rem-2(14, 18);
  }
}


@include media-breakpoint-up(md) {
  #g-translate-section{
    .toggle-language-select-control{
      display: none;

      &.fake-lang{
        display: block;
      }

      padding-right: 26px;

      span.material-icons{
        &.arrow-icon{
          display: inline-block;
        }
      }
    }
  }

  header > .navbar{
    .logo{
      margin-left: 20px;

      .navbar-brand{
        padding: 17px 0;

        img{
          height: 26px;
        }
      }
    }

    .search-opener{
      width: 68px;
    }

    .menu-opener{
      width: 59px;
    }

    .navbar-2{
      justify-content: flex-end;

      .link-item{
        position: relative;
        padding: 10px 20px;
        flex-grow: 0 !important;

        .navbar-link.dropdown-zip-toggler{
          position: relative;
          padding-right: 25px;

          &:after{
            display: block;
            margin-left: 0;
            vertical-align: baseline;
            content: "\e5cf";
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: none;
            font-family: $material-icons;
            @include rem(20);
            position: absolute;
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));
          }

          &.opened{
            &:after{
              content: "\e5ce";
            }
          }
        }

        .dropdown-menu-zip {
          @include border-radius (0 0 3px 3px);
          left: -30px;
        }

        + .link-item{
          border-left: none;
        }
      }
    }

    #navbarSearch{
      max-width: 350px;
      right: 59px
    }
  }
}

@include media-breakpoint-up(lg) {
  header {
    border-bottom: 1px solid $white;
  }

  header > .navbar{
    .logo{
      margin-left: 50px;
    }

    .navbar-1{
      border-bottom: none;
    }

    .navbar-2{
      position: absolute;
      top: 0;
      right: 137px;
      bottom: 0;
      left: 363px;
      width: auto;

      .link-item{
        padding-top: 20px;

        &:last-child{
          padding-right: 0;
        }
      }

      #g-translate-section{
        .lang-result{
          top: 101%;
        }
      }

      .dropdown-menu-zip {
        top: calc(100% + 1px);
      }
    }

    #navbarSearch{
      max-width: 570px;
    }
  }
}

html.has-mouseover{
  #g-translate-section .lang-result .item-link button:hover{
    color: #99d9ff;
  }
}

html.has-mouseover header > .navbar{
  .navbar-2{
    .dropdown-menu-zip {
      .search-result{
        .your-water{
          .your-water-info{
            .water{
              a:hover{
                color: $blue-light-hover;

                svg{
                  fill: $blue-light-hover;
                }
              }
            }
          }
        }
      }
    }
  }
}
