.article-teaser-project-block {
  word-break: break-word;

  .section-padding {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;

      .text-image-side-by-side-item {
        .image-and-caption {
          .image-n-caption-content-wrapper {
            .image {
              img {
                height: auto;
                width: 100%;
                margin-bottom: 5px;
              }
            }

            .caption {
              margin-bottom: 10px;
              @include rem-2(14, 16);
              font-family: $font-family-sans-serif;
            }
          }
        }

        .body-content {
          .wysiwyg-field {
            .h3 {
              @include rem-2(25, 27);
              font-family: $headings-font-family;
            }

            p {
              @include rem-2(16, 24);
              font-family: $font-family-sans-serif;
            }
          }

          .wysiwyg-field > h2 + p {
            margin-top: 25px;
          }

          .teaser-cta {
            margin-top: 30px;

            a {
              color: $gray-900;

              &:hover {
                color: $color-text-dark-orange;
              }
            }
          }
        }

        + .text-image-side-by-side-item {
          margin-top: 31px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .article-teaser-project-block {
    .section-padding {
      .container-fluid {
        padding-right: 40px;
        padding-left: 40px;

        .text-image-side-by-side-item {
          .body-content {
            .wysiwyg-field {
              .h3 {
                @include rem-2(27, 29);
              }
            }
          }
        }

        .text-image-side-by-side-item.swap-direction {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .article-teaser-project-block {
    .section-padding {
      .container-fluid {
        padding-right: 47px;
        padding-left: 47px;

        .text-image-side-by-side-item {
          .body-content {
            .wysiwyg-field {
              max-width: 370px;

              .h3 {
                @include rem-2(27, 29);
              }
            }

            .wysiwyg-field > h2 + p {
              margin-top: 15px;
            }
          }

          + .text-image-side-by-side-item {
            margin-top: 50px;
          }
        }

        .text-image-side-by-side-item.swap-direction {
          .body-content {
            .wysiwyg-field {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .article-teaser-project-block {
    .section-padding {
      .container-fluid {
        padding: 0;

        .text-image-side-by-side-item {
          .body-content {
            .wysiwyg-field {
              max-width: 445px;
            }
          }
        }
      }
    }
  }
}
