.sharing-panel-block, .sharing-panel-block-bio {
  position: fixed;
  top: 486px;
  right: 0;
  max-width: 42px;
  border-radius: 4px;
  border: solid 1px $white;
  z-index: 101;

  .reviewer-panel {
    .container-icon {
      margin: 9px 8px;
      max-width: 25px;
      max-height: 25px;

      .material-icons {
        background: none;
        color: $white;
        border: none;
        margin: 0;
        padding: 0;
        @include rem(25)
      }

      .ion-link{
        background: none;
        color: $white;
        border: none;
        margin: 0 0 0 -2px;
        margin-top: -10px;
        @include rem(25);
        padding: 1px 6px;
      }

      &.url-link {
        position: relative;

        .popup-advice {
          pointer-events: none;
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          padding: 10px 15px;
          left: -150px;
          border: 1px solid gray;
          border-radius: 5px;
          opacity: 0;

          span.popup-text {
            color: $white;
          }

          &:after {
            pointer-events: none;
            position: absolute;
            content: "play_arrow";
            font-family: $material-icons;
            color: $blue;
            top: 50%;
            @include transform(translateY(-50%));
            right: 12px;
            width: 0;
            font-size: 36px;
          }
        }

        &.visible {
          &:after,
          .popup-advice {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    .container-icon + .container-icon {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .sharing-panel-block {
    top: 457px;
  }
}

@include media-breakpoint-up(lg) {
  .sharing-panel-block {
    top: 453px;
  }
}

@include media-breakpoint-up(xl) {
  .sharing-panel-block {
    top: 583px;
  }
}

html.has-mouseover{
  .sharing-panel-block .reviewer-panel .container-icon .material-icons:hover{
    color: #99d9ff;
  }

  .sharing-panel-block .reviewer-panel .container-icon .ion-link:hover{
    color: #99d9ff;
  }
}

