.generic-container {
  .limiter {
    .container-fluid {
      padding: 0 15px;

      > .row{
        + .row{
          margin-top: 60px;
        }
      }

      .row-wysiwyg {
        margin: 0 -15px;

        .column-wysiwyg {
          padding: 0 15px;
        }
      }

      * + .row-wysiwyg {
        margin-top: 60px;
      }
    }
  }

  .limiter-sidebar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &.article-type-2 {
    padding: 0;

    .limiter-700 {
      .container-fluid {
        padding: 0 15px;

        .row-wysiwyg {
          margin: 0 -15px;

          .column-wysiwyg {
            padding: 0 15px;
          }
        }

        * + .row-wysiwyg {
          margin-top: 60px;
        }
      }
    }
  }

  &.article-type-3 {
    .limiter-700 {
      .container-fluid {
        .subtext {
          @include rem-2(16, 24);
          font-weight: 500;
        }
      }
    }
  }

  &.top-border.theme-dark{
    position: relative;

    &:after{
      content: "";
      background: white;
      position: absolute;
      top: 0;
      left: 25px;
      right: 25px;
      height: 1px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .generic-container{
    > .section-padding{
      > .limiter{
        > .container-fluid{
          > .row{
            > .column{
              + .column{
                margin-top: 70px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .generic-container {
    .limiter-700 {
      .container-fluid {
        padding: 0 15px;
      }
    }

    .limiter-sidebar {
      .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &.top-border.theme-dark{
      &:after{
        left: 40px;
        right: 40px;
      }
    }

    &.article-type-2 {
      padding: 0 24px 0;
    }

    &.article-type-3 {
      .limiter-700 {
        .container-fluid {
          .subtext {
            @include rem-2(20, 24);
            font-weight: 500;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .generic-container {
    .limiter {
      max-width: 960px;
    }

    .limiter-sidebar {
      .container-fluid {
        padding: 0 15px;
      }
    }

    &.top-border.theme-dark{
      &:after{
        left: 50px;
        right: 50px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .generic-container {
    .limiter {
      max-width: 1140px;
    }
  }
}

@media (min-width: 1210px) {
  .generic-container{
    &.top-border.theme-dark{
      &:after{
        max-width: 1110px;
        width: 100%;
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
      }
    }
  }
}
