.masonry-block{
  .section-padding {
    padding-bottom: 0;
    padding-top: 0;
  }

  .masonry-container{
    .masonry-img{
      width: 50%;
      height: auto;

      img{
        width: 100%;
        height: 100%;
      }

      .label{
        display: none;
      }
    }

    .masonry-img:hover{
      position: relative;
      .label{
        color: $white;
        display: none;
        position: absolute;
        top: 24px;
        left: 25px;
        right: 25px;
        z-index: 2;
        font-family: $headings-font-family;
        @include rem-2(20, 24);
      }

      .overlay{
        color: $white;
        display: block;
        opacity: 0.85;
        background-color: $blue;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      background-color: transparent;
      border: solid 1px $white;

      #cboxLoadedContent {
        margin-bottom: 0;
      }

      #cboxClose {
        top: 0;
        right: -29px;
      }
    }

    #cboxTopLeft,
    #cboxTopRight,
    #cboxMiddleLeft,
    #cboxMiddleRight,
    #cboxBottomLeft,
    #cboxBottomRight {
      height: 0 !important;
    }

    #cboxTopCenter,
    #cboxBottomCenter {
      width: 0 !important;
    }
  }

  &.image-cropping {
    img {
      margin: 0 !important;
      width: 100% !important;
    }
  }
}

@include media-breakpoint-up(md){
  .masonry-block{
    .masonry-container{
      .masonry-img{
        width: 33.333333%;
      }

      .masonry-img:hover{
        position: relative;
        .label{
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .masonry-block{
    .masonry-container{
      .masonry-img{
        width: 25%;
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .masonry-block{
    .masonry-container{
      .masonry-img{
        width: 20%;
      }
    }
  }
}

