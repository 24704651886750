.read-more-module-project-block {
  word-break: break-word;

  .image-and-caption {
    .image-n-caption-content-wrapper {
      .image {
        img {
          width: 100%;
        }
      }
    }
  }

  .limiter-1056 {
    &::before {
      position: absolute;
      width: 100%;
      height: 565px;
      content: ' ';
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #ffffff);
    }

    &.no-before::before{
      display:none;
    }

    .container-fluid {
      padding: 60px 25px 0;

      .hide-filter {
        text-align: center;

        p {
          margin-block-end: 0;
          font-size: 18px;
          font-weight: 500;
        }

        &:hover {
          color: $color-text-dark-orange;
        }
      }

      .wysiwyg-field.body-hidden {
        max-height: 505px;
        overflow: hidden;
      }

      .wysiwyg-field + .hide-filter {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .read-more-module-project-block {
    .limiter-1056 {
      &::before {
        height: 361px;
      }

      .container-fluid {
        .wysiwyg-field.body-hidden {
          max-height: 301px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .read-more-module-project-block {
    .limiter-1056 {
      &::before {
        height: 338px;
      }

      .container-fluid {
        padding-right: 162px;
        padding-left: 162px;

        .wysiwyg-field.body-hidden {
          max-height: 278px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .read-more-module-project-block {
    .limiter-1056 {
      &::before {
        height: 341px;
      }

      .container-fluid {
        padding-right: 178px;
        padding-left: 178px;

        .wysiwyg-field.body-hidden {
          max-height: 281px;
        }
      }
    }
  }
}
