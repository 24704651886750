#sign-in {
  word-break: break-word;

  .container-fluid {
    font-family: $font-family-sans-serif;
    padding: 0 15px;

    .sign-title {
      text-align: center;

      p {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .sign-body {
      text-align: center;
      margin-bottom: 30px;

      p {
        margin: 0;
      }
    }

    .sign-form {
      width: 100%;
      max-width: 325px;

      .input-field {
        width: 100%;
        margin: 0;
        margin-bottom: 31px;

        .validate {
          margin: 0;
        }

        label {
          max-width: 400px;
        }
      }

      .sign-button {
        width: 154px;
        height: 48px;
        font-size: 16px;
        background-color: $gray-300;
        border: 1px solid $gray-900;

        &::after {
          right: 30px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #sign-in {
    .container-fluid {
      padding: 0 81px;

      .sign-form {
        max-width: 445px;
      }
    }
  }
}
