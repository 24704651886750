$accordion-primary: #fafafa;
$accordion-dark-orange: #c34a00;

.accordion-generic-block {
  .accordion-primary {
    background-color: $accordion-primary;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0;
  }

  h3 button {
    font-size: 20px;
  }

  .section-padding {
    .limiter-920 {
      .container-fluid {
        .row {
          .head-text {
            padding: 0 25px;

            + div.accordion {
              margin-top: 20px;
            }
          }

          .active {
            @include box-shadow-3 (0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14));

            .card-header{
              button{
                span.material-icons{
                  @include transform (rotate(180deg));
                }
              }
            }
          }

          .accordion {
            padding: 0 15px 0 15px;

            .card {
              padding: 0;
              border-bottom: solid 3px $accordion-dark-orange;
              background-color: $accordion-primary;
              border-radius: .25rem;

              .card-header {
                border-bottom: none;
                padding: 0;
                background-color: $accordion-primary;

                .btn-block {
                  padding: 26px 20px 30px 30px;
                  box-shadow: none;

                  .text-into-dropdown {
                    max-width: 245px;
                    line-height: 1.5rem;
                    @include rem(20);
                    $font-family-sans-serif:'Roboto', sans-serif;
                    overflow-wrap: break-word;
                    color: $gray-900;
                    font-weight: 500;
                  }
                }
              }

              .card-body {
                padding: 0 50px 40px 50px;
                @include rem(16);
                line-height: 1.5em;
                overflow-wrap: break-word;
                color: $gray-900;
              }

              .card-body-style-1 {
                padding: 0 50px 0 50px;
                @include rem(16);
                line-height: 1.5em;
                overflow-wrap: break-word;
                color: $gray-900;

                p {
                  margin: 0;
                }
              }

              * + .main-content-image-short-text {
                margin-top: 20px;
              }

              .content-subtitle-text {
                p {
                  margin-bottom: 0;
                }
              }

              .main-content-image-short-text {
                padding: 0 50px 42px 50px;
                width: 100%;

                .first-content-image {
                  .img-video {
                    width: 100%;
                  }
                }

                * + .content-short-text {
                  margin-top: 5px;
                }

                .content-short-text {
                  @include rem(14);
                  line-height: 1rem;
                  $font-family-sans-serif:'Roboto', sans-serif;
                  color: $gray-901;
                  overflow-wrap: break-word;

                  p {
                    margin-bottom: 0;
                  }
                }
              }

              + .card {
                margin-top: 10px;
              }

              .content-article {
                display: block;

                .card-body-style-2 {
                  padding: 0 50px 0 50px;
                  @include rem(16);
                  line-height: 1.5em;
                  overflow-wrap: break-word;
                  color: $gray-900;
                  $font-family-sans-serif:'Roboto', sans-serif;
                }

                * + .content-item {
                  margin-top: 20px;
                }

                .content-item {
                  padding: 0 50px 42px 50px;

                  .second-content-image {
                    .img-video {
                      width: 100%;
                    }
                  }

                  * + .content-large-text {
                    margin-top: 5px;
                  }

                  .content-large-text {
                    @include rem(14);
                    line-height: 1rem;
                    $font-family-sans-serif:'Roboto', sans-serif;
                    color: $gray-901;
                    overflow-wrap: break-word;

                    p {
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .accordion-generic-block {
    .section-padding {
      .limiter-920 {
        .container-fluid {
          .row {
            .head-text {
              padding: 0 40px;
            }

            .accordion {
              padding: 0 40px 0 40px;

              .card {
                .card-header {
                  .btn-block {
                    padding: 26px 20px 25px 40px;

                    .text-into-dropdown {
                      max-width: 580px;
                    }
                  }
                }

                .card-body {
                  padding: 0 60px 40px 60px;
                }

                .card-body-style-1 {
                  padding: 0 60px 0px 60px;
                }

                .main-content-image-short-text {
                  padding: 0 60px 42px 60px;
                }

                .content-article {
                  display: flex;
                  padding: 0 60px 0 60px;

                  .card-body-style-2 {
                    padding: 0;
                    max-width: 230px;
                  }

                  .content-item {
                    margin-top: 0;
                    padding: 0 0 0 40px;
                  }
                }

                * + .content-subtitle-text {
                  margin-top: 13px;
                }

                .content-subtitle-text {
                  padding: 0 60px 40px 60px;
                  @include rem(16);
                  line-height: 1.5em;
                  overflow-wrap: break-word;
                  color: $gray-900;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .accordion-generic-block {
    .section-padding {
      .limiter-920 {
        .container-fluid {
          .row {
            .head-text {
              padding: 0 77px;
            }

            .accordion {
              padding: 0 77px 0 77px;

              .card {
                .card-header {
                  .btn-block {
                    padding: 28px 20px 31px 40px;

                    .text-into-dropdown {
                      max-width: 651px;
                      letter-spacing: 0.15px;
                    }
                  }
                }

                * + .main-content-image-short-text {
                  margin-top: 11px;
                }

                .main-content-image-short-text {
                  padding: 0 60px 40px 60px;
                }

                .content-article {
                  .card-body-style-2 {
                    padding: 0 0 0 0;
                    max-width: 240px;
                  }

                  .content-item {
                    padding: 0 0 0 40px;
                    margin-top: 0;
                  }
                }

                * + .content-subtitle-text {
                  margin-top: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .accordion-generic-block {
    .section-padding {
      .limiter-920 {
        .container-fluid {
          .row {
            .head-text {
              padding: 0;
            }

            .accordion {
              padding: 0;

              .card {
                .card-header {
                  .btn-block {
                    padding: 25px 20px 28px 40px;

                    .text-into-dropdown {
                      max-width: 810px;
                    }
                  }
                }

                .card-body {
                  padding: 0 130px 20px 60px;
                }

                .card-body-style-1 {
                  padding: 0 130px 0 60px;
                }

                * + .main-content-image-short-text {
                  margin-top: 20px;
                }

                .main-content-image-short-text {
                  padding: 0 130px 40px 60px;
                }

                .content-article {
                  padding: 0 130px 0 60px;

                  .card-body-style-2 {
                    max-width: 305px;
                  }

                  .content-item {
                    padding: 0 0 0 40px;
                    margin-top: 0;
                  }
                }

                * + .content-subtitle-text {
                  margin-top: 12px;
                }

                .content-subtitle-text {
                  padding: 12px 130px 40px 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme-dark{
  .accordion-generic-block{
    .card-body{
      .wysiwyg-field{
        color: #424647;
      }
    }
  }
}

