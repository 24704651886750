@use "sass:math";

.text-block{
  .col-info{
    padding-top: 40px;
    padding-bottom: 28px;

    .image{
      + .text-field{
        margin-top: 15px;
      }
    }
  }

  .col-title{
    padding: 40px math.div($grid-gutter-width, 2) 51px;
    text-align: right;

    > .title{
      margin: 0;
    }

    > * + .sub-title{
      margin-top: 20px;
    }

    > .sub-title{
      max-width: 260px;
      margin-left: auto;
    }
  }
}

@include media-breakpoint-up(md) {
  .text-block{
    .col-title{
      padding-top: 50px;
      padding-right: 30px;
      padding-bottom: 84px;
      padding-left: 30px;
    }

    .col-info{
      position: relative;
      padding-top: 50px;
      padding-right: 75px;
      padding-bottom: 84px;
      padding-left: 50px;
    }
  }
}
