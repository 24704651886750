.megamenu{
  .faceted-filter-block{
    &[data-document-type="all_documents"]{
      .tab-content{
        .container-dates{
          .label-text{
            @include rem-2(14, 16);
          }

          .label-date{
            @include rem-2(16, 24);
          }

          .date-start,
          .date-end{
            position: relative;

            input{
              max-width: 72px;
              height: 18px;
              padding: 27px 15px 9px !important;
            }

            .label-date{
              color: #ba4d01;
              @include rem(12);
              position: absolute;
              top: 32px;
              left: 15px;
            }
          }
        }
      }

      .facets-results{
        .results{
          max-width: 375px;
          margin: 0 auto;
        }
      }
    }
  }
}

.faceted-filter-block.documents, .faceted-filter-block.post{
  .container-fluid {
    .form-group {
      padding: 0;
    }
  }

  &.results {
    .container-fluid{
      padding: 0;
    }
  }

  .text-center {
    display: block;

    .choose-view {
      .btn-group {
        button {
          &:before {
            font-family: $awesome-icons-font;
          }
        }
      }
    }
  }

  .card-container{
    word-break: break-word;
    padding: 36px 0 66px;
    margin: 0;


    .card {
      border: none;
      @include border-radius(0);
      margin-top: 39px;
      padding-right: 15px;
      padding-left: 15px;
      background-color: transparent;

      .card-body {
        position: relative;
        padding: 37px 40px 40px 40px;
        border-top: 3px solid $color-text-dark-orange;
        background-color: $white;
        max-height: 209px;

        img.image {
          display: none;
        }

        .card-cta {
          position: absolute;
          bottom: 40px;
          left: 40px;
          height: 16px;

          .link-arrow {
            @include rem-2(18,16);
            font-weight: 500;
            font-family: $font-family-sans-serif;

            &:hover {
              color: $orange-bright;
            }
          }
        }

        .title-container {
          padding-right: 0;
          padding-left: 0;
          font-family: $font-family-sans-serif;
          @include rem-2(16,24);
          font-weight: 500;
        }

        .card-option {
          padding: 0;

          p {
            margin-right: -40px;
            margin-top: -64px;
          }
        }

        .card-text {
          margin: 0;
          @include rem-2(20,24);
          font-family: $headings-font-family;
          max-height: 216px;
          overflow: hidden;
        }

        * + .card-text {
          margin-top: 11px;
        }

        .card-option + .card-text {
          margin-top: 11px;
        }

        .card-text + .card-cta {
          a {
            color: $indigo;
          }
        }

        .card-title {
          margin: 0;
        }
      }

      &.background-image {
        .without-image {
          .card-body {
            background-color: $white;
            min-height: 370px;

            .link-arrow {
              &:hover {
                color: $orange-bright;
              }
            }
          }
        }

        .with-image {
          .card-body {
            position: relative;
            background-color: $black;
            color: $white;
            min-height: 370px;

            img.image {
              position: absolute;
              opacity: .25;
              max-width: 100%;
              top: 0;
              left: 0;
              max-height: 100%;
              display: block;
            }

            .card-option {
              color: $gray-900;
            }

            .card-cta {
              .link-arrow {
                color: $white;

                &:hover {
                  color: $orange-bright;
                }
              }
            }
          }
        }
      }

      &.generic-class {
        .without-image {
          .card-body {
            padding: 40px;

            .card-text {
              & + .card-cta {
                position: static;
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .megamenu{
    .faceted-filter-block{
      &[data-document-type="all_documents"]{
        .facets-results{
          .view-item{
            & + *{
              padding-top: 30px;
            }
          }

          .results{
            max-width: 720px;
          }
        }
      }
    }
  }

  .faceted-filter-block.documents{
    .card-container {
      padding: 36px 25px 66px;

      .card {
        &.generic-class {
          .without-image {
            .card-body {
              min-height: initial;

              .float-right {
                margin-top: 0;
                margin-right: 0;
              }
            }
          }
        }
      }

      .card.col-md-12 {
        margin-top: 20px;

        .card-body {
          .card-option {
            p {
              margin-right: 0px;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .megamenu{
    .faceted-filter-block{
      &[data-document-type="all_documents"]{
        .facets-results{
          .results{
            max-width: 960px;
          }
        }
      }
    }
  }

  .faceted-filter-block.documents {
    .limiter-920 {
      max-width: 1440px;

      .card-container {
        padding: 36px 32px 66px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .megamenu{
    .faceted-filter-block{
      &[data-document-type="all_documents"]{
        .facets-results{
          .limiter-1110{
            max-width: 1140px;

            .results{
              max-width: 1140px;
            }
          }
        }
      }
    }
  }

  .faceted-filter-block.documents {
    .limiter-920 {
      .card-container {
        padding: 36px 150px 66px;
      }
    }
  }
}

