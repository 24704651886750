.block-image-text.video-type{
  .block-image-container{
    height: 100%;
    border-bottom: 2px solid $color-text-dark-orange;

    &:last-child{
      margin-bottom: 0;
    }

    .video-img-container{
      width: 100%;
      min-width: 100%;

      .video-image{
        position: relative;

        .img-video{
          width: 100%;
        }

        .content-picture{
          overflow: hidden;
        }

        .video-btn-container{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;

          .btn-play{
            width: 65px;
            height: 65px;
          }
        }
      }
    }

    &.none{
      justify-content: flex-start;

      .video-image{
        .video-img-container{
          max-width: 100%;
        }
      }
    }

    * + .main-content-text{
      margin-top: 15px;
    }

    .main-content-text{
      .text-content{
        $font-family-sans-serif:'Roboto', sans-serif;
        @include rem-2(20, 24);
        overflow-wrap: break-word;
        letter-spacing: 0.15px;
        min-height: 54px;
        max-width: 345px;
        font-weight: 500;

        p{
          margin-bottom: 0;
        }
      }

      * + .date-content{
        margin-top: 10px;
      }

      .date-content{
        $font-family-sans-serif:'Roboto', sans-serif;
        overflow-wrap: break-word;
        max-width: 345px;
        @include rem-2(16, 24);
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
  }

  & + *{
    margin-top: 20px;
  }

  & + .button-block{
    margin-top: 40px;
  }
}

.video-player-block-list{
  &.listing{
    .container-fluid{
      .row + .global-search-pagination{
        margin-top: 58px;
      }
    }
  }

  .container-fluid{
    padding-right: 15px;
    padding-left: 15px;

    * + .image-content{
      margin-top: 32px;
    }

    .block-image-text{
      .block-image-container{
        height: 100%;
        border-bottom: 2px solid $color-text-dark-orange;

        &:last-child{
          margin-bottom: 0;
        }

        .video-img-container{
          width: 100%;
          min-width: 100%;

          .video-image{
            position: relative;

            .img-video{
              width: 100%;
            }

            .content-picture{
              overflow: hidden;
            }

            .video-btn-container{
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;

              .btn-play{
                width: 65px;
                height: 65px;
              }
            }
          }
        }

        &.none{
          justify-content: flex-start;

          .video-image{
            .video-img-container{
              max-width: 100%;
            }
          }
        }

        * + .main-content-text{
          margin-top: 15px;
        }

        .main-content-text{
          .text-content{
            $font-family-sans-serif:'Roboto', sans-serif;
            @include rem-2(20, 24);
            overflow-wrap: break-word;
            letter-spacing: 0.15px;
            min-height: 54px;
            max-width: 345px;
            font-weight: 500;

            p{
              margin-bottom: 0;
            }
          }

          * + .date-content{
            margin-top: 10px;
          }

          .date-content{
            $font-family-sans-serif:'Roboto', sans-serif;
            overflow-wrap: break-word;
            max-width: 345px;
            @include rem-2(16, 24);
            font-weight: 500;
            margin-bottom: 20px;
          }
        }
      }

      & + *{
        margin-top: 20px;
      }

      & + .button-block{
        margin-top: 40px;
      }
    }

    .button-block{
      text-align: left;
    }
  }
}

@include media-breakpoint-up(md) {
  .videos3ColBlock{
    padding-left: 40px;
    padding-right: 40px;
  }

  .block-image-text.video-type{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0;

    &:nth-child(n+3){
      margin-top: 20px;
    }
  }

  .video-player-block-list{
    &.listing{
      .container-fluid{
        padding-left: 40px;
        padding-right: 40px;

        .block-image-text{
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 0;

          &:nth-child(n+3){
            margin-top: 20px;
          }
        }
      }
    }

    .container-fluid{
      padding-right: 0;
      padding-left: 0;

      >.container-text{
        max-width: 474px;
        margin: 0 auto;
      }

      * + .image-content{
        margin-top: 12px;
      }

      .image-content{
        margin-top: 0;
        margin-left: -15px;
        margin-right: -15px;

        .title{
          padding-right: 15px;
          padding-left: 15px;
        }

        .block-image-text{
          padding-right: 15px;
          padding-left: 15px;
          margin-top: 20px;
        }

        .button-block{
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-content{
    .video-player-block-list{
      .container-fluid{
      }
    }
  }

  .block-image-text.video-type{
    &:nth-child(n+3){
      margin-top: 0;
    }

    &:nth-child(n+4){
      margin-top: 68px;
    }
  }

  .video-player-block-list{
    &.listing{
      .container-fluid{
        max-width: 960px;
        padding-left: 50px;
        padding-right: 50px;

        .block-image-text{
          &:nth-child(n+3){
            margin-top: 0;
          }

          &:nth-child(n+4){
            margin-top: 68px;
          }
        }
      }
    }

    .container-fluid{
      .image-content{
        .block-image-text{
          padding-right: 14px;
          padding-left: 14px;
        }
      }

      .button-block{
        text-align: left;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .video-player-block-list{
    &.listing{
      .container-fluid{
        max-width: 1140px;

        .block-image-text{
          &:nth-child(n+4){
            margin-top: 30px;
          }
        }
      }
    }

    .block-image-text.video-type{
      &:nth-child(n+4){
        margin-top: 30px;
      }
    }

    .container-fluid{
      max-width: 979px;

      >.container-text{
        max-width: 983px;
      }
    }
  }
}
