.job-descriptions-container {
  .section-padding {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

@include media-breakpoint-up(md) {
  .job-descriptions-container {
    .section-padding {
      .container-fluid {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .job-descriptions-container {
    .section-padding {
      .container-fluid {
        padding-right: 50px;
        padding-left: 50px;
      }
    }
  }
}
