.timeline-block {
  .image-gallery-slider-wrapper {
    .image-gallery-slider {
      white-space: nowrap;
      display: inline-flex;
      width: 100%;

      .image-gallery-item {
        position: relative !important;
        width: 100%;
        max-width: 305px;
        display: inline-block;
        white-space: normal;
        z-index: 1;

        .image-gallery-item-button{
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 999;
          width: 100%;
          height: 100px;
          border: none;
          background: transparent;

          &:focus{
            outline:none;
          }
        }

        + .image-gallery-item {
          margin-left: 15px;
        }

        .image {
          min-width: 305px;
          position: relative;
          word-break: break-word;
          min-height: 365px;
          overflow: hidden;
          @include border-radius(3px);

          .summary {
            @include transition(transform .5s ease-in-out);
            @include rem-2(16, 24);
            @include transform(translateY(275px));
            padding: 12px 40px;
            height: 100%;
            position: absolute;
            bottom: 0;
            opacity: 0.9;
            width: 100%;
            z-index: 1;

            .date {
              margin-bottom: 0;
              text-align: center;

              button{
                background: none;
                box-shadow: none;
                border: none;
                color: $white;
                margin: 0;
                padding: 0;

                &:focus,
                &:active{
                  border: none;
                }
              }
            }

            .description {
              position: relative;
              white-space: initial;
              text-align: left;
              overflow: hidden;
              display: none;

              p{
                margin-bottom: 0;
              }
            }

            .preview{
              text-align: center;
            }

            .link-arrow {
              position: relative;
              z-index: 3;
              display: none;
              color: $white;
            }

            * + * {
              margin-top: 3px;
            }
          }

          .summary.summary-hidden {
            padding: 12px 40px;
            @include border-radius(5px 5px 0px 0px);
            height: 100%;

            .description {
              text-align: left;
              max-height: 100%;

              p {
                margin-bottom: 13px;
              }
            }
          }

          img {
            position: absolute;
            height: 100%;
            width: auto;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
        }

        &.open-active {
          .image .summary {
            padding: 40px;
            @include transform(translateY(0));

            .description {
              display: block;
            }

            .preview {
              display: none;
            }

            .h4 {
              @include rem-2(34, 44);
              font-family: $headings-font-family;
              text-align: left;

              button{
                margin-top: 0 !important;
              }
            }

            .link-arrow {
              display: inline-block;
            }

            * + * {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .main-timeline-section {
      position: relative;
      width: 100%;
      height: 50px;
      margin-top: 40px;

      .conference-center-line {
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        @include transform(translateY(-50%));
        background: $gray-400;
      }

      .conference-timeline-content {
        overflow: inherit !important;
        display: inline-flex;
        white-space: nowrap;
        width: 100%;

        .timeline-article {
          max-width: 37px;
          margin: 0 23px;

          button.prev,
          button.next {
            visibility: hidden;
          }

          .content-date {
            width: 100%;
            cursor: pointer;
            display: flex;
            flex-direction: row;
          }

          .meta-date {
            width: 10px;
            height: 10px;
            background-color: #c9c9c9;
            position: absolute;
            bottom: 0;
            margin-left: 15px;
            border-radius: 50%;
          }

          &.active-slide {
            max-width: 163px;
            font-family: $headings-font-family;
            @include rem-2(31, 33);
            @include transition(all .4s ease-in-out);

            button.prev,
            button.next {
              visibility: visible;
              outline: none;
            }

            .meta-date {
              display: none;
            }

            .timeline-article-year {
              pointer-events: none;
              cursor: default;
            }
          }
        }

        .cycle-carousel-wrap {
          height: 52px;
        }
      }
    }
  }
}

.theme-dark {
  .timeline-block {
    .image-gallery-slider-wrapper {
      .main-timeline-section {
        .conference-timeline-content {
          .timeline-article {
            button.prev,
            button.next {
              color: $white;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .timeline-block {
    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        .image-gallery-item {
          min-width: 569px;

          .image {
            padding-top: 320px;
          }
        }
      }

      .main-timeline-section {
        .conference-timeline-content {
          .timeline-article {
            margin: 0 53px;
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .timeline-block {
    .image-gallery-slider-wrapper {
      .image-gallery-slider {
        .image-gallery-item {
          .summary {
            .link-arrow:hover {
              color: #ffa860;
            }
          }

          &:hover {
            .image .summary {
              padding: 40px;
              @include transform(translateY(0));

              .description {
                display: block;
              }

              .preview {
                display: none;
              }

              .h4 {
                @include rem-2(34, 44);
                font-family: $headings-font-family;
                text-align: left;

                button{
                  margin-top: 0 !important;
                }
              }

              .link-arrow {
                display: inline-block;
              }

              * + * {
                margin-top: 15px;
              }
            }
          }
        }
      }

      .main-timeline-section {
        .conference-timeline-content {
          .timeline-article {
            .content-date:hover {
              color: $orange;
            }

            button.prev:hover,
            button.next:hover {
              color: $orange;
            }
          }
        }
      }
    }
  }
}

