.search-section{
  form {
    padding: 0 25px;
    width: 100%;

    .form--inline{
      .form-item{
        width: 100%;
        display: flex;

        input{
          padding: 16px 35px 16px 40px;
          height: auto;
          width: auto;
          color: #3d3d3d;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          -ms-box-shadow: none;
          -o-box-shadow: none;
          box-shadow: none;
          -webkit-border-radius: 6px 6px 0 0;
          -moz-border-radius: 6px 6px 0 0;
          -ms-border-radius: 6px 6px 0 0;
          -o-border-radius: 6px 6px 0 0;
          border-radius: 6px 6px 0 0;
          border: none;
          border-bottom: solid 1px #0c4680;
          background: #ececec;
          margin: 0;
          flex: 1;
        }

        + * {
          margin-top: 20px;
        }
      }
    }

    .results{
      .result-item{
        button{
          text-align: left;
          width: 100%;
          border-bottom: 1px solid black;
          padding: 20px 50px 20px 30px;

          &::after{
            right: 30px;
          }

          &.external{
            &::after{
              content:'\ef71';
            }
          }
        }

        &:first-child{
          button{
            border-top: 1px solid black;
          }
        }
      }

      + * {
        margin-top: 30px;
      }
    }

    * + .results{
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-up(md) {
  .search-section{
    form {
      padding: 0 40px;

      .form--inline{
        .form-item{
          max-width: 400px;

          + * {
            margin-top: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .search-section{
    form {
      .pagination{
        button:hover {
          color: $color-text-darker-orange;

          &.jplist-disabled{
            color: #dbdcdc;
          }
        }
      }
    }
  }
}
