.t-pagination{
  border: none;

  .prev, .next{
    button{
      border: none;
    }
  }

  .paginationjs-prev{
    padding: 12px 12px 12px 0;
  }

  .paginationjs-next{
    padding: 12px 0 12px 12px;
  }

  .paginationjs-prev, .paginationjs-next{
    a, button{
      color: #424647;
      display: block;
      border: none;
      background-color: transparent;
      padding: 0;
    }

    &.disabled{
      a,button{
        color: #dbdcdc;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  .paginationjs-ellipsis{
    a{
      display: inline-block;
    }
  }

  .paginationjs-page{
    a,button{
      display: inline-block;
      color: #424647;
      @include rem-2(16, 20);
      border: none;
      border-bottom: 2px solid transparent;
      background-color: inherit;
      padding: 12px;
      font-weight: 500;
    }

    &.active{
      a,button{
        color: #c34a00;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #c34a00;
        background-color: inherit;
      }
    }
  }

  .paginationjs-ellipsis{
    a,button{
      color: #424647;
      @include rem-2(16, 20);
      border: none;
      border-bottom: 2px solid transparent;
      background-color: inherit;
      padding: 12px;
    }
  }

  .nav-item{
    .nav-link{
      color: #424647;
      @include rem-2(16, 20);
      border: none;
      border-bottom: 2px solid transparent;
      background-color: inherit;

      &.active{
        color: #c34a00;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #c34a00;
        background-color: inherit;
      }
    }
  }
}

html.has-mouseover {
  .t-pagination .paginationjs-page a:hover {
    color: #c34a00;
    border-bottom: 2px solid #c34a00;
  }

  .t-pagination .paginationjs-page button:hover {
    color: #c34a00;
    border-bottom: 2px solid #c34a00;
  }

  .t-pagination .paginationjs-ellipsis a:hover {
    color: #c34a00;
  }

  .t-pagination .paginationjs-ellipsis button:hover {
    color: #c34a00;
  }

  .t-pagination .paginationjs-prev a:hover {
    color: #424647;
  }

  .t-pagination .paginationjs-prev button:hover {
    color: #424647;
  }

  .t-pagination .paginationjs-next a:hover {
    color: #424647;
  }

  .t-pagination .paginationjs-next button:hover {
    color: #424647;
  }

  .t-pagination .paginationjs-prev.disabled a:hover {
    color: #dbdcdc;
  }

  .t-pagination .paginationjs-prev.disabled button:hover {
    color: #dbdcdc;
  }

  .t-pagination .paginationjs-next.disabled a:hover {
    color: #dbdcdc;
  }

  .t-pagination .paginationjs-next.disabled button:hover {
    color: #dbdcdc;
  }
}
