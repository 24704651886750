.fact-sheets-reports-block {
  padding-right: 15px;
  padding-left: 15px;
  word-break: break-word;

  .cart-container {
    width: 100%;
    position: relative;

    .header-title {
      margin-bottom: 40px;

      .title {
        font-family: $headings-font-family;
        font-size: 25px;
        line-height: 1rem;

        .color {
          color: $orange-bright;
          display: inline;
        }
      }
    }

    .content-link {
      padding-top: 30px;
      text-align: right
    }

    .cart-content {
      position: relative;
      background-color: $white;
      color: $gray-900;
      display: flex;
      flex-direction: row;
      border-left: solid $color-text-dark-orange 5px;
      min-height: 335px;

      .cart-content-img {
        position: relative;

        img {
          display: none;
          position: relative;
        }
      }

      .cart-content-text {
        width: 100%;
        padding: 30px 30px 74px;
        position: relative;

        .title {
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5rem;
          text-transform: uppercase;
        }

        .sup-title {
          font-family: $font-family-sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.2rem;
          letter-spacing: 0.15px;
        }

        * + .sup-title {
          margin-top: 5px;
        }

        .text-body {
          font-family: $font-family-sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5rem;
        }

        * + .text-body {
          margin-top: 10px;
        }

        * + .content-btn-download {
          margin-top: 10px;
        }

        .content-btn-download {
          position: absolute;
          right: 40px;
          bottom: 40px;
          left: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          a, button {
            color: $link-default;
          }

          .btn-download {
            max-width: 50%;
            background-color: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
          }

          .line-divider {
            border-left: solid #5a5b5d 1px;
            margin: auto 20px;
            height: 18px;
          }
        }
      }

      + .cart-content {
        margin-top: 20px;
      }
    }
  }
}

.bg-neutral-light {
  .fact-sheets-reports-block {
    .cart-container {
      .cart-content {
        background: $gray-100;
      }
    }
  }
}

html.has-mouseover {
  .fact-sheets-reports-block {
    .cart-container {
      .cart-content {
        .cart-content-text {
          .content-btn-download {
            a:hover, button:hover {
              color: #005486;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .fact-sheets-reports-block{
    padding-right: 40px;
    padding-left: 40px;

    .cart-container{
      margin-left: auto;

      .header-title {
        margin-bottom: 40px;

        .title {
          font-size: 25px;
          line-height: 1rem;
        }
      }

      .cart-content {
        width: 100%;

        .cart-content-text {
          .title {
            font-size: 16px;
            font-weight: 500;
          }

          .sup-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2rem;
            letter-spacing: 0.15px;
          }

          * + .sub-title {
            margin-top: 5px;
          }

          .text-body {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5rem;
          }

          * + .text-body {
            margin-top: 10px;
          }

          * + .content-btn-download {
            margin-top: 10px;
          }
        }
      }

      * + .cart-content {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .fact-sheets-reports-block {
    .cart-container {
      .header-title {
        .title {
          font-size: 31px;
          line-height: 1.06rem;
        }
      }

      .cart-content {
        position: relative;
        min-height: 229px;

        .cart-content-img {
          position: relative;
          overflow: hidden;
          min-width: 205px;
          min-height: 205px;

          img {
            display: block;
            position: absolute;
            height: auto;
            width: 100%;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));

          }
        }

        .cart-content-text {
          padding: 30px 30px 64px;

          .title {
            font-size: 16px;
            font-weight: 500;
          }

          .sup-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2rem;
            letter-spacing: 0.15px;
          }

          .content-btn-download{
            bottom: 30px;
          }

          * + .sub-title {
            margin-top: 5px;
          }

          .text-body {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5rem;
          }

          * + .text-body {
            margin-top: 10px;
          }
        }
      }

      * + .cart-content {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .fact-sheets-reports-block {
    .cart-container {
      max-width: 979px;
      margin: 0 auto;

      .header-title {
        margin-bottom: 40px;

        .title {
          font-size: 31px;
          line-height: 1.06rem;
        }
      }

      .cart-content {
        border-left: solid $color-text-dark-orange 5px;
        min-height: 205px;

        .cart-content-text {
          padding: 30px 30px 64px 35px;

          .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5rem;
          }

          .sup-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2rem;
            letter-spacing: 0.15px;
          }

          * + .sub-title {
            margin-top: 5px;
          }

          .text-body {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5rem;
          }

          * + .text-body {
            margin-top: 10px;
          }

          .content-btn-download {
            right: 30px;
          }
        }
      }

      .content-link {
        padding-top: 50px;
      }

      * + .cart-content {
        margin-top: 20px;
      }
    }
  }
}
