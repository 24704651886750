.video-block-list {
  .limiter-sidebar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;

      .main-title {
        color: $gray-990;
        margin: 0;
      }

      .block-image-text {
        margin-top: 40px;
        margin-bottom: 0;

        .video-img-container {
          width: 100%;
          min-width: 100%;

          .video-image {
            position: relative;

            .img-video {
              width: 100%;
            }

            .content-picture {
              overflow: hidden;
            }

            .video-btn-container {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;

              .btn-play {
                width: 65px;
                height: 65px;
              }
            }
          }
        }

        &.none {
          justify-content: flex-start;
            .video-image {
              .video-img-container {
                max-width: 100%;
              }
            }
        }

        * + .main-content-text {
          margin-top: 15px;
        }

        .main-content-text {
          .text-content {
            $font-family-sans-serif:'Roboto', sans-serif;
            @include rem-2(20, 24);
            overflow-wrap: break-word;
            letter-spacing: 0.15px;
            color: $gray-900;
            min-height: 54px;
            max-width: 345px;
            font-weight: 500;

            p {
              margin-bottom: 0;
            }
          }

          * + .date-content {
            margin-top: 10px;
          }

          .date-content {
            $font-family-sans-serif:'Roboto', sans-serif;
            overflow-wrap: break-word;
            max-width: 345px;
            max-height: 20px;
            @include rem-2(16, 24);
            font-weight: 500;
          }

          * + .line-orange {
            margin-top: 20px;
          }

          .line-orange {
            width: 100%;
            height: 2px;
            background-color: $color-text-dark-orange;
          }
        }
      }

      .block-image-text + .block-image-text {
        margin-bottom: 0;
        margin-top: 20px;
      }

      * + .button-block {
        margin-top: 30px;
      }

      .button-block {
        text-align: right;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .video-block-list {
    .limiter-sidebar {
      .container-fluid {
        padding-right: 0;
        padding-left: 0;

        .block-image-text + .block-image-text {
          margin-top: 40px;
        }

        .image-cotent {
          max-width: 504px;
          margin-right: -15px;
          margin-left: auto;

          .title {
            padding-right: 15px;
            padding-left: 15px;
          }

          .block-image-text {
            padding-right: 15px;
            padding-left: 15px;
          }
        }

        .button-block {
          text-align: left;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .video-block-list {
    .limiter-sidebar {
      .container-fluid {
        .image-cotent {
          max-width: 750px;

          .block-image-text {
            padding-right: 14px;
            padding-left: 14px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .video-block-list {
    .limiter-sidebar {
      .container-fluid {
        .image-cotent {
          max-width: 1007px;
          padding-right: 59px;
        }
      }
    }
  }
}
