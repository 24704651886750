.form-elements-styles-block.form-content {
  padding: 0;

  .limiter {
    margin: 0 auto;

    .formulate__form {
      .formulate__row {
        display: flex;
        flex-wrap: wrap;

        .formulate__cell {
          .formulate__field {
            .formulate__field__label.active {
              top: 7px;
              font-family: $font-family-sans-serif;
              @include rem-2(12, 16);
              color: #007dba;
            }

            input {
              font-family: $font-family-sans-serif;
              @include rem-2(16, 24);
              color: #424647;
            }
          }

          .formulate__field--radio-list,
          .formulate__field--checkbox-list {
            display: flex;
            flex-direction: column;
          }

          .formulate__field--select {
            margin-bottom: 30px;

            .formulate__field__label {
              position: absolute;
              top: 7px;
              z-index: 1;
              margin-left: 15px;
              font-family: $font-family-sans-serif;
              @include rem-2(12, 16);
              color: #007dba;
            }
          }

          .select-selected {
            position: relative;
            border-radius: 3px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
            background-color: #fafafa;

              select {
                display: none;
              }

              &::after {
                position: absolute;
                content: "";
                top: 14px;
                right: 10px;
                width: 0;
                height: 0;
                border: 6px solid transparent;
                border-color: #424647 transparent transparent transparent;
              }
          }

          .select-selected.select-arrow-active:after {
            border-color: transparent transparent #424647 transparent;
            top: 7px;
          }

          .select-items div,.select-selected {
            color: #424647;
            background-color: #f2f2f2;
            padding: 23px 15px 9px;
            border: 1px solid transparent;
            border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
            cursor: pointer;
            user-select: none;
          }

          .select-items {
            position: absolute;
            color: #424647;
            background-color: #f2f2f2;
            top: 66px;
            left: 0;
            right: 0;
            z-index: 99;
          }

          .select-hide {
            display: none;
          }

          .select-items div:hover, .same-as-selected {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        .formulate__cell--4-columns,
        .formulate__cell--6-columns {
          position: relative;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .form-elements-styles-block.form-content {
    .formulate__form {
      .formulate__row {
        .formulate__cell {
          .formulate__field {
            .select-items {
              left: 25px;
            }
          }
        }

        .formulate__cell--4-columns{
          padding-left: 25px;
          padding-right: 25px;
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
          min-width: 230px;
        }

        .formulate__cell--6-columns {
          padding-left: 25px;
          padding-right: 25px;
          flex: 0 0 50%;
          max-width: 50%;
        }

      }
    }
  }
}
