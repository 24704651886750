.form-elements-styles-block {
  padding: 70px 0;
  overflow-wrap: break-word;

  .container-fluid {
    .form-group {
      .helper-error-text {
        margin: 10px 0 0;
        font-family: $font-family-sans-serif;
        @include rem-2(12, 16);
        color: $input-error-color;
      }
    }

    .input-field + .input-field {
      margin-top: 30px;
    }

    .form-date {
      .col-sm-12 {
        .input-field {
          max-width: 157px;

          .datepicker {
            padding: 16px 7px 16px 8px;
          }

          .datepicker.valid,
          .datepicker.invalid {
            padding: 23px 7px 9px 8px;
          }

          .datepicker-day {
            padding: 16px 8px;
          }

          .datepicker-day.valid,
          .datepicker-day.invalid {
            padding: 23px 8px 9px;
          }

          label{
            left: 8px;
          }
        }
      }
    }

    .form-input {
      .formulate__form {
        .formulate__row {
          .formulate__cell {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;

            .formulate__field--text {
              position: relative;
              margin-top: 1rem;
              margin-bottom: 1rem;

              input {
                border: 1px solid #757575;
                padding: 0px 0 0 15px;
                float: none;
                display: block;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border-radius: 3px;
              }

              label {
                position: absolute;
                top: 16px;
                left: 16px;
                color: #757575;
              }
            }
          }
        }
      }
    }

    .form-input,
    .form-dropdown {
      .col-sm-7 {
        .input-field {
          max-width: 157px;
        }
      }
    }

    .form-dropdown {
      .form-group {
        .input-field {
          .select-wrapper {
            &::before {
              height: 20px;
              width: 20px;
              display: block;
              content: '\e313';
              font-family: $material-icons-font;
              position: absolute;
              right: 10px;
              z-index: 2;
              top: 16px;
              color: $gray-900;
              @include rem(20);
            }
          }

          .select-wrapper.active {
            &::before {
              height: 20px;
              width: 20px;
              display: block;
              content: '\e316';
              font-family: $material-icons-font;
              position: absolute;
              right: 10px;
              z-index: 2;
              top: 16px;
              color: $gray-900;
              @include rem(20);
            }
          }
        }

        .input-field.valid,
        .input-field.invalid {
          .select-dropdown {
            padding: 10px 15px 9px;
          }

          .dropdown-content {
            padding: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .form-elements-styles-block {
    padding-left: 24px;
    padding-right: 24px;

    .limiter {
      max-width: 800px;

      .container-fluid {
        padding-left: 15px;
        padding-right: 15px;

        .form-input,
        .form-dropdown {
          margin-left: -15px;
          margin-right: -15px;
          padding: 0;

          .col-sm-12,
          .col-md-6,
          .col-md-4,
          .col-md-2 {
            padding-right: 15px;
            padding-left: 15px;
          }

          .col-md-6 {
            max-width: 360px;
          }

          .col-md-4 {
            .input-field {
              max-width: 210px;
            }
          }
        }

        .form-date {
          padding-right: 25px;
          padding-left: 25px;

          .col-md-6 {
            padding-right: 15px;
            padding-left: 15px;

            .input-field {
              max-width: 90px;
            }
          }

          .col-md-6 + .col-md-6 {
            .input-field {
              max-width: 121px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .form-elements-styles-block {
    .container-fluid {
      .form-input,
      .form-dropdown,
      .form-checkbox {
        .col-md-6 {
          padding-right: 15px;
          padding-left: 15px;
          max-width: 400px;
        }

        .col-md-4 {
          max-width: 240px;
        }

        .col-lg-3 {
          max-width: 160px;
        }
      }

      .form-date {
        padding-right: 60px;
        padding-left: 60px;

        .col-md-6 {
          .input-field {
            max-width: 130px;
          }
        }

        .col-md-6 + .col-md-6 {
          .input-field {
            max-width: 130px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .form-elements-styles-block {
    .container-fluid {
      .form-input,
      .form-dropdown,
      .form-checkbox {
        padding-right: 0;
        padding-left: 0;

        .col-md-6 {
          max-width: 475px;
        }

        .col-md-4 {
          max-width: 285px;

          .input-field {
            max-width: 255px;
          }
        }

        .col-lg-3 {
          max-width: 175px;

          .input-field {
            max-width: 160px;
          }
        }
      }

      .form-date {
        padding-right: 0;
        padding-left: 0;

        .col-md-6 {
          .input-field {
            max-width: 160px;
          }
        }

        .col-md-6 + .col-md-6 {
          .input-field {
            max-width: 160px;
          }
        }
      }
    }
  }
}
