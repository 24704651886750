[type=checkbox] + span:not(.lever):before, [type=checkbox]:not(.filled-in) + span:not(.lever):after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

[type=checkbox]:not(.filled-in) + span:not(.lever):after{
  border: 0;
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

[type=checkbox] + span:not(.lever):before{
  border: 2px solid #007dba;
}

[type=checkbox] + span:not(.lever){
  display: inline;
  padding-left: 25px;
}

[type=checkbox] + span:not(.lever){
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

[type=checkbox]:checked + span:not(.lever):before{
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #007dba;
  border-bottom: 2px solid #007dba;
}

[type=checkbox]:checked + span:not(.lever):before{
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #007dba;
  border-bottom: 2px solid #007dba;
  -webkit-transform: rotate( 40deg );
  -o-transform: rotate(40deg);
  transform: rotate( 40deg );
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/*
 * Radio Buttons
 */

[type=radio]:checked, [type=radio]:not(:checked){
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

input[type=checkbox], input[type=radio]{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type=radio]:checked+span, [type=radio]:not(:checked)+span{
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  -o-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type=radio]:checked+span, [type=radio]:not(:checked)+span{
  padding-left: 30px;
}

[type=radio]+span:after, [type=radio]+span:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  -o-transition: .28s ease;
  transition: .28s ease;
}

[type=radio].with-gap:checked+span:after, [type=radio].with-gap:checked+span:before, [type=radio]:checked+span:after, [type=radio]:checked+span:before, [type=radio]:not(:checked)+span:after, [type=radio]:not(:checked)+span:before{
  border-radius: 50%;
}

[type=radio]:checked+span:before{
  border: 2px solid transparent;
}

[type=radio]:checked+span:after{
  -webkit-transform: scale(1.02);
  -o-transform: scale(1.02);
  transform: scale(1.02);
}

[type=radio].with-gap:checked+span:after, [type=radio]:checked+span:after{
  background-color: #007dba;
}

[type=radio].with-gap:checked+span:after, [type=radio].with-gap:checked+span:before, [type=radio]:checked+span:after{
  border: 2px solid #007dba;
}

[type=radio]:not(:checked)+span:after, [type=radio]:not(:checked)+span:before{
  border: 2px solid #5a5a5a;
}

[type=radio]:not(:checked)+span:after, [type=radio]:not(:checked)+span:before{
  border: 2px solid #007dba;
}

.modal.with-forms{
  background-color: $white;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-height: calc(100vh - 86px);
  overflow-y: auto !important;
  overflow-x: hidden !important;

  &:-webkit-scrollbar{
    display: none;
  }
}

/* TinyMCE */
.mce-content-body{
  .umbraco-forms-form{
    &.umbraco-forms-MWD{
      .umbraco-forms-page{
        .umbraco-forms-fieldset{
          .umbraco-forms-container{
            .shortanswer{
              width: 325px;
              margin: auto !important;

              .umbraco-forms-field-wrapper{
                position: relative;
                margin-bottom: 0;
              }

              input{
                height: 58px;
                @include rem(16);
                border: solid 1px #757575;
                border-radius: 3px;
                padding: 7px 15px;
                background-color: $white;
              }

              input:focus{
                box-shadow: none;
              }

              input:focus ~ label{
                @include rem(12);
                top: 0;
                color: #007dba;
              }

              input.input-validation-error{
                border: solid 1px #ef3340;
              }

              input.input-validation-error ~ label.label-field{
                @include rem(12);
                top: 0;
                color: #ef3340;
              }

              input.valid{
                border: solid 1px #007dba;
              }

              input.valid ~ label.label-field{
                @include rem(12);
                top: 0;
                color: #007dba;
              }

              ::placeholder{
                color: #bcbcbc;
              }

              .field-validation-error{
                position: absolute;
                color: #ef3340;
                padding: 5px 15px;
                bottom: -34px;

                span{
                  white-space: nowrap;
                }
              }

              label.label-field{
                transition: all .2s;
                max-width: 375px;
                margin: 0;
                @include rem(16);
                line-height: 24px;
                color: #424647;
                position: absolute;
                top: 17px;
                left: 16px;
              }
            }

            .checkboxlist{
              float: none;
              display: flex;
              width: 100%;
              margin: 0;

              .checkbox-item{
                cursor: pointer;
                margin: 0;
                margin-top: 15px;
                user-select: none;
                padding: 0;
                max-width: 100%;

                input{
                  display: inline;
                  width: 20px;
                  height: 20px;
                  float: left;
                  border-color: #007dba;
                  display: none !important;

                  &:focus{
                    border-color: #007dba;
                  }
                }

                label{
                  margin-left: 5px;
                  display: inline;
                }
              }

              .checkbox-item:nth-child(odd){
                flex: 0 0 60%;
              }

              .checkbox-item:nth-child(even){
                flex: 0 0 40%;
              }
            }

            .umbraco-forms-field{
              position: relative;
              margin: 0;

              .g-recaptcha{
                > div{
                  margin: auto;
                }
              }

              & + .umbraco-forms-field{
                margin-top: 40px;
              }

              & + .umbraco-forms-field.multiplechoice{
                margin-top: 30px;
              }
            }
          }
        }
      }
    }

    &.umbraco-forms-FormElements{
      .checkboxlist{
        float: none;
        display: flex;

        .checkbox-item{
          cursor: pointer;
          margin: 0;
          margin-top: 15px;
          user-select: none;

          input{
            display: inline;
            width: 20px;
            height: 20px;
            float: left;
            border-color: #007dba;
            display: none !important;

            &:focus{
              border-color: #007dba;
            }
          }

          label{
            margin-left: 5px;
            display: inline;
          }
        }
      }

      .shortanswer{
        max-width: 325px;
        margin: auto !important;

        .umbraco-forms-field-wrapper{
          position: relative;
          margin-bottom: 34px;
        }

        input{
          height: 56px;
          @include rem(16);
          border: solid 1px #757575;
          border-radius: 3px;
          padding: 7px 15px;
          background-color: $white;
        }

        input:focus{
          box-shadow: none;
        }

        input:focus ~ label.label-field{
          @include rem(12);
          top: 0;
          color: #007dba;
        }

        input.input-validation-error{
          border: solid 1px #ef3340;
        }

        input.input-validation-error ~ label.label-field{
          @include rem(12);
          top: 0;
          color: #ef3340;
        }

        input.valid{
          border: solid 1px #007dba;
        }

        input.valid ~ label.label-field{
          @include rem(12);
          top: 0;
          color: #007dba;
        }

        .field-validation-error{
          position: absolute;
          color: #ef3340;
          padding: 5px 15px;
          bottom: -34px;
        }

        label.label-field{
          transition: all .2s;
          max-width: 375px;
          margin: 0;
          @include rem(16);
          line-height: 24px;
          color: #424647;
          position: absolute;
          top: 16px;
          left: 16px;
        }
      }

      .radiobuttonlist{
        padding: 10px;

        .form-check{
          margin: 0;
          margin-top: 15px;
        }
      }

      .dropdown{
        .umbraco-forms-field-wrapper{
          margin-bottom: 34px;

          &.error{
            .label-field{
              font-size: 12px;
              font-size: .75rem;
              top: 0;
              color: #ef3340;
            }

            .dropdown{
              .dropdown-toggle{
                border: solid 1px #ef3340;
              }
            }
          }

          &.success{
            .label-field{
              font-size: 12px;
              font-size: .75rem;
              top: 0;
              color: #007dba;
            }

            span[class*="field-validation-"]{
              display: none;
            }

            .dropdown{
              .dropdown-toggle{
                border: solid 1px #007dba;
              }
            }
          }

          .dropdown{
            .dropdown-toggle{
              padding: 0 15px;
              height: 56px;
              line-height: 56px;
              background-color: #ffffff;
              border-radius: 3px;
              border: solid 1px #757575;
              box-shadow: none;

              .filter-option{
                .filter-option-inner{
                  .filter-option-inner-inner{
                    color: #424647;
                  }
                }
              }

              &:focus{
                box-shadow: none;
                outline: none !important;
                outline-offset: none;
              }

              &:after{
                margin-right: 10px;
                color: #424647;
              }
            }

            > .dropdown-menu{
              padding: 0;
              margin: 0;
              border: none;
              border-radius: 3px;
              box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
              background-color: #fafafa;

              .dropdown-menu{
                padding: 10px 0;

                li{
                  padding: 0;

                  .dropdown-item{
                    color: #424647;

                    &.active{
                      background-color: #f2f2f2 !important;
                    }

                    &:active{
                      background-color: #f2f2f2 !important;
                    }
                  }
                }
              }
            }
          }

          .dropdown.show ~ .label-field{
            @include rem(12);
            top: 0;
            color: #007dba;
          }

          .label-field{
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            max-width: 375px;
            margin: 0;
            font-size: 16px;
            font-size: 1rem;
            line-height: 24px;
            color: #424647;
            position: absolute;
            top: 16px;
            left: 16px;
          }

          .field-validation-error{
            position: absolute;
            padding: 5px 15px;
            bottom: -34px;

            span{
              white-space: nowrap;
            }
          }
        }
      }

      .date{
        width: 200px;

        .calendar{
          right: 20px;
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          z-index: 3;
        }

        .umbraco-forms-label{
          display: none;
        }

        .umbraco-forms-field-wrapper{
          margin-bottom: 34px;
          position: relative;

          &.error{
            input{
              border: solid 1px #ef3340;
            }
          }

          &.success{
            input{
              border: solid 1px #007dba;
            }
          }

          .label-field{
            -webkit-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            max-width: 375px;
            margin: 0;
            font-size: 16px;
            font-size: 1rem;
            line-height: 24px;
            color: #424647;
            position: absolute;
            top: 16px;
            left: 16px;

            .default{
              display: block;
            }

            .focused{
              display: none;
            }
          }

          .field-validation-error{
            position: absolute;
            padding: 5px 15px;
            bottom: -34px;

            span{
              white-space: nowrap;
            }
          }
        }

        input{
          height: 56px;
          color: #424647;
          @include rem(16);
          border: none;
          border-bottom: 1px solid #757575;
          box-shadow: none;

          &:focus{
            outline: 0;
            border-bottom: 1px solid #26a69a;
            box-shadow: 0 1px 0 0 #26a69a;
          }
        }

        input.valid{
          border: solid 1px #007dba;
        }

        input.valid  ~ .label-field{
          @include rem(12);
          top: 0;
          color: #007dba;

          .default{
            display: none;
          }

          .focused{
            display: block;
          }
        }

        input.input-validation-error{
          border: solid 1px #ef3340;
        }

        input.input-validation-error  ~ .label-field{
          @include rem(12);
          top: 0;
          color: #ef3340;

          .default{
            display: none;
          }

          .focused{
            display: block;
          }
        }

        input:focus ~ .label-field{
          @include rem(12);
          top: 0;
          color: #007dba;

          .default{
            display: none;
          }

          .focused{
            display: block;
          }
        }
      }
    }

    .field-validation-error{
      span{
        white-space: nowrap;
      }
    }

    .umbraco-forms-navigation{
      button{
        margin: auto;
        display: table;
        width: 162px;
        min-height: 48px;
        padding: 12px 30px 12px 40px;
        text-align: left;
        border: solid 1px #424647;
        background-color: #fafafa;
      }
    }

    * + .umbraco-forms-navigation{
      margin-top: 30px;
    }
  }
}

.umbraco-forms-form{
  &.umbraco-forms-MWD{
    .umbraco-forms-page{
      .umbraco-forms-fieldset{
        .umbraco-forms-container{
          .shortanswer{
            width: 325px;
            margin: auto !important;

            .umbraco-forms-field-wrapper{
              position: relative;
              margin-bottom: 0;
            }

            input{
              height: 58px;
              @include rem(16);
              border: solid 1px #757575;
              border-radius: 3px;
              padding: 7px 15px;
              background-color: $white;
            }

            input:focus{
              box-shadow: none;
            }

            input:focus ~ label{
              @include rem(12);
              top: 0;
              color: #007dba;
            }

            input.input-validation-error{
              border: solid 1px #ef3340;
            }

            input.input-validation-error ~ label.label-field{
              @include rem(12);
              top: 0;
              color: #ef3340;
            }

            input.valid{
              border: solid 1px #007dba;
            }

            input.valid ~ label.label-field{
              @include rem(12);
              top: 0;
              color: #007dba;
            }

            ::placeholder{
              color: #bcbcbc;
            }

            .field-validation-error{
              position: absolute;
              color: #ef3340;
              padding: 5px 15px;
              bottom: -34px;

              span{
                white-space: nowrap;
              }
            }

            label.label-field{
              transition: all .2s;
              max-width: 375px;
              margin: 0;
              @include rem(16);
              line-height: 24px;
              color: #424647;
              position: absolute;
              top: 17px;
              left: 16px;
            }
          }

          .checkboxlist{
            float: none;
            display: flex;
            width: 100%;
            margin: 0;

            .checkbox-item{
              cursor: pointer;
              margin: 0;
              margin-top: 15px;
              user-select: none;
              padding: 0;
              max-width: 100%;

              input{
                display: inline;
                width: 20px;
                height: 20px;
                float: left;
                border-color: #007dba;
                display: none !important;

                &:focus{
                  border-color: #007dba;
                }
              }

              label{
                margin-left: 5px;
                display: inline;
              }
            }

            .checkbox-item:nth-child(odd){
              flex: 0 0 60%;
            }

            .checkbox-item:nth-child(even){
              flex: 0 0 40%;
            }
          }

          .umbraco-forms-field{
            position: relative;
            margin: 0;

            .g-recaptcha{
              > div{
                margin: auto;
              }
            }

            & + .umbraco-forms-field{
              margin-top: 40px;
            }

            & + .umbraco-forms-field.multiplechoice{
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  &.umbraco-forms-FormElements{
    .checkboxlist{
      float: none;
      display: flex;

      .checkbox-item{
        cursor: pointer;
        margin: 0;
        margin-top: 15px;
        user-select: none;

        input{
          display: inline;
          width: 20px;
          height: 20px;
          float: left;
          border-color: #007dba;
          display: none !important;

          &:focus{
            border-color: #007dba;
          }
        }

        label{
          margin-left: 5px;
          display: inline;
        }
      }
    }

    .shortanswer{
      max-width: 325px;
      margin: auto !important;

      .umbraco-forms-field-wrapper{
        position: relative;
        margin-bottom: 34px;
      }

      input{
        height: 56px;
        @include rem(16);
        border: solid 1px #757575;
        border-radius: 3px;
        padding: 7px 15px;
        background-color: $white;
      }

      input:focus{
        box-shadow: none;
      }

      input:focus ~ label.label-field{
        @include rem(12);
        top: 0;
        color: #007dba;
      }

      input.input-validation-error{
        border: solid 1px #ef3340;
      }

      input.input-validation-error ~ label.label-field{
        @include rem(12);
        top: 0;
        color: #ef3340;
      }

      input.valid{
        border: solid 1px #007dba;
      }

      input.valid ~ label.label-field{
        @include rem(12);
        top: 0;
        color: #007dba;
      }

      .field-validation-error{
        position: absolute;
        color: #ef3340;
        padding: 5px 15px;
        bottom: -34px;
      }

      label.label-field{
        transition: all .2s;
        max-width: 375px;
        margin: 0;
        @include rem(16);
        line-height: 24px;
        color: #424647;
        position: absolute;
        top: 16px;
        left: 16px;
      }
    }

    .radiobuttonlist{
      padding: 10px;

      .form-check{
        margin: 0;
        margin-top: 15px;
      }
    }

    .dropdown{
      .umbraco-forms-field-wrapper{
        margin-bottom: 34px;

        &.error{
          .label-field{
            font-size: 12px;
            font-size: .75rem;
            top: 0;
            color: #ef3340;
          }

          .dropdown{
            .dropdown-toggle{
              border: solid 1px #ef3340;
            }
          }
        }

        &.success{
          .label-field{
            font-size: 12px;
            font-size: .75rem;
            top: 0;
            color: #007dba;
          }

          span[class*="field-validation-"]{
            display: none;
          }

          .dropdown{
            .dropdown-toggle{
              border: solid 1px #007dba;
            }
          }
        }

        .dropdown{
          .dropdown-toggle{
            padding: 0 15px;
            height: 56px;
            line-height: 56px;
            background-color: #ffffff;
            border-radius: 3px;
            border: solid 1px #757575;
            box-shadow: none;

            .filter-option{
              .filter-option-inner{
                .filter-option-inner-inner{
                  color: #424647;
                }
              }
            }

            &:focus{
              box-shadow: none;
              outline: none !important;
              outline-offset: none;
            }

            &:after{
              margin-right: 10px;
              color: #424647;
            }
          }

          > .dropdown-menu{
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 3px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
            background-color: #fafafa;

            .dropdown-menu{
              padding: 10px 0;

              li{
                padding: 0;

                .dropdown-item{
                  color: #424647;

                  &.active{
                    background-color: #f2f2f2 !important;
                  }

                  &:active{
                    background-color: #f2f2f2 !important;
                  }
                }
              }
            }
          }
        }

        .dropdown.show ~ .label-field{
          @include rem(12);
          top: 0;
          color: #007dba;
        }

        .label-field{
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
          max-width: 375px;
          margin: 0;
          font-size: 16px;
          font-size: 1rem;
          line-height: 24px;
          color: #424647;
          position: absolute;
          top: 16px;
          left: 16px;
        }

        .field-validation-error{
          position: absolute;
          padding: 5px 15px;
          bottom: -34px;

          span{
            white-space: nowrap;
          }
        }
      }
    }

    .date{
      width: 200px;

      .calendar{
        right: 20px;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 3;
      }

      .umbraco-forms-label{
        display: none;
      }

      .umbraco-forms-field-wrapper{
        margin-bottom: 34px;
        position: relative;

        &.error{
          input{
            border: solid 1px #ef3340;
          }
        }

        &.success{
          input{
            border: solid 1px #007dba;
          }
        }

        .label-field{
          -webkit-transition: all .2s;
          -o-transition: all .2s;
          transition: all .2s;
          max-width: 375px;
          margin: 0;
          font-size: 16px;
          font-size: 1rem;
          line-height: 24px;
          color: #424647;
          position: absolute;
          top: 16px;
          left: 16px;

          .default{
            display: block;
          }

          .focused{
            display: none;
          }
        }

        .field-validation-error{
          position: absolute;
          padding: 5px 15px;
          bottom: -34px;

          span{
            white-space: nowrap;
          }
        }
      }

      input{
        height: 56px;
        color: #424647;
        @include rem(16);
        border: none;
        border-bottom: 1px solid #757575;
        box-shadow: none;

        &:focus{
          outline: 0;
          border-bottom: 1px solid #26a69a;
          box-shadow: 0 1px 0 0 #26a69a;
        }
      }

      input.valid{
        border: solid 1px #007dba;
      }

      input.valid  ~ .label-field{
        @include rem(12);
        top: 0;
        color: #007dba;

        .default{
          display: none;
        }

        .focused{
          display: block;
        }
      }

      input.input-validation-error{
        border: solid 1px #ef3340;
      }

      input.input-validation-error  ~ .label-field{
        @include rem(12);
        top: 0;
        color: #ef3340;

        .default{
          display: none;
        }

        .focused{
          display: block;
        }
      }

      input:focus ~ .label-field{
        @include rem(12);
        top: 0;
        color: #007dba;

        .default{
          display: none;
        }

        .focused{
          display: block;
        }
      }
    }
  }

  .field-validation-error{
    span{
      white-space: nowrap;
    }
  }

  .umbraco-forms-navigation{
    button{
      margin: auto;
      display: table;
      width: 162px;
      min-height: 48px;
      padding: 12px 30px 12px 40px;
      text-align: left;
      border: solid 1px #424647;
      background-color: #fafafa;
      color: $black;
    }
  }

  * + .umbraco-forms-navigation{
    margin-top: 30px;
  }
}

.mce-content-body{
  .umbraco-forms-form .umbraco-forms-page .umbraco-forms-navigation button:hover{
    border: solid 1px #424647;
    background-color: #e1e1e1;
  }
}

html.has-mouseover{
  .umbraco-forms-form .umbraco-forms-page .umbraco-forms-navigation button:hover{
    border: solid 1px #424647;
    background-color: #e1e1e1;
  }
}

@include media-breakpoint-up(md) {
  .mce-content-body{
    .umbraco-forms-form{
      &.umbraco-forms-MWD{
        .umbraco-forms-page{
          .umbraco-forms-fieldset{
            .umbraco-forms-container{
              .checkboxlist{
                .checkbox-item:nth-child(odd){
                  flex: 0 0 33.33%;
                }

                .checkbox-item:nth-child(even){
                  flex: 0 0 33.33%;
                }
              }

              .shortanswer{
                width: 445px;
              }
            }
          }
        }
      }

      .umbraco-forms-navigation{
        button{
          width: 162px;
          padding: 12px 30px 12px 40px;

          &:after{
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
          }
        }
      }
    }
  }

  .umbraco-forms-form{
    &.umbraco-forms-MWD{
      .umbraco-forms-page{
        .umbraco-forms-fieldset{
          .umbraco-forms-container{
            .checkboxlist{
              .checkbox-item:nth-child(odd){
                flex: 0 0 33.33%;
              }

              .checkbox-item:nth-child(even){
                flex: 0 0 33.33%;
              }
            }

            .shortanswer{
              width: 445px;
            }
          }
        }
      }
    }

    .umbraco-forms-navigation{
      button{
        width: 162px;
        padding: 12px 30px 12px 40px;

        &:after{
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .mce-content-body{
    .umbraco-forms-form{
      &.umbraco-forms-MWD{
        .umbraco-forms-page{
          .umbraco-forms-fieldset{
            .umbraco-forms-container{
              .checkboxlist{
                .checkbox-item:nth-child(odd){
                  flex: 0 0 25%;
                }

                .checkbox-item:nth-child(even){
                  flex: 0 0 25%;
                }
              }
            }
          }
        }
      }
    }
  }

  .umbraco-forms-form{
    &.umbraco-forms-MWD{
      .umbraco-forms-page{
        .umbraco-forms-fieldset{
          .umbraco-forms-container{
            .checkboxlist{
              .checkbox-item:nth-child(odd){
                flex: 0 0 25%;
              }

              .checkbox-item:nth-child(even){
                flex: 0 0 25%;
              }
            }
          }
        }
      }
    }
  }
}
