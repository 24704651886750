.images-styles-block {
  .section-padding {
    padding-bottom: 40px;

    .container-fluid {
      padding: 0 15px;

      .title {
        margin-bottom: 30px;
      }

      .image-content {
        .content-wrapper {
          width: 100%;
          display: table;
          margin: auto;
          margin-bottom: 0;

          img {
            width: 100%;
            height: auto;
            border-radius: 4px;
          }

          * + .caption {
            margin: 5px 0 0;
          }

          .caption {
            p {
              overflow-wrap: break-word;
              max-width: 100%;
              margin: 0;
            }
          }
        }
      }

      .image-content + .image-content {
        padding-top: 40px;
      }

      .content-col-title {
        .h3{
          @include rem(31);
          margin-bottom: 0;
          overflow: hidden;

          > * {
            margin: 0;
          }
        }
      }

      .row + .row {
        margin-top: 40px;
      }
    }
  }

  + .wysiwyg-block {
    padding-top: 0;
  }
}

  .imageBlock {
    + .imageBlock {
      .images-styles-block {
        .section-padding {
          padding-top: 0;
          padding-bottom: 70px;
        }
      }
    }
  }


@include media-breakpoint-up(md) {
  .images-styles-block {
    .section-padding {
      .container-fluid {
        padding: 0 38px;

        .image-l, .image-s {
          .content-wrapper {
            img {
              width: 100%;
              height: auto;
            }

            .caption {
              p {
                margin-bottom: 0;
              }
            }
          }
        }

        .image-content + .image-content {
          padding-top: 0;
        }

        .content-col-title {
          margin-bottom: 30px;
        }

        * + .image-s{
          margin-top: 29px;
        }

        .image-s {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .images-styles-block {
    .section-padding {
      .container-fluid {
        padding: 0 47px;

        .image-l, .image-s {
          .content-wrapper {
            .caption {
              p {
                max-width: 400px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .images-styles-block {
    .section-padding {
      .limiter {
        .container-fluid {
          padding: 0;
          max-width: 1110px;

          .image-s {
            padding-right: 17px;
            padding-left: 15px;
          }

          * + .image-s {
            margin-top: 30px;
          }

          .image-s + .image-s {
            padding-right: 15px;
            padding-left: 17px;
          }
        }
      }
    }
  }
}
