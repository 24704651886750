a.link-arrow,
button.link-arrow{
  border: none;
  background: transparent;
  padding: 0 30px 0 0;
  @include rem(18);
  font-weight: 500;
  position: relative;
  color: $gray-900;
  display: inline-block;

  &:after{
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
    font-family: $icofont;
    @include rem(20);
    content: "\ea5d";
  }
}

a.overlay-cta{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
}

button.newsroom-cta,
a.newsroom-cta{
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 62px;
  height: 62px;
  @include box-shadow-3(0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14));
  border: solid 1px $white;
  background-color: $blue;
  @include border-radius(50%);
  z-index: 2001;

  span{
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }

  &.active{
    opacity: 0;
    z-index: -99999999;
  }
}

a.link-default,
button.link-default{
  color: $link-default;
  @include rem(16);
  line-height: 24px;
  display: inline-block;
  border: none;
  background: transparent;
}

a.link-default:focus,
button.link-default:focus,
a:focus{
  color: #005486;
}

.nav-tabs{
  a.nav-link{
    color: $gray-900;
  }

  a.nav-link.active,
  a.nav-link:focus{
    color: $orange-tab-active;
    border-bottom: solid 2px $orange-tab-active;
    background: transparent;
  }
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border: solid 1px transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover{
  border: solid 1px transparent;
}

a.link-arrow-left,
button.link-arrow-left{
  border: none;
  background: transparent;
  padding: 0 0 0 30px;
  @include rem(18);
  font-weight: 500;
  position: relative;
  color: $gray-900;
  display: inline-block;


  &:after{
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    @include transform(translateY(-50%));
    font-family: $icofont;
    @include rem(20);
    content: "\ea5c";
  }
}

button.dot{
  background: $gray-500;
  width: 10px;
  height: 10px;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  text-align: left;
  text-indent: -999999px;
  @include border-radius(50% 50% 50% 50%);
  overflow: hidden;

  &.active{
    background: #ffa860;
  }
}

button.btn-arrow-below{
  padding: 0;
  display: block;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;

  &::after{
    position: relative;
    @include rem(20);
    height: 20px;
    width: 20px;
    font-family: $material-icons-font;
    content: '\e313';
  }

  &:focus{
    color: $orange;
  }

  &.active{
    &::after{
      content: '\e316';
    }
  }
}

a.cta-opt-1,
button.cta-opt-1{
  padding: 16px 35px 16px 40px;
  background-color: $gray-50;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  color: $gray-900;
  @include rem-2(16, 16);
  position: relative;
  border: 1px solid $gray-50;

  &:after{
    margin-left: 10px;
    font-family: $icofont;
    @include rem(20);
    content: "\ea5d";
  }
}

a.cta-opt-2-back,
button.cta-opt-2-back{
  padding: 16px 40px 16px 65px;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  @include rem-2(16, 16);
  position: relative;
  border: solid 1px $gray-900;
  background-color: $gray-50;
  color: $gray-900;

  &:before{
    position: absolute;
    left: 37px;
    font-family: $icofont;
    @include rem(20);
    content: "\ea5c";
  }
}

a.cta-opt-2,
button.cta-opt-2,
input.cta-opt-2{
  padding: 16px 35px 16px 40px;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  @include rem-2(16, 16);
  position: relative;
  border: solid 1px $gray-900;
  background-color: $gray-50;

  &:after{
    margin-left: 10px;
    font-family: $icofont;
    @include rem(20);
    content: "\ea5d";
  }
}

input.cta-opt-2{
  font-weight: 100;
}

.override-button{
  a.cta-opt-2,
  button.cta-opt-2{
    @include rem-2(16, 20);
    padding: 19px 60px 19px 30px;
    width: 320px;
    text-align: center;

    &:after{
      position: absolute;
      right: 30px;
      top: 50%;
      @include transform(translateY(-50%));
    }
  }

  a.cta-opt-1,
  button.cta-opt-1{
    @include rem-2(16, 20);
    padding: 19px 60px 19px 30px;
    width: 320px;
    text-align: center;

    &:after{
      position: absolute;
      right: 30px;
      top: 50%;
      @include transform(translateY(-50%));
    }
  }

  a.cta-opt-2-back,
  button.cta-opt-2-back{
    @include rem-2(16, 20);
    padding: 19px 30px 19px 60px;
    width: 320px;
    text-align: center;

    &:before{
      left: 30px;
      top: 50%;
      @include transform(translateY(-50%));
    }
  }
}

button.prev{
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 10px;
  @include border-radius(0 0 0 0);
  text-align: left;
  position: relative;

  &:after{
    content: "\e314";
    font-family: $material-icons;
    @include rem(25);
    display: block;
    text-indent: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }
}

button.prev,
button.next{
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  @include border-radius(0 0 0 0);
  text-align: left;
  text-indent: -99999px;
  position: relative;
  width: 40px;
  height: 40px;

  &:after{
    content: "\e314";
    font-family: $material-icons;
    @include rem(25);
    display: block;
    text-indent: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }

  &.next:after{
    content: "\e315";
  }
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus{
  @include box-shadow(none);
}

.theme-dark{
  a.cta-opt-2,
  a.cta-opt-2-back,
  button.cta-opt-2,
  button.cta-opt-2-back{
    border: solid 1px $white;
    background-color: transparent;
    color: $white;
  }

  a.link-arrow,
  button.link-arrow,
  button.link-arrow-left,
  a.link-arrow-left{
    color: $white;
  }

  a.link-arrow:focus,
  button.link-arrow:focus,
  a.link-arrow-left:focus,
  button.link-arrow-left:focus{
    color: $orange-bright;
  }

  a.link-default,
  button.link-default,
  a{
    color: $blue-light;
  }

  a.link-default:focus,
  button.link-default:focus,
  a:focus{
    color: $blue-light-hover;
  }

  .nav-tabs{
    a.nav-link{
      color: $white
    }

    a.nav-link.active,
    a.nav-link:focus{
      border: none;
      color: $orange-bright;
      border-bottom: solid 2px $orange-bright !important;
      background: transparent;
    }
  }
}

html.has-mouseover{
  a.newsroom-cta:hover,
  button.newsroom-cta:hover{
    background-color: #ba4d01;
  }

  a.link-arrow:hover,
  button.link-arrow:hover,
  a.link-arrow-left:hover,
  button.link-arrow-left:hover{
    color: $orange;
  }

  button.btn-arrow-below:hover{
    color: $orange;
  }

  a.link-default:hover,
  button.link-default:hover{
    color: #005486;
  }

  a:hover{
    color: $orange;
  }

  a.cta-opt-2:hover,
  a.cta-opt-2-back:hover,
  button.cta-opt-2:hover,
  button.cta-opt-2-back:hover{
    background-color: $gray-light;
  }

  .nav-tabs{
    a.nav-link:hover{
      color: $orange-tab-active;
      border-bottom: solid 2px $orange-tab-active;
    }
  }

  .theme-dark{
    a.link-arrow:hover,
    button.link-arrow:hover,
    a.link-arrow-left:hover,
    button.link-arrow-left:hover{
      color: $orange-bright;
    }

    a.cta-opt-2:hover,
    a.cta-opt-2-back:hover,
    button.cta-opt-2:hover,
    button.cta-opt-2-back:hover{
      background-color: $white;
      color: $gray-900;
    }

    a.link-default:hover,
    button.link-default:hover,
    a:hover{
      color: $blue-light-hover;
    }

    .nav-tabs{
      a.nav-link:hover{
        border: none;
        color: $orange-bright;
        border-bottom: solid 2px $orange-bright !important;
      }
    }
  }

  a.cta-opt-1:hover,
  button.cta-opt-1:hover{
    background-color: $gray-light;
    border: 1px solid $gray-light;
  }
}

.mce-content-body{
  a.newsroom-cta:hover,
  button.newsroom-cta:hover{
    background-color: #ba4d01;
  }

  a.link-arrow:hover,
  button.link-arrow:hover,
  a.link-arrow-left:hover,
  button.link-arrow-left:hover{
    color: $orange;
  }

  button.btn-arrow-below:hover{
    color: $orange;
  }

  a.link-default:hover,
  button.link-default:hover{
    color: #005486;
  }

  a:hover{
    color: $orange;
  }

  a.cta-opt-2:hover,
  a.cta-opt-2-back:hover,
  button.cta-opt-2:hover,
  button.cta-opt-2-back:hover{
    background-color: $gray-light;
  }

  .nav-tabs{
    a.nav-link:hover{
      color: $orange-tab-active;
      border-bottom: solid 2px $orange-tab-active;
    }
  }

  .theme-dark{
    a.link-arrow:hover,
    button.link-arrow:hover,
    a.link-arrow-left:hover,
    button.link-arrow-left:hover{
      color: $orange-bright;
    }

    a.cta-opt-2:hover,
    a.cta-opt-2-back:hover,
    button.cta-opt-2:hover,
    button.cta-opt-2-back:hover{
      background-color: $white;
      color: $gray-900;
    }

    a.link-default:hover,
    button.link-default:hover,
    a:hover{
      color: $blue-light-hover;
    }

    .nav-tabs{
      a.nav-link:hover{
        border: none;
        color: $orange-bright;
        border-bottom: solid 2px $orange-bright !important;
      }
    }
  }

  a.cta-opt-1:hover,
  button.cta-opt-1:hover{
    background-color: $gray-light;
    border: 1px solid $gray-light;
  }
}
