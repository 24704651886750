.hero-slider{
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 93.335%;

  .slide-items{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .slide-item{
      position: relative;
      width: 100%;
      height: 100%;

      .title{
        position: absolute;
        top: 75px;
        left: 25px;
        right: 40px;
        max-width: 310px;
        z-index: 2;
        color: $white;
      }

      picture{
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          @include transform(translate(-50%, -50%));
          z-index: 1;
        }
      }

      &.has-overlay{
        &:after{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .dots{
    position: absolute;
    bottom: 35px;
    left: 50%;
    @include transform(translateX(-50%));
    z-index: 2;

    .pager-dots{
      width: 10px;
      height: 10px;
      @include border-radius(50%);
      padding: 0;
      margin: 0;
      border: none;
      background-color: $gray-500;

      &.pager-active{
        background-color: #ffa860;
      }

      & + .pager-dots{
        margin-left: 10px;
      }

      .screen-reader-text{
        display: none;
      }
    }
  }

  .slide-arrows{
    position: absolute;
    top: 44%;
    @include transform(translateY(-50%));
    width: 100%;
    z-index: 2;

    .arrow-left, .arrow-right{
      .arrow-wrapper{
        position: relative;
        width: 100%;
        height: 100%;
      }

      position: absolute;
      border: none;
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      @include border-radius(50%);
      background-color: #ffffff;
    }

    .arrow-left{
      left: 40px;

      .icon-left{
        position: absolute;
        top: 50%;
        left: 50%;
        color: $gray-900;
        @include transform(translate(-50%, -50%));
      }
    }

    .arrow-right{
      right: 40px;

      .icon-right{
        position: absolute;
        top: 50%;
        left: 50%;
        color: $gray-900;
        @include transform(translate(-50%, -50%));
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-slider{
    padding-top: 45.573%;

    .slide-items{
      .slide-item{
        picture{
          img{
            width: 100%;
            height: auto;
          }
        }

        .title{
          left: 40px;
          top: 75px;
          max-width: 378px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-slider{
    padding-top: 37.989%;

    .slide-items{
      .slide-item{
        .title{
          top: 55%;
          left: 127px;
          @include transform(translateY(-50%));
          max-width: 450px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero-slider{
    padding-top: 31.25%;

    .slide-items{
      .slide-item{
        .title{
          max-width: 635px;
          left: 260px;
          top: 43%;
        }
      }
    }

    .slide-arrows{
      top: 38%;
    }
  }
}

body.is-ie .hero-slider .slide-arrows{
  .arrow-left .icon-left, .arrow-right .icon-right{
    top: 13px;
    left: 8px;
    width: 24px;
    height: 24px;
    @include transform(translate(0, 0));
  }
}
