.blog-post{
  margin: 0 -25px;

  .hero-article-block{
    position: relative;
    overflow: hidden;
    min-height: 259px;
    padding-top: inherit;

    .hero-article-slider{
      .hero-article-item{
        .image{
          img{
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            @include transform(translate(-50%, -50%));
          }
        }
      }
    }

    .pager-blog{
      .dot + .dot{
        margin-left: 10px;
      }
    }
  }

  .article-content{
    .container-icons{
      .social-icons{
        padding: 0 15px;

        ul{
          margin: 0;
          padding: 0;

          a{
            position: relative;
            display: inline-block;
            text-align: left;
            text-indent: -99999px;
            width: 26px;
            height: 26px;
            margin: 0;

            &:after{
              font-family: Ionicons,sans-serif;
              speak: none;
              font-style: normal;
              font-weight: 400;
              font-variant: normal;
              text-transform: none;
              text-rendering: auto;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: inherit;
              text-indent: 0;
              position: absolute;
              left: 50%;
              top: 50%;
              font-size: 26px;
              font-size: 1.625rem;
              -webkit-transform: translate(-50%,-50%);
              -o-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
            }

            &.fb{
              &:after{
                content: "\f231";
              }
            }

            &.in{
              &:after{
                content: "\f351";
              }
            }

            &.tw{
              text-indent: -9999px;
              text-align: left;
              width: 26px;
              height: 20px;
              -webkit-mask-size: 20px;
              mask-size: 20px;
              -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
              -webkit-mask-position: center center;
              mask-position: center center;
              -webkit-mask-image: url(/assets/icons/x-logo.svg);
              mask-image: url(/assets/icons/x-logo.svg);
              background: #535353;

              &:after{
                content: none;
              }

              &:hover{
                background: #ba4d01;
              }
            }

            &.li{
              &:after{
                content: "\f239";
              }
            }

            &.yt{
              &:after{
                content: "\f24d";
              }
            }

            &.pt{
              &:after{
                content: "\f2b1";
              }
            }

            & + a{
              margin-left: 15px;
            }
          }
        }
      }
    }

    .title-and-date{
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;

      .title{
        margin: 0;
      }

      .date{
        font-weight: bold;
        color: $gray-900;
      }

      * + .subheading{
        margin-top: 15px;
      }

      * + .date{
        margin-top: 5px;
      }

      * + .author{
        margin-top: 15px;
      }

      .subheading + *{
        margin-top: 15px;
      }
    }

    .title-date-block + *{
      margin-top: 15px;
    }

    .cta-back{
      .container-fluid{
        padding: 0 15px;
      }
    }

    .wysiwyg-block{
      .container-fluid, .container-detail{
        padding-left: 15px;
        padding-right: 15px;

        .wysiwyg-field{
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }

    * + .article-tags{
      padding-top: 50px;
    }

    * + .cta-back{
      margin-top: 70px;
    }
  }

  .section-block + .section-block{
    .section-padding{
      padding: 50px 0 0;
    }
  }
}

@include media-breakpoint-up(md){
  .blog-post{
    margin: 0 -40px;

    .hero-article-block{
      .hero-article-arrow-buttons{
        left: 50%;
        @include transform(translate(-50%, -50%));
      }
    }

    .article-content{
      .cta-back{
        .container-fluid{
          padding: 0 40px;
        }
      }

      .wysiwyg-block{
        .container-fluid, .container-detail{
          .wysiwyg-field{
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .blog-post{
    margin: 0 -50px;

    .hero-article-block{
      min-height: 320px;
    }

    .article-content{
      .title-and-date{
        padding-top: 0;
      }

      .container-icons{
        position: relative;

        .social-icons{
          position: absolute;
          left: -102px;
          top: 0;

          ul{
            display: inline-flex;
            flex-direction: column;
            padding: 20px;
            border-radius: 4px;
            margin: 0;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
            background-color: #ececec;
            border: solid 1px #ffffff;

            a{
              & + a{
                margin-left: 0;
                margin-top: 15px;
              }
            }
          }
        }
      }

      .cta-back{
        .container-fluid{
          padding: 0 75px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .blog-post{
    .article-content{
      .cta-back{
        .container-fluid{
          padding: 0 15px;
        }
      }
    }
  }
}
