.live-stream-block {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;

    .wysiwyg-field {
      p {
        margin: 0;

        & + * {
          margin-top: 30px;
        }
      }

      .title {
        + * {
          margin-top: 3px;
        }
      }

      .meeting-notification {
        font-weight: 500;
        @include rem-2(20, 24);

        & + * {
          margin-top: 10px;
        }
      }

      .meeting-date + * {
        margin-top: 5px;
      }

      .meeting-date,
      .meeting-time {
        font-weight: 500;

        span {
          font-weight: normal;
        }
      }
    }
  }

  .iframe {
    text-align: center;
    color: $white;
    background-color: $blue;
    padding: 150px 18px 152px;
    @include rem-2(20, 24);
    font-weight: 500;

    .content-title {
      overflow-wrap: break-word;
    }
  }
}

@include media-breakpoint-up (md) {
  .live-stream-block {
    .container-fluid {
      padding-left: 40px;
      padding-right: 40px;

      .wysiwyg-field {
        .title {
          + * {
            margin-top: 15px;
          }
        }
      }
    }

    .iframe {
      .content-title {
        max-width: 341px;
        margin: 0 auto;
      }
    }
  }
}

@include media-breakpoint-up (lg) {
  .live-stream-block {
    .limiter-700 {
      max-width: 780px;
    }

    .iframe {
      padding: 190px 18px 220px;
    }
  }
}

@include media-breakpoint-up (xl) {
  .live-stream-block {

    .iframe {
      padding: 279px 18px 297px;

      .content-title {
        max-width: 364px;
      }
    }
  }
}
