.text-orange{
  color: $orange;
}

.color-text-light-orange{
  color: $orange-bright;
}

.theme-dark{
  a {
    color: $white;
  }

  .text-orange{
    color: $orange-bright;
  }
}

.bg-neutral-dark-blue{
  background-color: $blue;
  color: $white;
}

.bg-neutral-light{
  background-color: $white;
  color: $gray-900;
}

.bg-neutral-medium{
  background-color: $gray-100;
  color: $gray-900;
}

.bg-neutral-dark{
  background-color: $gray-200;
  color: $gray-900;
}

.bg-dark-blue{
  background-color: $blue;
  color: $white;
}
