.four-column-content {
  .section-padding {
    padding: 0;
  }

  .col-title {
    > .title {
      margin: 0;
      line-height: 20px;
    }

    .title + .sub-title {
      margin-top: 20px;
    }
  }

  .col-content {
    padding: 0;
  }

  .col-content-item {
    position: relative;
    background-color: $blue;
    height: 200px;
    overflow: hidden;

    > .row{
      height: 100%;
    }

    .image {
      position: relative;
      z-index: 1;
      width: 100%;

      &[data-type="image/svg+xml"] {
        img {
          width: 100%;
        }
      }

      picture,
      img,
      video {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: "";
        background: #1c2240;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        opacity: 0.9;
      }
    }

    .info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;

      .inner-info {
        position: absolute;
        top: 46px;
        display: block;
        width: 100%;
        padding: 0 35px;

        > h4 {
          margin: 0;
          color: $white;

          + .body{
            margin-top: 10px;
          }
        }
      }
    }

    .cta {
      a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        cursor: pointer;
        text-indent: -99999px;
        padding: 16px 35px 16px 40px;
        background-color: transparent;

        div.title {
          position: relative;
          display: inline-block;
          padding-right: 30px;
          font-family: $font-family-sans-serif;
          color: $gray-900;
          @include rem(16);
          font-weight: 500;

          span.arrow{
            &:after{
              position: absolute;
              display: block;
              top: 50%;
              right: 0;
              @include transform(translateY(-50%));
              font-family: $icofont;
              @include rem(20);
              content: "\ea5d";
              color: $gray-900;
              line-height: 16px;
            }
          }
        }
      }
    }

    + .col-content-item {
      margin-top: 1px;
    }
  }
}

@include media-breakpoint-up(md) {
  .four-column-content {
    .col-content-item {
      height: 170px;

      .info {
        .inner-info {
          top: 35px;
          padding: 0 30px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .four-column-content {
    .col-content-item {
      height: 320px;

      .image {
        &[data-type="image/svg+xml"] {
          img {
            height: 320px;
          }
        }
      }

      .info .inner-info {
        top: 60px;
      }

      .cta {
        opacity: 0;
        touch-action: none;

        a {
          opacity: 0;
          touch-action: none;
          top: auto;
          bottom: 25px;
          left: 24px;
          right: 24px;
          width: calc(100% - 48px);
          text-indent: 0;
          text-align: center;
          background-color: $gray-50;
          font-family: $font-family-sans-serif;
          font-weight: 500;
          color: $gray-900;
          @include rem-2(16, 16);
        }
      }

      + .col-content-item {
        margin-top: 0;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background: white;
          display: block;
          content: "";
          z-index: 2;
        }
      }
    }
  }

  html.has-mouseover .four-column-content,
  .four-column-content {
    .col-content-item {
      .cta {
        a:hover {
          background-color: $gray-light;
        }
      }
    }

    .col-content-item:hover {
      .image {
        &:after {
          content: '';
          opacity: .7;
        }
      }

      .cta {
        opacity: 1;
        touch-action: auto;

        a {
          opacity: 1;
          touch-action: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .four-column-content {
    .col-content-item-title {
      flex: 1;
      max-width: 100%;

      .row {
        height: 100%;
        min-height: 450px;
      }
    }

    .col-content-item{
      height: 450px;

      .image {
        &[data-type="image/svg+xml"] {
          img {
            height: 450px;
          }
        }
      }

      .info .inner-info{
        top: 110px;
        padding: 0 50px;
      }

      .cta{
        a{
          top: auto;
          bottom: 50px;
          left: 58px;
          right: 58px;
          width: calc(100% - 116px);
          text-indent: 0;
          text-align: center;
          background-color: #fafafa;
          color: #424647;
        }
      }
    }
  }
}
