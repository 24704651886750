#featured-stories-block {
  position: relative;
  padding: 70px 15px 116px;

  .limiter {
    width: 100%;
    position: relative;

    .header-title {
      margin-bottom: 30px;

      .title {
        font-family: $headings-font-family;
        font-size: 25px;
        line-height: 1.08;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #5a5b5d;
        letter-spacing: normal;

        .color {
          color: $color-text-dark-orange;
          display: inline;
        }
      }
    }

    .cart-content {
      position: relative;
      background-color: $white;
      color: $gray-900;
      display: flex;
      flex-direction: column;
      border-top: solid $color-text-dark-orange 3px;
      min-height: 352px;

      .cart-content-img {
        position: relative;
        min-height: 138px;

        img {
          position: relative;
          min-width: 100%;
          min-height: 100%;
        }
      }

      .cart-content-text {
        padding: 27px 40px 52px;
        position: relative;

        .text-body {
          font-family: $headings-font-family;
          font-size: 25px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.08;
          letter-spacing: normal;
          color: #5a5b5d;
        }

        .content-arrow {
          text-align: right;
        }
      }
    }

    .after {
      position: absolute;
      right: 0;
      line-height: 0.89;
      bottom: -50px;
    }
  }
}

@include media-breakpoint-up(md) {
  #featured-stories-block {
    padding: 73px 39px 116px 249PX;

    .limiter {
      .header-title {
        .title {
          font-size: 27px;
          line-height: 1.07;
        }
      }

      .cart-content {
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;
        min-height: 352px;

        .cart-content-img {
          position: relative;

          img {
            min-width: 200px;
            min-height: 100%;
          }
        }

        .cart-content-text {
          padding: 40px 40px 80px;
          position: relative;

          .text-body {
            font-family: $headings-font-family;
            font-size: 27px;
            line-height: 1.07;
          }

          .link-arrow {
            position: absolute;
            right: 49px;
            bottom: 40px;
          }
        }
      }
      .link-arrow.after {
        position: absolute;
        right: 0;
        color: $gray-900;
        bottom: -50px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #featured-stories-block {
    padding: 70px 47px 116px 249PX;

    .limiter {
      .header-title {
        .title {
          font-size: 31px;
          line-height: 1.06;
        }
      }

      .cart-content {
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;
        min-height: 272px;

        .cart-content-img {
          position: relative;

          img {
            min-width: 270px;
            min-height: 100%;
          }
        }

        .cart-content-text {
          padding: 40px 40px 100px;
          position: relative;

          .text-body {
            font-family: $headings-font-family;
            @include rem-2(31, 33);
          }

          .link-arrow {
            position: absolute;
            right: initial;
            left: 40px;
            bottom: 40px;
          }
        }
      }

      .link-arrow.after {
        position: absolute;
        right: 0;
        color: $gray-900;
        bottom: -50px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #featured-stories-block {
    padding: 70px 165px 116px 355PX;

    .limiter {
      max-width: 920px;

      .header-title {
        .title {
          font-size: 31px;
          line-height: 1.06;
        }
      }

      .cart-content {
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;
        min-height: 272px;

        .cart-content-img {
          position: relative;

          img {
            min-width: 365px;
            min-height: 100%;
            max-height: 272px;
          }
        }

        .cart-content-text {
          padding: 40px 40px 100px;
          position: relative;

          .text-body {
            font-family: $headings-font-family;
            font-size: 31px;
            line-height: 1.06;
          }

          .link-arrow {
            position: absolute;
            right: initial;
            left: 40px;
            bottom: 40px;
          }
        }
      }

      .link-arrow.after {
        position: absolute;
        right: 0;
        color: $gray-900;
        bottom: -50px;
      }
    }
  }
}
