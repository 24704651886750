
//bootstrap
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "custom";

[type=checkbox]:checked,
[type=checkbox]:not(:checked) {
  position: relative;
  opacity: 1;
}
