#lightbox.lightbox {
  .lb-outerContainer {
    .lb-container {
      .lb-nav {
        @include transform(translateY(50%));

        .lb-next {
          position: absolute;
          background-color: $gray-200;
          border: 0;
          width: 40px;
          height: 40px;
          right: 25px;
          border-radius: 50%;
          padding: 7px 7px;
          @include transform(translateY(50%));
          cursor: pointer;
          color: $gray-900;

          &:hover{
            background-color: $gray-light;
          }

          &:after {
            width: 25px;
            height: 25px;
            content: "\e315";
            font-family: $material-icons-font;
            @include rem(30);
            display: block;
            text-indent: 0;
            position: absolute;
            top: 20px;
            left: 3px;
          }
        }

        .lb-prev {
          position: absolute;
          background-color: $gray-200;
          border: 0;
          width: 40px;
          height: 40px;
          left: 25px;
          border-radius: 50%;
          padding: 7px 7px;
          @include transform(translateY(50%));
          cursor: pointer;
          color: $gray-900;

          &:hover{
            background-color: $gray-light;
          }

          &:after {
            width: 25px;
            height: 25px;
            content: "\e314";
            font-family: $material-icons-font;
            @include rem(30);
            display: block;
            text-indent: 0;
            position: absolute;
            top: 20px;
            left: 3px;
          }
        }
      }

      .lb-loader {
          display: block;
          top: 0;

        .lb-cancel {
          position: absolute;
          width: 43px;
          height: 43px;
          background-color: $gray-200;
          border-radius: 5px;
          top: 0;
          right: 0;
          padding: 5px 5px 3px 3px;
          cursor: pointer;

          &:hover{
            color: $color-text-dark-orange;
          }

          &:after {
            width: 35px;
            height: 35px;
            @include rem(35);
            content: "\e5cd";
            font-family: $material-icons-font;
            position: absolute;
            right: 3px;
            top: 20px;
          }
        }
      }
    }
  }
}
