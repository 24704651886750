.divider-block {
  .container-fluid {
    margin: 0;
    padding: 0;

    .content-wrapper {
      .bg-neutral-medium,
      .bg-neutral-dark-blue {
        padding: 70px 25px;
        margin: 0;

        .h5 {
          font-family: $headings-font-family;
          margin-bottom: 40px;
        }

        hr {
          margin: 0;
          border-top: 1px solid $gray-450;
        }
      }

      .bg-neutral-dark-blue {
        hr {
          border-top: 1px solid $white;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .divider-block {
    .container-fluid {
      .content-wrapper {
        .bg-neutral-medium,
        .bg-neutral-dark-blue {
          padding: 70px 39px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .divider-block {
    .container-fluid {
      .content-wrapper {
        .bg-neutral-medium,
        .bg-neutral-dark-blue {
          padding: 70px 47px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .divider-block {
    .container-fluid {
      .content-wrapper {
        .bg-neutral-medium,
        .bg-neutral-dark-blue {
          padding: 70px 165px;
        }
      }
    }
  }
}
