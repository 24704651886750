.paginationjs {
  .paginationjs-pages {
    .nav-tabs {
      font-family: $font-family-sans-serif;
      @include rem(16);
      font-weight: 500;
      border-bottom: none;

      .nav-item-prev, .nav-item-next {
        cursor: pointer;
        text-indent: -99999999px;
        text-align: left;
        position: relative;
        width: 25px;
        height: 25px;
        display: block;
      }

      .nav-item-prev::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
        font-family: $material-icons;
        font-weight: normal;
        font-style: normal;
        @include rem(24);
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        content: 'keyboard_arrow_left';
      }

      .nav-item-next::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
        font-family: $material-icons;
        font-weight: normal;
        font-style: normal;
        @include rem(24);
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        content: 'keyboard_arrow_right';
      }

      .nav-item-page {
        margin-bottom: -1px;

        a {
          color: $gray-900;
          padding: 0 10px;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }

        &.current {
          a {
            color: $selected-pagination;
            font-weight: 500;
            border: none;
            padding-bottom: 5px;
            border-bottom: solid 2px $selected-pagination;
            background-color: transparent;
          }
        }
      }
    }
  }
}

* + .paginationjs {
  margin-top: 62px;
}

