.bio-body{
  background-color: #fff;
  padding: 70px 0;

  .bio-container{
    max-width: 920px;

    .bio-info{
      border-bottom: 1px solid $gray-450;
      margin-bottom: 40px;

      .bio-img {
        width: 100%;
        max-width: 210px;
        max-height: 272px;
        margin: 0 auto;
        overflow: hidden;
        @include border-radius(3px);

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .container-modal-card {
        padding: 30px 0 0;

        .container-modal-card-header {
          padding-bottom: 28px;

          .title-bio-info {
            font-family: $headings-font-family;
            color: $gray-900;
            @include rem-2(25, 27);
          }

          .sub-title-bio-info {
            font-family: $font-family-sans-serif;
            @include rem-2(20, 24);
            color: $gray-900;
            font-weight: 500;
            letter-spacing: 0.15px;
          }

          * + .sub-title-bio-info {
            margin-top: 10px;
          }

          .text-bio-info {
            font-family: $font-family-sans-serif;
            color: $gray-900;
          }

          * + .text-bio-info {
            margin-top: 10px;
          }

          & + * {
            border-top: 1px solid $gray-450;
          }
        }

        .container-modal-card-body {
          padding: 28px 0 30px;

          .title-bio-info {
            font-family: $font-family-sans-serif;
            font-weight: 500;
            color: $gray-900;

            p {
              margin: 0;
            }
          }

          * + .title-bio-info {
            margin-top: 15px;
          }

          .sub-title-bio-info {
            font-family: $font-family-sans-serif;
            color: $light-blue;
          }

          * + .sub-title-bio-info {
            margin-top: 5px;
          }

          .text-bio-info {
            font-family: $font-family-sans-serif;
            color: $gray-900;
          }

          * + .text-bio-info {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .bio-body{
    .bio-container{
      .bio-info{
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;

        .container-modal-card {
          padding: 0 0 40px 30px;
          width: 100%;
          flex: 1;

          .container-modal-card-body {
            padding: 28px 0 0px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .bio-body{
    .bio-container{
      .bio-info{
        padding-bottom: 30px;

        .bio-img {
          max-width: 255px;
          max-height: 330px;
        }
      }
    }
  }
}
