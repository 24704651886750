.short-hero-block {
  padding-top: 150px;
  padding-bottom: 66px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .h1 {
    margin: 0;
  }

  &.hero-with-cta {
    padding-bottom: 30px;
    min-height: 160px;

    .h1 {
      p {
        margin-bottom: 0;
      }
    }

    .cta-wrapper{
      display: table;
      margin: auto;
    }

    .link-arrow {
      line-height: 17px;
    }

    * + .link-arrow {
      margin-top: 20px;
    }
  }

  .video-image {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    z-index: -2;
    height: auto;
    width: 100%;

    .img-fluid {
      height: auto;
      width: 100%;
    }
  }
}

.overlayEnabled {
  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(28, 34, 64,0.9);
  }
}

@include media-breakpoint-up(md) {
  .short-hero-block {
    padding-bottom: 57px;

    &.hero-with-cta {
      * + .link-arrow {
        margin-top: 11px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .short-hero-block {
    padding-top: 131px;
    padding-bottom: 69px;

    &.hero-with-cta {
      padding-top: 70px;
      min-height: 200px;

      * + .link-arrow {
        margin-top: 23px;
      }
    }
  }
}
