@use "sass:math";

.carousel-block{
  .col-info{
    text-align: left;

    .image{
      + .text-field{
        margin-top: 15px;
      }

      img {
        height: auto;
      }
    }
  }

  .col-title{
    > .title{
      margin: 0;
    }

    > * + .sub-title{
      margin-top: 20px;
    }

    > .sub-title{
      max-width: 260px;
    }

    + .col-info{
      &:before{
        content: "";
        background: #c8c8c8;
        height: 1px;
        display: block;
        position: absolute;
        top: 0;
        left: math.div($grid-gutter-width, 2);
        right: math.div($grid-gutter-width, 2);
      }
    }
  }

  .slides-block{
    position: relative;
    padding-bottom: 40px;

    .slide-items-wrapper{
      position: relative;
      height: 300px;
      overflow: hidden;

      .btn-container-left, .btn-container-right {
        display: none;
      }
    }

    .slide-items{
      position: absolute;
      top: 0;
      left: 50%;
      @include transform(translateX(-50%));
      height: 300px;
      white-space: nowrap;
      font-size: 0;
    }

    .slide-item{
      position: relative;
      white-space: normal;
      display: inline-block;
      vertical-align: top;
      width: 315px;
      height: 300px;
      padding: 0 5px;
      @include rem(1);

      .slide-item-inner{
        position: relative;
        overflow: hidden;
        background: $blue;

        &:after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: $color-text-dark-orange;
          opacity: 0;
          z-index: 1;
        }
      }

      &.active{
        .slide-item-inner:after{
          opacity: 1;
          z-index: 2;
        }
        .slide-item-inner {
          padding-bottom: 4px;
        }
      }

      .logo {
        position: absolute;
        right: 10px;
        bottom: 14px;
        max-width: 28px;
        max-height: 41px;
        z-index: 10;

        img {
          width: 100%;
        }
      }

      .image{
        position: relative;
        z-index: 1;
        height: 296px;

        picture{
          img{
            width: 100%;
            height: auto;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
          }
        }

        &:after{
          opacity: 0.85;
          content: "";
          @include bg-gradient(#000000, rgba(0, 0, 0, 0.6));
          position: absolute;
          top: 0;
          right: -10px;
          bottom: -296px;
          left: 0;
          display: block;
          transition: all 0.1s;
        }
      }

      .info{
        position: absolute;
        z-index: 2;
        top: 53px;
        left: 43px;
        right: 43px;
        bottom: 53px;

        &:before{
          content: "";
          position: absolute;
          left: -23px;
          top: -13px;
          width: 2px;
          background: #ffffff;
          bottom: 14px;
        }
      }
    }
  }

  .slide-actions{
    margin-top: 20px;

    .slide-dot{
      border: none;
      background: #a7a7a7;
      @include border-radius(50% 50% 50% 50%);
      text-align: left;
      text-indent: -99999px;
      width: 10px;
      height: 10px;
      padding: 0;
      display: block;
      margin: 0 5px;
      position: relative;

      &.active{
        background: $color-text-dark-orange;
      }

      &:focus{
        outline: none;

        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: solid 1px $color-text-dark-orange;
          z-index: 1;
          @include border-radius(50% 50% 50% 50%);
        }
      }
    }
  }
}

.sliderBlock, .carousel-block {
    .section-padding {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

@include media-breakpoint-up(md) {
  .carousel-block{
    .col-title{
      + .col-info{
        &:before{
          content: "";
          display: block;
          background: $gray-450;
          width: 1px;
          position: absolute;
          top: 23px;
          left: 0;
          bottom: 30px;
          right: auto;
          height: auto;
        }
      }
    }

    .slides-block{
      $slide-height: 256px;
      padding-bottom: 70px;

      .slide-items-wrapper {
        height: $slide-height;

        .btn-container-left {
          display: block;
          position: absolute;
          top: 50%;
          @include transform (translateY(-50%));
          left: 0;
          z-index: 999;

          button {
            color: white;
            border: none;
            background: transparent;
            outline: none;

            .material-icons {
              @include rem(70);
            }
          }
        }

        .btn-container-right {
          display: block;
          position: absolute;
          top: 50%;
          @include transform (translateY(-50%));
          right: 0;
          z-index: 999;

          button {
            color: white;
            border: none;
            background: transparent;
            outline: none;

            .material-icons {
              @include rem(70);
            }
          }
        }

        .slide-items{
          height: $slide-height;
        }
      }

      .slide-item{
        width: 510px;
        height: $slide-height;
        padding: 0 10px;

        .image {
          height: 252px;

          picture {
            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .info {
          &:before {
            top: -25px;
            bottom: -22px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .carousel-block .slides-block{
    $slide-height: 300px;

    .slide-items-wrapper {
      height: $slide-height;

      .slide-items{
        height: $slide-height;
      }
    }

    .slide-item{
      width: 596px;
      height: $slide-height;

      .image {
        height: 296px;
      }

      .info {
        &::before {
          top: -13px;
          bottom: 14px;
        }
      }
    }
  }

  .carousel-block {
    .col-info {
      max-width: 594px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .slides-block {
    .slide-items-wrapper {
      margin: 0 auto;
      max-width: 1768px;
    }
  }
}

html.has-mouseover .carousel-block .slides-block .slide-item:hover {
  .slide-item-inner{
    .image {
      &:after {
        opacity: 0.5;
      }
    }
  }
}
