@use "sass:math";

#main-container{
  overflow: hidden;

  &.animated{
    padding-top: 0;
  }

  .hero-spacer{
    height: 575px;
    min-height: 575px;
  }
}

.section-rows{
  position: relative;
}

.section-row{
  background: white;
  position: relative;
  z-index: 100;
  overflow: hidden;

  &.section-fixed{
    position: fixed;
    z-index: 1;
    top: 0;
    left: math.div($grid-gutter-width, 2);
    right: math.div($grid-gutter-width, 2);
  }

  &.first-view-port{
    position: static;
    z-index: 1;
    top: 0;
    left: math.div($grid-gutter-width, 2);
    right: math.div($grid-gutter-width, 2);

    &.active{
      position: fixed;
    }
  }

  &.transparent{
    @include transform(translateX(-100%));
  }

  &.padding-top-0{
    .section-padding{
      padding-top: 0;
    }
  }

  &.padding-top-35{
    .section-padding{
      padding-top: 35px;
    }
  }

  &.padding-top-70{
    .section-padding{
      padding-top: 70px;
    }
  }

  &.padding-bottom-0{
    .section-padding{
      padding-bottom: 0;
    }
  }

  &.padding-bottom-35{
    .section-padding{
      padding-bottom: 35px;
    }
  }

  &.padding-bottom-70{
    .section-padding{
      padding-bottom: 70px;
    }
  }
}

.section-block{
  width: 100%;
}

.row-content{
  flex-direction: column;

  &.sidebar-content{
    display: flex;
    margin: 0 -25px;

    .sidebar{
      position: absolute;
      max-width: 1170px;
      margin: auto;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .sidebar-navigation{
        position: relative;
        z-index: 101;
        background: transparent;
        margin: 0 0 auto;
        width: 219px;
        height: 100%;

        .limiter{
          width: 100%;
        }

        .sidebar-menu{
          position: fixed;

          &.stick{
            position: fixed !important;
            top: 60px;
          }

          .sidebar-wrapper{
            margin-top: 70px;
          }
        }
      }
    }

    .sidebar-content{
      width: 100%;
      padding: 0 25px;
    }
  }
}

@include media-breakpoint-up(md) {
  .section-row.first-view-port,
  .section-row.section-fixed{
    left: 40px;
    right: 40px;
  }

  .row-content{
    &.sidebar-content{
      margin: 0 -40px;

      .sidebar-content{
        padding: 0 40px;

        .section-row{
          > div{
            max-width: 1170px;
            margin: 0 auto;
            padding-left: 40px;
            padding-right: 40px;
          }

          .section-padding{
            padding-left: 209px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #main-container{
    .hero-spacer{
      height: calc(95vh - 60px);
    }
  }

  .section-row.first-view-port,
  .section-row.section-fixed{
    left: 50px;
    right: 50px;
  }

  .row-content{
    &.sidebar-content{
      margin: 0 -50px;

      .sidebar-content{
        padding: 0 50px;

        .section-row{
          > div{
            padding-left: 47px;
            padding-right: 47px;
          }

          .section-padding{
            padding-left: 202px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #main-container{
    overflow: hidden;
  }

  .row-content{
    &.sidebar-content{
      .sidebar-content{
        .section-row{
          > div{
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    }
  }
}

@media (min-width: 1441px) {
  .row-content{
    &.sidebar-content{
      .sidebar-content{
        .section-row{
          > div{
            margin: 0 auto;
          }
        }
      }
    }
  }

  .section-row{
    &.first-view-port{
      height: auto;
    }
  }
}
