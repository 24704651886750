.post-teaser-block {
  word-break: break-word;
  overflow: wrap;

  .section-padding {
    padding-top: 50px;

    .limiter-920 {
      .container-fluid {
        padding-right: 15px;
        padding-left: 15px;

        .card-view {
          .reset-view {
            a {
              color: $gray-900;
            }
          }

          .form-group {
            margin-bottom: 0;
            padding-right: 0;
            padding-left: 10px;
            max-width: 170px;

            .input-field {
              input {
                padding-top: 0;
                margin-bottom: 0;
              }
            }
          }

          .card-container {
            border-top: 3px solid $color-text-dark-orange;

            .card {
              @include border-radius(0);
              border: none;

              .card-image {
                img {
                  width: 100%;
                }
              }

              .card-content {
                padding: 40px 30px 35px;

                .card-title {
                  font-weight: 500;
                }

                .card-text {
                  font-family: $font-family-sans-serif;
                  font-weight: 500;
                }

                .card-date {
                  .date {
                    font-weight: 500;
                    margin-bottom: 0;
                  }
                }

                .card-title + .card-date {
                  margin-top: 20px;
                }

                .card-date + .card-text {
                  margin-top: 10px;
                }

                .card-text + .card-cta {
                  margin-top: 24px;
                }
              }
            }
          }

          .select-view + .card-container {
            margin-top: 30px;
          }

          .card-container + .card-container {
            margin-top: 20px;
          }
        }
      }
    }

    .pagination-styles {
      margin-top: 62px;
    }
  }
}

@include media-breakpoint-up(md) {
  .post-teaser-block {
    .section-padding {
      .limiter-920 {
        max-width: 768px;

        .container-fluid {
          padding-right: 39px;
          padding-left: 39px;

          .card-view {
            .card-container {
              .card {
                .card-image {
                  width: 300px;
                }

                .card-image + .card-content {
                  max-width: 390px;

                  .card-body {
                    .card-text {
                      overflow: hidden;
                      height: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .post-teaser-block {
    .section-padding {
      .limiter-920 {
        max-width: 920px;

        .container-fluid {
          padding-right: 0;
          padding-left: 0;

          .card-view {
            .card-container {
              .card {
                .card-image {
                  width: 350px;
                }

                .card-image + .card-content {
                  max-width: 570px;

                  .card-body {
                    .card-text {
                      overflow: hidden;
                      height: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
