.article-title {
  padding-top: 70px;
}

.article-tags {
  .tag-title {
    margin: 0 0 10px 15px;
    @include rem-2(16, 24);
    overflow-wrap: break-word;
    font-weight: bold;
    color: #424647;
  }

  .tags {
    a {
      margin-left: 15px;
      @include rem-2(16, 24);
      color: $light-blue;
    }
  }

  &.type-1 {
    width: 100%;

    .container-fluid {
      padding: 0 15px;

      .tag-title {
        margin: 0 0 10px 0;
      }

      .tags {
        * + a {
          margin-left: 5px;
        }

        a {
          color: $light-blue;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .article-tags {
    &.type-1 {
      .container-fluid {
        padding: 0 40px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .article-tags {
    &.type-1 {
      .container-fluid {
        padding: 0 75px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .article-tags {
    &.type-1 {
      .container-fluid {
        padding: 0 15px;
      }
    }
  }
}

