// Video Block Styles.
* + .video-block.none {
  margin-top: 30px;
}

.video-block {
  padding-right: 15px;
  padding-left: 15px;

  * + .video-image {
    margin-top: 20px;
  }

  .video-image {
    .video-img-container {
      width: 100%;
      max-width: 345px;
      position: relative;
      @include box-shadow-3(0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),0 4px 5px 0 rgba(0, 0, 0, 0.14));

      .img-video {
        width: 100%;
      }

      .video-btn-container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .btn-play {
          width: 65px;
          height: 65px;
        }
      }
    }
  }

  &.none {
    justify-content: flex-start;

    .video-image {
      .video-img-container {
        max-width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .video-block {
    padding-right: 39px;
    padding-left: 39px;

    .video-image {
      .video-img-container {
        max-width: 690px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .video-block {
    padding-right: 127px;
    padding-left: 127px;

    .video-image {
      .video-img-container {
        max-width: 770px;
      }
    }
  }
}


@include media-breakpoint-up(xl) {
  .video-block {
    .video-image {
      .video-img-container {
        max-width: 920px;
      }
    }
  }
}
