.list-link-block {
  .pagination-styles {
    .section-padding {
      padding: 90px 0 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0;
  }

  .container-fluid {
    padding: 0 15px;

    .row {
      margin: 0 -15px;

      .title-lists {
        padding: 0 15px;

        .h5 {
          margin: 0;
        }
      }

      .body {
        font-family: $font-family-sans-serif;
        @include rem-2(16, 24);
        color: $gray-900;
        max-height: 216px;
        overflow: hidden;
        overflow-wrap: break-word;
      }

      .title-lists + .body {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 0 15px;
      }

      .content {
        padding: 0 15px;

        .main-container {
          .link-items {
            position: relative;

            .l-item {
              position: relative;

              a.link-list {
                padding: 22px 75px 22px 25px;
                display: block;
                color: $gray-900;
                position: relative;
                min-height: 70px;

                &::after {
                  position: absolute;
                  right: 25px;
                  top: 22px;
                  @include rem(25);
                  height: 25px;
                  width: 25px;
                }

                &:hover {
                  background-color: #e8e8e8;

                  &::after {
                    color: $color-text-dark-orange;
                  }
                }
              }

              a.link-list.download {
                &::after {
                  font-family: $material-icons-font;
                  content: '\e2c4';
                }
              }

              a.link-list.download-app {
                &::after {
                  font-family: $material-icons-font;
                  content: '\e884';
                }
              }

              a.link-list.link {
                &::after {
                  font-family: $icofont;
                  content: "\ef71";
                }
              }

              &::before {
                content: "";
                background: $gray-450;
                position: absolute;
                left: 50%;
                height: 1px;
                display: block;
                width: 100%;
                @include transform(translate(-50%));
              }

              &:last-child() {
                &::after {
                  content: "";
                  background: $gray-450;
                  position: absolute;
                  left: 50%;
                  height: 1px;
                  display: block;
                  width: 100%;
                  @include transform(translate(-50%));
                }
              }
            }
          }

          * + .link-items {
            margin-top: 30px;
          }
        }
      }

      .title-lists + .content {
        margin-top: 20px;
      }

      .content + .title-lists {
        margin-top: 65px;
      }

      .content + .copy {
        padding: 0 15px;
        margin-top: 30px;
      }
    }

    .row + .row {
      margin-top: 60px;
    }
  }

  p {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .list-link-block {
    padding: 0 39px;
  }
}

@include media-breakpoint-up(lg) {
  .list-link-block {
    .container-fluid {
      .title-lists {
        padding: 0 40px;
      }
    }
  }
}
