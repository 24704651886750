.hero-generic-block {
  .hero-generic-pagination {
    position: absolute;
    bottom: 40px;
    right: 50%;
    @include transform(translateX(50%));
    z-index: 100;

    button.page.dot + button.page.dot {
      margin-left: 10px;
    }
  }

  .hero-generic-arrow-buttons {
    position: absolute;
    right: 40px;
    left: 40px;

    button {
      height: 40px;
      width: 40px;
      padding: 7px 7px;
      @include border-radius(50%);
      border-style: none;
    }

    button span {
      font-size: 25px;
      @include transform-2(translateX(-50%), translateY(-50%));
      top: 50%;
      right: 50%;
    }
  }

  .hero-generic-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .hero-generic-slider {
      white-space: nowrap;
      height: 100%;

      .hero-generic-item {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        display: inline-block;

        img {
          width: 100%;
          height: auto;
        }

        .hero-landing-text {
          position: absolute;
          width: 100vw;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          top: 0;
          display: flex;
          align-items: center;
          color: white;
          padding: 36px 40px 0 25px;

          h2 {
            white-space: initial;
            min-width: 310px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-generic-block {
    .hero-generic-wrapper {
      .hero-generic-slider {
        .hero-generic-item {
          .hero-landing-text {
            padding: 50px 40px 0 40px;

            h2 {
              max-width: 378px;
              font-size: 42px;
            }
          }
        }
      }
    }

    .hero-generic-arrow-buttons {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-generic-block {
    .hero-generic-wrapper {
      .hero-generic-slider {
        .hero-generic-item {
          .hero-landing-text {
            padding: 50px 40px 0 127px;

            h2 {
              max-width: 450px;
              font-size: 50px;
            }
          }
        }
      }
    }

    .hero-generic-pagination {
      display: none !important;
    }

    .hero-generic-arrow-buttons {
      top: 50%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .hero-generic-block {
    .hero-generic-wrapper {
      .hero-generic-slider {
        .hero-generic-item {
          .hero-landing-text {
            padding: 0 40px 40px 260px;

            h2 {
              max-width: 635px;
            }
          }
        }
      }
    }

    .hero-generic-pagination {
      display: none !important;
    }

    .hero-generic-arrow-buttons {
      top: 50%;
    }
  }
}
