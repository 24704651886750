.faceted-filter-block{
  &[data-document-type="jobs"],
  &[data-document="job"]{
    .facets-results{
      background-color: #ffffff;

      .link-items{
        .l-item{
          a{
            color: #424647;
          }
        }
      }
    }

    .section-padding {
      padding-top: 70px;
      padding-bottom: 70px;

      .limiter {
        .container-fluid {
          .title{
            .col-12{
              padding-right: 15px;
              padding-left: 15px;
            }

            & + *{
              margin-top: 10px;
            }
          }

          .form{
            form{
              padding-left: 0;
              max-width: 265px;

              & + *{
                margin-top: 40px;
                margin-bottom: 0;
              }
            }

            hr{
              background-color: #f4f4f4;

              & + *{
                margin-top: 20px;
              }
            }
          }

          .form-group {
            padding-left: 0;
            padding-right: 0;
            margin: 0;

            .input-group {
              margin-bottom: 41px !important;
              input,
              label {
                color: $gray-900;
              }

              input {
                max-width: 255px;
                border-radius: 3px;
              }
            }

            .input-field {
              &::before {
                content: '';
              }
            }
          }
        }
      }
    }

    .char-filters-container{
      font-size: 0;

      button.link-default{
        display: inline-block;
        background-color: transparent;
        padding-left: 0;
        padding-right: 10px;
        font-size: 20px;
        font-weight: 500;
        padding-top: 10px;

        &.active{
          color: $link-default-hover;
        }
      }

      + *{
        margin-top: 20px;
      }
    }

    button.link-default {
      background-color: transparent;
      padding: 0;
    }

    .subtitle{
      font-weight: 500;
      + .char-filters-container{
        margin-top: 20px;
      }
    }

    &.results {
      background-color: $white;

      .section-padding {
        padding-top: 70px;
      }
    }
  }
}

.faceted-filter-block.results{
  &[data-document-type="jobs"],
  &[data-document="job"]{
    .card-content{
      .card-body{
        position: relative;

        a.hover-link{
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }

        .card-download{
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .faceted-filter-block{
    &[data-document-type="jobs"],
    &[data-document="job"]{
      .section-padding {
        .limiter {
          max-width: 710px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .faceted-filter-block{
    &[data-document-type="jobs"],
    &[data-document="job"]{
      .section-padding {
        .limiter {
          max-width: 730px;
        }
      }
    }
  }
}
