.job-search-container {
  .section-padding {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;

      hr {
        border-color: $gray-450;
      }

      .wysiwyg-field {
        h2 + h3 {
          margin-top: 29px;
        }

        h3 + hr {
          margin-top: 10px;
        }

        hr + h4 {
          margin-top: 20px;
        }
      }

      .tabs-pagination {
        ul.nav {
          border: none;

          li.nav-item {
            a.nav-link {
              padding: 10px 10px 0 0;
              @include rem-2(20, 27);
              font-weight: 500;
              letter-spacing: 0.15px;
              color: $light-blue;
              border: none;

              &.active {
                background-color: transparent;
                color: $link-default-hover;
              }
            }
          }
        }
      }

      .wysiwyg-field + .tabs-pagination {
        margin-top: 10px;
      }

      .input-field {
        max-width: 255px;
      }

      .form-input {
        padding: 0 90px 0 0;
      }

      .form-input + * {
        margin-top: 41px;
      }

      .tabs-pagination + .form-input {
        margin-top: 20px;
      }

      hr + .apply-search {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .job-search-container {
    .section-padding {
      .container-fluid {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .job-search-container {
    .container-fluid {
      padding-right: 50px;
      padding-left: 50px;
    }
  }
}

html.has-mouseover .job-search-container .apply-search a:hover {
  color: $link-default-hover;
}
