.commercial-block {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  .limiter {
    max-width: 920px;

    .card-content {
      .card-text {
        width: 426px;
        @include rem-2(16, 24);
        max-width: 100%;
      }

      .card-title + .card-text {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .commercial-block {
    padding: 0 0 0 257px;

    .limiter {
      max-width: 920px;

      .card-content {
        .card-title + .card-text {
          margin-top: 19px;
        }

        .card-title {
          line-height: 1.8125rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .commercial-block {
    .limiter {
      .card-content {
        .card-title {
          line-height: 2.0625rem;
        }

        .card-title + .card-text {
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .commercial-block {
    padding: 0 165px 0 355px;

    .card-content {
      padding: 0;

      .card-title + .card-text {
        margin-top: 20px;
      }
    }
  }
}
