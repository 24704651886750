/* stylelint-disable */

.article-teaser{
  .pagination{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .actions{
      border-top: solid 1px $gray-590;
      border-bottom: solid 1px $gray-590;
      background: white;
      padding: 0 3px;
      position: absolute;
      top: -42px;
      left: 0;
      right: 0;
      opacity: 0;
      @include transform(translateY(42px));
      @include transition(all 0.5s);
      @include transition-delay(0.3s);
    }

    .pages{
      background: white;
      position: relative;
      padding: 13px 0 0;

      .scroll-wrapper{
        position: relative;
        padding-top: 100px;
        width: 100%;
        overflow: hidden;

        .scroll-links{
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          align-items: center;
          display: flex;
          justify-content: center;
          padding-top: 0;
          z-index: 1;

          .scroll-bar{
            width: 100%;

            .scroller{
              overflow-y: hidden;
              overflow: hidden;

              .scroll-area{
                -webkit-overflow-scrolling: touch;
                display: flex;
                overflow-x: hidden;
                overflow-x: scroll;
                -ms-overflow-style: none;
                overflow: hidden;

                .scroll-content{
                  position: relative;
                  display: flex;
                  flex: 1 0 auto;
                  transform: none;
                  will-change: transform;
                  flex: 0 1 auto;
                  display: table;
                  padding: 0 0 23px;
                  margin: 0 auto;
                  white-space: nowrap;
                  font-size: 0;
                }
              }
            }
          }
        }
      }

      .progress-line{
        background: $gray-400;
        height: 6px;
        position: absolute;
        top: 11px;
        left: 45px;
        right: 110px;
        z-index: 0;
      }

      .start{
        vertical-align: top;
        @include rem(16);
        display: inline-block;
        padding-top: 4px;
        min-width: 210px;
        text-align: center;
        position: relative;
        z-index: 3;
        white-space: normal;
        padding-top: 0;
        min-width: 45px;
        width: 45px;
        background-image: url("../../../assets/images/faucet-end@3x.png");
        background-repeat: no-repeat;
        background-size: 30px 28px;
        background-position: 15px 0;
        height: 28px;
        text-indent: -99999px;

        &:before{
          content: "";
          height: 6px;
          background: $orange;
          display: block;
          position: absolute;
          top: 11px;
          left: 100%;
          width: 85px;
        }

        + .page{
          margin-left: -20px;
        }
      }

      .page{
        vertical-align: top;
        @include rem(16);
        display: inline-block;
        padding-top: 4px;
        min-width: 210px;
        text-align: center;
        position: relative;
        z-index: 3;
        white-space: normal;

        &:before{
          content: "";
          display: block;
          position: absolute;
          left: -50%;
          top: 11px;
          width: 100%;
          height: 6px;
          background: $orange;
          @include transform(scaleX(0));
          @include transform-origin(0 0);
          @include transition(transform 0.5s);
        }

        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 50%;
          @include transform(translateX(-50%));
          background: $gray-400;
          @include transition(background 0.2s);
          @include transition-delay(0.3s);
          width: 20px;
          height: 20px;
          @include border-radius(50% 50% 50% 50%);
        }

        &.first-page{
          &:before{
            left: 0;
            width: 50%;
          }
        }

        &.active{
          &:before{
            @include transform(scaleX(1));
          }

          &:after{
            background: $orange;
          }
        }
      }
    }

    .slider-page{
      position: relative;
      padding-top: 30px;
      background: transparent;
      color: $gray-901;
      border: none;
      @include border-radius(0 0 0 0);
      @include rem-2(14, 16);
      max-width: 130px;

      &:focus{
        outline: none;
      }
    }
  }
}

.popup-article-ready{
  .article-teaser .pagination .actions{
    opacity: 1;
    @include transform(translateY(0));
  }
}

@include media-breakpoint-up(lg) {
  .article-teaser{
    .pagination{
      .pages{
        padding: 0;

        .scroll-wrapper{
          padding-top: 127px;
        }

        .progress-line{
          top: 31px;
        }

        .scroll-wrapper{
          .scroll-links{
            .scroll-bar{
              .scroller{
                .scroll-area{
                  .scroll-content{
                    padding: 20px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
