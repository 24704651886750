.article-list-project-block{
  word-break: break-word;

  .section-padding{
    .container-fluid{
      padding-right: 15px;
      padding-left: 15px;

      .text-image-side-by-side-item{
        text-align: center;

        .body-content{
          text-align: left;

          .wysiwyg-field{
            h2 + p{
              margin-top: 16px;
            }
          }
        }

        .image-and-caption{
          max-width: 160px;
          min-width: 160px;
          max-height: 160px;
          margin: 0 auto;
          padding: 0;

          &.shadow-border{
            padding: 5px;
            @include border-radius(50%);
            @include box-shadow-3(0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14));
            background-color: $gray-50;
          }

          .image{
            overflow: hidden;
            @include border-radius(50%);
            position: relative;
            height: 150px;
            width: 150px;
            margin: 0 auto;

            img{
              position: absolute;
              top: 50%;
              left: 50%;
              height: auto;
              width: 100%;
              background-color: transparent;
              @include transform(translate(-50%, -50%));
            }
          }
        }

        .image-and-caption + .body-content{
          margin-top: 10px;
        }
      }

      .text-image-side-by-side-item + .text-image-side-by-side-item{
        margin-top: 30px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .article-list-project-block{
    .section-padding{
      .container-fluid{
        padding-right: 39px;
        padding-left: 39px;

        .text-image-side-by-side-item{
          .body-content{
            max-width: 530px;

            .wysiwyg-field{
              h2 + p{
                margin-top: 13px;
              }
            }
          }

          .image-and-caption + .body-content{
            margin-top: 14px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .article-list-project-block{
    .section-padding{
      .container-fluid{
        padding-right: 127px;
        padding-left: 127px;

        .text-image-side-by-side-item{
          .body-content{
            max-width: 610px;
          }

          .image-and-caption + .body-content{
            margin-top: 25px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .article-list-project-block{
    .section-padding{
      .container-fluid{
        padding-right: 68px;
        padding-left: 68px;

        .text-image-side-by-side-item{
          .body-content{
            max-width: 760px;
          }
        }
      }
    }
  }
}
