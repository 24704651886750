.sidebar-positioner{
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 1019;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &.stick{
    position: fixed;
    top: 0;
    left: 40px;
  }

  &.scroll-sidebar{
    overflow: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar{
    display: none;
  }
}

.sidebar-menu{
  .sidebar-wrapper{
    padding: 35px 30px 41px;
    max-width: 219px;

    .links{
      list-style: none;
      margin: 0;
      padding: 0;

      li{
        & + li{
          margin-top: 10px;
        }

        a, button{
          border: none;
          background: transparent;
          color: #60c4ff;
          padding: 0;
          text-align: left;
        }
      }

      & + *{
        margin-top: 30px;
      }
    }

    .press-contacts{
      .title{
        font-family: $font-family-sans-serif;
        font-weight: 500;
        @include rem-2(16, 24);

        p{
          @include rem-2(16, 24);

          span{
            @include rem-2(16, 24);
          }
        }

        & + *{
          margin-top: 10px;
        }
      }

      .contact{
        .name{
          font-weight: 500;
        }

        .text{
          @include rem-2(14, 22);
        }

        & + .contact{
          margin-top: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .sidebar-positioner {
    &.stick {
      left: 50px;
    }
  }
}

@include media-breakpoint-up(xl){
  .sidebar-positioner {
    left: 50%;
    @include transform(translate(-291%, -50%));

    &.stick {
      left: 50%;
    }
  }
}

html.has-mouseover{
  .sidebar-menu .sidebar-wrapper .links li{
    a:hover, button:hover{
      color: #99d9ff;
    }
  }
}
