.modal{
  &.modal-featured-content{
    overflow: inherit;
    overflow-y: scroll;
    padding: 70px 15px;

    a.close{
      position: absolute;
      right: 10px;
      top: 10px;

      span{
        @include rem(35);
        color: #424647;
      }
    }

    .generic-container{
      padding: 0;

      + *{
        padding: 0;

        .section-padding{
          padding-top: 0;
        }
      }
    }

    .slider-images{
      .slide-image{
        width: 100%;
        position: relative;

        .image{
          position: relative;
          height: 248px;
          width: 100%;
          overflow: hidden;
          border-radius: 3px;

          picture{
            img{
              position: absolute;
              width: 100%;
              height: auto;
              top: 50%;
              left: 50%;
              @include transform(translate(-50%,-50%));
            }
          }
        }

        .caption-image{
          position: relative;
          @include rem-2(14,16);
          color: $gray-901;
        }

        * + *{
          margin-top: 5px;
        }
      }

      .image-gallery-arrow-buttons{
        position: absolute;
        top: 203px;
        right: 10px;
        z-index: 9999;
        color: white;
        justify-content: flex-end;

        button{
          background: transparent;
          border: none;
          color: white;
          padding: 0 5px;
        }

        span{
          padding: 4px 0 5px;
        }

        &::before{
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 5px;
          opacity: 0.39;
          border-radius: 3px;
          background-color: #000000;
          z-index: -1;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal{
    &.modal-featured-content{
      padding: 70px 39px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal{
    &.modal-featured-content{
      padding: 70px 47px;

      .slider-images{
        .slide-image{
          .image{
            height: 335px;
          }
        }

        .image-gallery-arrow-buttons{
          top: 290px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal{
    &.modal-featured-content{
      padding: 70px 165px;

      .slider-images{
        .slide-image{
          .image{
            height: 400px;
          }
        }

        .image-gallery-arrow-buttons{
          top: 355px;
        }
      }
    }
  }
}
