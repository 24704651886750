.floating-upward {
  position: fixed;
  top: 382px;
  right: 42px;
  z-index: 101;

  .reviewer-upward {
    background-color: $blue;
    padding: 18px;
    max-width: 62px;
    max-height: 62px;
    height: auto;
    border-radius: 50%;
    border: solid 1px $white;
    @include box-shadow-3(0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14));

    &:focus {
      outline: none;
    }

    .material-icons {
      @include rem(25);
      color: $white;
    }
  }
}
