.row{
  margin-left: -25px;
  margin-right: -25px;
}

@include media-breakpoint-up(md) {
  .row{
    margin-left: -40px;
    margin-right: -40px;
  }
}

@include media-breakpoint-up(lg) {
  .row{
    margin-left: -50px;
    margin-right: -50px;
  }
}

@include media-breakpoint-up(sm) {
  .row-sm-up-30{
    margin-left: -15px;
    margin-right: -15px;
  }
}
