#cards-block {
  .card-content {
    padding: 70px 15px;

    .card-title {
      font-family: $headings-font-family;
      @include rem-2(25, 27);
      margin-bottom: 20px;

      span {
        display: block;
      }
    }

    .card-text {
      .theme-light {
        display: inline;
      }
    }

    &.bg-neutral-medium {
      .card-content-2 {
        padding-bottom: 40px;

        & + .card-content-2 {
          padding-top: 30px;
          padding-bottom: 0;
          border-top: 1px solid $gray-450;
        }

        .card-text {
          margin-bottom: 20px;
        }
      }
    }
  }
}

//Here begin the first breakpoint
@include media-breakpoint-up(md) {
  #cards-block {
    .card-content{
      padding-left: 257px;
      padding-right: 39px;

      .card-title {
        @include rem-2(27, 29);
      }

      .card-text {
        line-height: 24px;
      }

      .card-content-2:first-child {
        padding-bottom: 30px;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  #cards-block {
    .card-content {
      padding: 0 47px 0 257px;

      .card-title {
        @include rem-2(31, 33);
      }

      &.bg-neutral-medium {
        .card-content-2 {
          padding: 0;

          &:first-child {
            padding-right: 51px;
            padding-top: 70px;
            padding-bottom: 70px;
          }

          & + .card-content-2 {
            border: none;
            border-left: 1px solid $gray-450;
            padding-left: 38px;
            padding-top: 70px;
            padding-bottom: 70px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #cards-block {
    .card-content {
      padding: 0 165px 0 355px;

      .limiter {
        max-width: 920px;
      }

      .card-content-2 {
        max-width: 460px;
        padding: 70px 0 70px 35px;

        &:first-child {
          padding: 70px 15px 70px 0;
        }
      }

      &:first-child {
        padding-top: 70px;
        padding-bottom: 70px;
      }
    }
  }
}
