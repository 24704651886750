.article-teaser{
  .slider-items{
    position: relative;
    z-index: 1;
    white-space: nowrap;

    .article-slide-item{
      display: inline-block;
      white-space: normal;
      vertical-align: top;
    }
  }
}
