.news-list-block {
  word-break: break-word;

  .view {
    position: relative;
    display: block;
    margin: 0 0 85px 0;

    .down-button {
      position: absolute;
      top: 66px;
      right: 0;
      color: $gray-900;
    }
  }

  .container-fluid {
    padding: 0 15px;

    .header-title {
      margin-bottom: 32px;

      .title {
        font-family: $headings-font-family;
        @include rem-2(25, 27);
        color: $gray-990;

        .color {
          color: $color-text-dark-orange;
          display: inline;
        }
      }
    }

    .carousel-meet-in-te-news {
      .carousel-indicators {
        position: absolute;
        bottom: -56px;

        li{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $gray-500;
          margin: 0 5px 0;
        }

        li.active {
          background-color: $color-text-dark-orange;
        }
      }

      .carousel-inner {
        .carousel-item.image {
          padding: 40px;
          overflow: hidden;
        }

        .carousel-item {
          position: static;

          .item-button {
            a.link-arrow {
              color: #424647;
            }
          }

          .carousel-caption {
            position: static;
            text-align: left;
            padding: 0;

            .title {
              font-family: $font-family-sans-serif;
              @include rem-2(16, 24);
              font-weight: 500;
              color: $orange-bright;
            }

            .body {
              font-family: $font-family-sans-serif;
              @include rem-2(20, 24);
              font-weight: 500;
              letter-spacing: 0.15px;
              color: $white;
            }

            * + .body {
              margin-top: 11px;
            }

            a.link-arrow:hover{
              color: $color-text-dark-orange;
            }
          }

          * + .carousel-caption {
            margin-bottom: 28px;
          }

          .carousel-cart {
            padding: 40px;
            width: 100%;
            background-color: $gray-100;

            .title {
              font-family: $font-family-sans-serif;
              @include rem-2(16, 24);
              font-weight: 500;
              color: $color-text-dark-orange;
            }

            .body {
              font-family: $font-family-sans-serif;
              @include rem-2(20, 24);
              font-weight: 500;
              letter-spacing: 0.15px;
              color: $gray-900;
            }

            * + .body {
              margin-top: 11px;
            }

            * + .item-button {
              margin-top: 28px;
            }
          }
        }

        .image {
          background-color: $black;
          position: static;

          picture {
            z-index: -2;

            img {
              opacity: .25;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 100%;
            }
          }

          .main-button {
            position: static;
            left: 0;
            right: 0;

            .link-arrow {
              color: $white;
            }
          }

          * + .main-button {
            margin-top: 28px;
          }
        }
      }
    }
  }
}

.bg-neutral-medium,
.bg-neutral-dark,
.bg-dark-blue {
  .news-list-block {
    .container-fluid {
      .carousel-meet-in-te-news {
        .carousel-inner {
          .carousel-item {
            .carousel-cart {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

html.has-mouseover {
  .news-list-block {
    .container-fluid {
      .carousel-meet-in-te-news {
        .carousel-inner {
          .carousel-item {
            .item-button {
              a.link-arrow:hover{
                color: $color-text-dark-orange;
              }
            }
            .carousel-caption {
              a.link-arrow:hover{
                color: $color-text-dark-orange;
              }
            }
          }

          .image {
            .main-button {
              a.link-arrow:hover {
                color: $color-text-dark-orange;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news-list-block{
    padding-left: 40px;
    padding-right: 40px;

    .view {
      margin: 0 0 49px 0;

      .down-button {
        top: 30px;
      }
    }

    .container-fluid {
      margin-left: auto;
      padding: 0;

      .header-title {
        .title {
          @include rem-2(27, 29);
        }
      }

      .carousel-meet-in-te-news {
        .carousel-indicators {
          display: none;
        }

        .carousel-inner {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;

          .carousel-item {
            min-height: 194px;
          }

          .carousel-item.image {
            position: relative;
            display: block;
            margin-top: 0;

            .carousel-caption {
              text-align: left;

              .title {
                @include rem-2(16, 24);
                font-weight: 500;
              }

              .body {
                @include rem-2(20, 24);
                font-weight: 500;
                letter-spacing: 0.15px;
                min-width: 392px;
                margin-bottom: 36px;
              }
            }

            * + .carousel-caption {
              margin-bottom: 30px;
            }
          }

          .block-right {
            display: contents;

            .carousel-item {
              padding: 0;
              display: block;
              min-width: 100%;

              .carousel-cart {
                width: 100%;
                margin-top: 10px;

                .title {
                  @include rem-2(16, 24);
                  font-weight: 500;
                }

                .body {
                  @include rem-2(17, 22);
                }

                * + .body {
                  margin-top: 10px;
                }

                * + .item-button {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .news-list-block {
    .container-fluid {
      .header-title {
        .title {
          @include rem-2(31, 33);
        }
      }

      .carousel-meet-in-te-news {
        .carousel-inner {
          flex-direction: row;

          .carousel-item.image {
            min-width: auto;
            width: auto;
            max-width: 225px;
            min-height: 395px;
            margin: 0;
            padding-right: 30px;
            display: flex;
            flex-direction: column;

            .carousel-caption {
              min-width: auto;
              width: auto;
              position: static;

              .body {
                min-width: auto;
                @include rem-2(20, 24);
                font-weight: 500;
                letter-spacing: 0.15px;
              }
            }

            * + .main-button {
              margin-top: auto;
            }

            * + .carousel-caption {
              margin-bottom: 92px;
            }
          }

          .block-right {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 472px;
            margin: 0 0 0 23px;
            padding: 0;

            .carousel-item {
              padding: 40px;
              width: auto;
              min-height: 194px;
              background-color: $gray-100;
              overflow: hidden;

              .carousel-cart {
                padding: 0;
                position: static;
                margin: 0;
                width: 100%;
                max-width: 392px;
                display: table-cell;
              }
            }

            * + .carousel-item {
              margin-top: 7px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .bg-neutral-medium,
  .bg-neutral-dark,
  .bg-dark-blue  {
    .news-list-block {
      .container-fluid {
        .carousel-meet-in-te-news {
          .carousel-inner {
            .block-right {
              .carousel-item {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .news-list-block {
    .container-fluid {
      max-width: 981px;

      .carousel-meet-in-te-news {
        .carousel-inner {
          .carousel-item.image {
            max-width: 287px;
            min-width: 287px;
            min-height: 395px;

            * + .carousel-caption {
              margin-bottom: 140px;
            }
          }

          .block-right {
            max-width: 603px;
            margin: 0 0 0 30px;

            .carousel-item {
              min-width: 603px;

              .carousel-cart {
                max-width: 523px;
              }
            }
          }
        }
      }
    }
  }
}
