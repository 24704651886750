.col-title {
  padding: 30px 25px 30px;
  text-align: left;

  .sub-title {
    margin-left: 0;
  }

  * + .sub-title {
    margin-top: 20px;
  }

  * + .text {
    margin-top: 20px;
  }
}

@include media-breakpoint-up(md) {
  .col-title {
    padding: 60px 30px;
    text-align: right;

    .sub-title {
      margin-left: auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .col-title {
    padding: 60px 20px;
  }
}

@include media-breakpoint-up(xl) {
  .col-title {
    padding: 100px 40px;
    min-width: 360px;

    + .col-lg-4,
    + .col-lg-9 {
      flex-grow: 1;
    }

    + .col-lg-4 + .col-lg-5 {
      flex-grow: 1;
    }
  }
}
