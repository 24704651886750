@use "sass:math";

.five-column-scroll {
  .section-padding {
    padding: 0;
  }

  .col-title{
    padding: 30px  math.div($grid-gutter-width, 2);

    > .title{
      margin: 0;
    }

    .sub-title{
      max-width: 260px;
    }

    .title + .sub-title{
      margin-top: 20px;
    }

    .social-links{
      margin-top: 30px;

      a.social-link {
        color: $gray-600;
        position: relative;
        display: inline-block;
        text-indent: -99999px;
        text-align: left;
        width: 33px;
        height: 35px;

        &:after{
          font-family: $ionicons;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-rendering: auto;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: inherit;
          text-indent: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          @include rem(35);
          @include transform(translate(-50%, -50%));
        }

        &:focus{
          color: $orange;
        }

        + a.social-link{
          margin-left: 25px;
        }
      }

      a.fb{
        width: 14px;

        &:after{
          content: "\f231";
        }
      }

      a.tw{
        text-indent: -9999px;
        text-align: left;
        width: 27px;
        height: 27px;
        -webkit-mask-size: 27px;
        mask-size: 27px;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-image: url(/assets/icons/x-logo.svg);
        mask-image: url(/assets/icons/x-logo.svg);
        background: #535353;
        top: 5px;

        &:after{
          content: none;
        }
      }

      a.in{
        width: 27px;

        &:after{
          content: "\f351";
        }
      }

      a.li{
        width: 27px;

        &:after{
          content: "\f239";
        }
      }
    }
  }

  .col-items{
    display: block;

    .scroll-wrapper{
      position: relative;
      padding-top: 450px;
      width: 100%;
      overflow: hidden;

      .scroll-links{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 0;
        z-index: 1;

        .scroll-bar{
          width: 100%;

          .scroller{
            overflow-y: hidden;

            .scroll-area{
              -webkit-overflow-scrolling: touch;
              display: flex;

              .scroll-content{
                position: relative;
                width: 100%;
                transform: none;
                will-change: transform;
                flex: 0 1 auto;
                display: table;
                padding: 0 0;
                margin: 0 auto;
                white-space: nowrap;
                font-size: 0;
              }
            }
          }
        }
      }
    }

    .col-content-item{
      position: relative;
      overflow: hidden;
      vertical-align: top;
      padding: 90px 40px 50px 40px;
      height: 450px;
      width: 270px;
      font-size: 1rem;
      display: inline-block;
      position: relative;
      flex: 1 0 auto;
      justify-content: center;
      box-sizing: border-box;
      white-space: nowrap;
      -webkit-appearance: none;

      picture {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          width: auto;
          height: 100%;
          @include transform(translate(-50%, -50%));
        }
      }

      .number-title{
        position: absolute;
        left: 0;
        top: 9px;
        z-index: 2;
      }

      > .inner-item{
        white-space: normal;
        position: relative;
        z-index: 3;
        height: 100%;

        .info{
          max-height: 225px;
          overflow: hidden;
        }

        .teaser{
          position: relative;
        }

        h4.p{
          + .teaser{
            margin-top: 41px;

            &:before{
              content: "";
              position: absolute;
              top: -21px;
              height: 1px;
              width: 100%;
              background: $gray-450;
            }
          }
        }

        .link-arrow{
          position: absolute;
          bottom: 0;
        }
      }

      &:nth-child(even) {
        background-color: $white;

        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $white;
          opacity: 0.85;
          z-index: 1;
        }
      }

      &:nth-child(odd) {
        background-color: $black;

        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $black;
          opacity: 0.85;
          z-index: 1;
        }

        > .inner-item{
          h4.p + .teaser:before{
            background: $gray-590;
          }
        }
      }

      &.theme-dark {
        .inner-item {
          .info {
            color: $white;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .five-column-scroll {
    .col-title{
      padding: 60px 30px (60px + 65px);
      position: relative;

      .social-links{
        margin-top: 0;
        position: absolute;
        right: 30px;
        left: 30px;
        bottom: 60px;
      }
    }

    .col-items {
      flex: 1;
      max-width: 100%
    }
  }
}

@include media-breakpoint-up(lg) {
  .five-column-scroll {
    .col-title {
      padding-left: 20px;
      padding-right: 20px;
    }

    .col-items {
      .col-content-item {
        width: 25%;
        padding: 74px 20px 50px 20px;

        > .inner-item{
          .info{
            max-height: 267px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .five-column-scroll {
    .col-title {
      padding-left: 40px;
      padding-right: 40px;
    }

    .col-items {
      .col-content-item {
        padding: 90px 40px 50px;
      }
    }
  }
}

html.has-mouseover {
  .five-column-scroll {
    .col-title {
      .social-links {
        a.social-link:hover {
          color: $orange;
        }

        a.social-link.tw:hover {
          background: $orange;
        }
      }
    }

    .col-items {
      .scroll-content {
        .item.col-content-item {
          &:nth-child(even){
            a.link-arrow:hover, a:hover{
              color: $color-text-dark-orange;
            }
          }
        }
      }
    }
  }
}
