/* stylelint-disable */

@mixin bg-gradient($color1, $color2) {
  background: $color1;
  background: -moz-linear-gradient(left, $color1 0%, $color2 100%);
  background: -webkit-linear-gradient(left, $color1 0%, $color2 100%);
  background: linear-gradient(to right, $color1 0%, $color2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 );
}

@mixin bg-gradient-2($color1, $color2) {
  background: $color1;
  background: -moz-linear-gradient(90deg, $color1 0%, $color2 100%);
  background: -webkit-linear-gradient(90deg, $color1 0%, $color2 100%);
  background: -ms-linear-gradient(90deg, $color1 0%, $color2 100%);
}

@mixin bg-gradient-3($color1, $color2) {
  background: $color2;
  background: -webkit-linear-gradient(right, $color1, $color2);
  background: -o-linear-gradient(right, $color1, $color2);
  background: -moz-linear-gradient(right, $color1, $color2);
  background: linear-gradient(to right, $color1, $color2);
}

@mixin bg-gradient-4($color1, $color2) {
  background: -moz-linear-gradient(0deg, $color1 0%, $color2 100%);
  background: -webkit-linear-gradient(0deg, $color1 0%, $color2 100%);
  background: -ms-linear-gradient(0deg, $color1 0%, $color2 100%);
  background: linear-gradient(0deg, $color1 0%, $color2 100%);
}

@mixin bg-gradient-5($color1, $color2, $color3) {
  background: -moz-linear-gradient(top, $color1 0%, $color1 50%, $color2 87%, $color3 100%);
  background: -webkit-linear-gradient(top, $color1 0%,$color1 50%,$color2 87%,$color3 100%);
  background: linear-gradient(to bottom, $color1 0%,$color1 50%,$color2 87%,$color3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 );
}

@mixin bg-gradient-6($color1, $color2) {
  background-image: -moz-linear-gradient(to bottom, $color1, $color2);
  background-image: -webkit-linear-gradient(to bottom, $color1, $color2);
  background-image: -ms-linear-gradient(to bottom, $color1, $color2);
  background-image: linear-gradient(to bottom, $color1, $color2);
}
