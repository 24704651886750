#popup-article-overlay{
  background: white;
  opacity: 0;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

#popup-articles{
  display: none;
}

#popup-article{
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

html.popup-article-on{
  main{
    position: relative;
    z-index: 1;
  }

  header.fixed-top{
    position: fixed;
    z-index: 3;
  }

  #popup-article-overlay{
    display: block;
    @include transition(opacity 0.3s);
  }

  #popup-article{
    display: block;
    @include transition(opacity 0.4s);
    @include transition-delay(0.2s);
  }

  &.popup-article-ready{
    #popup-article-overlay{
      opacity: 1;
    }

    #popup-article{
      opacity: 1;
    }
  }

  &.popup-article-full{
    #popup-article{
      @include transition-delay(0s);
    }

    #popup-article-overlay{
      @include transition-delay(0.3s);
    }
  }

  &.popup-article-off{
    #popup-article{
      opacity: 0;
    }

    #popup-article-overlay{
      opacity: 0;
    }
  }
}
