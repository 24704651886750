.press-inquiries {
  a {
    color: $link-default;
  }

  .limiter {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;

    .content-main {
      padding: 70px 15px;

      .head-content {
        .title-content {
          font-family: $headings-font-family;
          @include rem(31);
          line-height: 33px;
          overflow-wrap: break-word;

          .color {
            color: $orange-bright;
            display: inline;
          }
        }
      }

      .info-content {
        .content-data {
          .complete-name {
            font-family: $font-family-sans-serif;
            @include rem(20);
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            overflow-wrap: break-word;

            p {
              margin-bottom: 10px;
            }
          }

          * + .content-text {
            margin-top: 10px;
          }

          .content-text {
            @include rem(16);
            font-family: $font-family-sans-serif;
            line-height: 24px;

            .cellphone-number {
              overflow-wrap: break-word;

              p {
                margin: 0;
              }
            }

            .email-content {
              overflow-wrap: break-word;
              max-width: 300px;

              p {
                margin: 0;
              }
            }
          }

          &:nth-child(n+2) {
            margin-top: 30px;
          }
        }

        * + .content-second-data {
          margin-top: 50px;
        }
      }

      * + .info-content {
        margin-top: 40px;
      }
    }
  }
}

.theme-dark {
  .press-inquiries {
    a {
      color: $blue-light;
    }
  }
}

.press-releases-contact-block.bg-dark-blue {
  .press-inquiries {
    a {
      color: $blue-light;
    }
  }
}

html.has-mouseover {
  .press-releases-contact-block.bg-dark-blue {
    .press-inquiries {
      a:hover{
        color: $blue-light-hover;
      }
    }
  }
}


.press-releases-contact-block{
  margin: 70px 0 -70px;

  .press-inquiries {
    .limiter{
      max-width: 920px;

      .content-main{
        .title-content{
          padding: 0 20px;
        }

        .info-content{
          .content-data{
            padding-left: 25px;
            padding-right: 25px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .press-inquiries {
    .limiter {
      .content-main {
        .info-content {
          .content-data {
            &:nth-child(n+2) {
              margin-top: 0;
            }

            &:nth-child(n+4) {
              margin-top: 30px;
            }
          }

          .content-second-data {
            margin-top: 0;
          }
        }
      }
    }
  }

  .press-releases-contact-block{
    .press-inquiries {
      .limiter{
        .content-main{
          padding-right: 39px;
          padding-left: 39px;

          .info-content {
            .content-data {
              .head-content{
                .title-content{
                padding-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
