.search-faceted-filter-block {
  .section-padding {
    padding-top: 40px;
    padding-bottom: 0;

    .limiter-540 {
      margin-bottom: 41px;

      .container-fluid {
        .text {
          font-size: 16px;
          line-height: 1.25;
          color: $white;
        }

        .form-group {
          max-width: 445px;
          margin: 0 auto;
          padding-right: 0;
          padding-left: 0;

          .input-field {
            margin-top: 20px;
            margin-bottom: 20px;

            input {
              height: 52px;
            }

            label {
              top: 3px;
              text-align: left;
              color: #424647;
            }

            .input-group-prepend {
              .input-group-text {
                padding-top: 0 !important;
                position: absolute;
                top: 25%;
                right: 0;
                background: none;
                border: none;

                .material-icons {
                  color: #757575;
                }
              }
            }

            &.input-outlined {
              > input {
                @include border-radius(6px);
              }
            }
          }
        }

        .faceted-hide-filter {
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 8px;
          }

          .icon-faceted-filter {
            cursor: pointer;
            width: 40px;
            height: 40px;
            @include border-radius(50px);
            padding-top: 10px;
          }
        }
      }
    }

    .tab-container {
      .tabs-wrapper {
        .nav-tabs {
          display: -webkit-box;
          flex-wrap: unset;
          width: 100%;
          overflow: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          padding-left: 20px;

          &::-webkit-scrollbar {
            display: none;
          }

          .nav-item {
            .nav-link {
              color: $white;
              font-weight: 500;
              padding-right: 0;
              padding-left: 0;
              font-size: 20px;
              line-height: 1.35;
              padding-bottom: 15px;
              letter-spacing: 0.15px;
              border: none;
              border-bottom: 2px solid transparent;

              &.active {
                background-color: transparent;
                border: none;
                color: $orange-bright;
                border-bottom: 2px solid $orange-bright;
              }

              &:focus{
                color: #ffa860;
                outline: none;
                border: none;
                border-bottom: 2px solid #ffa860;
              }

              &:hover{
                color: #ffa860;
                outline: none;
                border: none;
                border-bottom: 2px solid #ffa860;
              }
            }
          }

          & + .nav-item {
            margin-left: 60px !important;
          }
        }

        .apply-search {
          a {
            color: $blue-light;
          }

          .btn {
            position: relative;
            padding: 9px 65px 8px 40px;
            background-color: $gray-300;
            color: $gray-900;
            font-weight: 500;

            &::after {
              position: absolute;
              display: block;
              top: 50%;
              right: 35px;
              @include transform(translateY(-50%));
              font-family: 'IcoFont',sans-serif;
              font-size: 1.25rem;
              content: "\ea5d";
            }
          }

          a + .btn {
            margin-left: 30px;
          }

          a.d-none + .btn {
            margin-left: 0;
          }
        }

        .tab-content {
          padding-top: 21px;
          padding-bottom: 39px;

          .tab-pane {
            .container-fluid {
              overflow: scroll;
              max-height: 232px;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */

              &::-webkit-scrollbar {
                display: none
              }

              .radio-group {
                margin: 0 auto;

                label {
                  width: fit-content;
                  float: left;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 1.25;
                  color: $white;

                  &.btn-radio {
                    margin-bottom: 0;
                    min-height: 38px
                  }
                }

                .btn {
                  background-color: transparent;
                  border: 1px solid $white;
                  @include border-radius(21.5px);
                  max-width: 289px;
                  margin-top: 20px;

                  input {
                    position: relative;
                    width: 0;
                  }

                  .input-group-prepend {
                    .input-group-text {
                      position: absolute;
                      top: 50%;
                      right: 0;
                      background: none;
                      border: none;
                      @include transform(translateY(-50%));

                      span {
                        @include rem(19);
                        background-color: $white;
                        @include border-radius(50px);
                        font-weight: 600;
                        padding-top: 2px;
                        height: 19px;
                        width: 19px;
                        font-size: 15px;
                        color: $black;
                      }
                    }
                  }

                  &.active {
                    background-color: $white;
                    color: $gray-900;

                    .input-group-prepend {
                      .input-group-text {
                        span {
                          background-color: $gray-900;
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }

              .radio-group.date {
                padding-top: 30px;
                margin: 0;
              }

              &.split-fields {
                align-items: initial;

                .form-elements-styles-block {
                  padding-top: 30px;
                  padding-left: 60px;

                  form {
                    margin-left: 100px;
                  }

                  .field-group-date {
                    width: fit-content;
                    font-family: $font-family-sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.25;
                    margin-bottom: 20px;

                    label {
                      color: $white;
                      margin: 0;
                    }
                  }

                  .date-input {
                    display: inline-block;

                    .date-input-label {
                      margin-bottom: 5px;
                      width: fit-content;
                      font-family: $font-family-sans-serif;
                      font-size: 14px;
                      line-height: 1.14;
                      color: rgba(255, 255, 255, 0.9);
                    }

                    .input-field {
                      margin-top: 0;
                      width: 104px;

                      .datepicker.validate.valid {
                        font-family: $font-family-sans-serif;
                        font-size: 16px;
                        line-height: 1.5;
                        color: $gray-900;
                      }

                      label {
                        font-size: 12px;
                        line-height: 1.33;
                        color: $color-text-dark-orange;
                      }
                    }
                  }

                  & + .radio-group {
                    .group-title {
                      width: 100%;
                    }

                    .btn {
                      height: 43px;
                      padding-top: 9px;

                      .input-group-text {
                        padding: 11px 20px;
                      }

                      & + .btn {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          .tab-pane + .apply-search {
            margin: 45px;
          }
        }
      }

    }
  }
}

.find-document {
  .search-faceted-filter-block .section-padding .tab-container .tabs-wrapper {
    .nav-item +.nav-item {
      margin-left: 65px;
    }

    .tab-content {
      .tab-pane {
        max-width: 1440px;
        margin: 0 auto;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .find-document {
    .search-faceted-filter-block .section-padding .tab-container .tabs-wrapper {
      .tab-content .split-fields {
        .radio-group {
          margin: 0;
        }

        .form-elements-styles-block {
          padding-left: 60px;
        }

        .radio-group .btn {
          height: 43px;
          padding-top: 9px;

          .input-group-text {
            padding: 11px 20px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .find-document {
    .search-faceted-filter-block .section-padding .tab-container .tabs-wrapper {
      .tab-content .split-fields {
        .form-elements-styles-block {
          padding : 0;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .find-document {
    .search-faceted-filter-block .section-padding .tab-container {
      margin: 0;

      .tabs-wrapper {
        padding: 0;

        .nav-tabs li:last-of-type {
          padding-right: 25px;
        }

        .tab-content .split-fields {
          .form-elements-styles-block form{
            margin: 0;
          }
        }
      }
    }
  }

  .search-faceted-filter-block {
    .section-padding {
      .tab-container {
        .tabs-wrapper {
          .tab-content {
            .tab-pane {
              .container-fluid {
                &.split-fields {
                  .form-elements-styles-block {
                    padding-top: 30px;
                    padding-left: 0;

                    .container-fluid {
                      padding-left: 0;

                      form {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search-faceted-filter-block {
    .section-padding {
      padding-top: 40px;

      .limiter-540 {
        .container-fluid {
          padding-right: 0;
          padding-left: 0;

          .text{
            display: inline-block;
          }
        }
      }

      .tab-container {
        .tabs-wrapper {
          display: inline-block;

          .tab-content {
            .container-fluid {

              .radio-group {

                .group-title {
                  padding-left: 26px;

                  label {
                    margin: 0;
                  }
                }

                .btn {
                  margin-right: 20px;
                }
              }
            }
          }

          .nav-tabs {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-faceted-filter-block {
    .section-padding {
      .tab-container {
        .tabs-wrapper {
          .tab-content {
            .container-fluid {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .search-faceted-filter-block {
    .section-padding {
      .tab-container {
        .tabs-wrapper {
          .tab-content {
            .container-fluid {
              padding: 0;

              .date-input{
                & + .date-input {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

