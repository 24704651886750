.faceted-filter-block.newsroom_videos{
  // Facated Filter Block for Videos
  .results{
    .block-image-text{
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 20px;

      .block-image-container{
        height: 100%;
        border-bottom: 2px solid $color-text-dark-orange;

        &:last-child {
          margin-bottom: 0;
        }

        .video-img-container {
          width: 100%;
          min-width: 100%;

          .video-image {
            position: relative;

            .img-video {
              width: 100%;
            }

            .content-picture {
              overflow: hidden;
            }

            .video-btn-container {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;

              .btn-play {
                width: 65px;
                height: 65px;
              }
            }
          }
        }
      }

      .main-content-text {
        .text-content {
          $font-family-sans-serif:'Roboto', sans-serif;
          @include rem-2(20, 24);
          overflow: hidden;
          letter-spacing: 0.15px;
          font-weight: 500;
          color: #424647;

          p {
            margin-bottom: 0;
          }
        }

        * + .date-content {
          margin-top: 10px;
        }

        .date-content {
          $font-family-sans-serif:'Roboto', sans-serif;
          overflow: hidden;
          @include rem-2(16, 24);
          font-weight: 500;
          margin-bottom: 20px;
          color: #424647;

          p {
            margin-bottom: 0;
          }
        }
      }

      * + .main-content-text {
        margin-top: 15px;
      }
    }

    * + .block-image-text {
      margin-top: 40px;
    }
  }
}

