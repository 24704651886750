.anchor-block {
  border-top: 1px solid $gray-450;
  width: 100%;

  .container-fluid {
    .row {
      .anchor-block-wrapper {
        padding: 30px 57px;
        width: 100%;

        .anchor-block-title {
          text-align: center;
          @include rem-2(31, 33);
          color: $gray-900;
          overflow-wrap: break-word;
          margin: 0;

          p {
            margin: 0;
          }
        }

        * + .anchor-block-button {
          margin-top: 13px;
        }

        .anchor-block-button {
          position: relative;
          padding: 0 16px;

          a {
            font-weight: 500;
            color: $gray-900;
            @include rem-2(16,16);
            padding: 16px 65px 16px 40px;
            background-color: $gray-50;
            border: 1px solid $gray-900;
            border-radius: inherit;

            &:after {
              right: 35px;
            }

            &:hover {
              background-color: $gray-light;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .anchor-block {
    .container-fluid {
      .row {
        .anchor-block-wrapper {
          padding: 30px 40px;
          max-width: 1026px;
          margin: 0 auto;
          display: flex;
          align-items: center;

          .anchor-block-title {
            margin: 0;
            max-width: 262px;
          }

          .anchor-block-button {
            margin-left: auto;
            margin-top: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .anchor-block {
    .container-fluid {
      .row {
        .anchor-block-wrapper {
          padding-left: 0;
          padding-right: 0;
          max-width: 920px;
        }
      }
    }
  }
}
