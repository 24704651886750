.custom-html{
  .col-items{
    .row{
      height: 100%;

      .raw-html{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .custom-html{
    .col-items{
      position: relative;
      z-index: 1;
      height: 100%;
      flex: 1;
      max-width: 100%
    }
  }
}

@include media-breakpoint-up(xl) {
  .custom-html {
    .col-title {
      .text {
        max-width: 230px;
        margin-left: auto;
      }
    }
  }
}
