.jquery-modal .modal-bio-detail.modal-story-content{
  border-radius: inherit;
  overflow: hidden;

  &.stories-slides{
    .align-items-center{
      padding-left: 0;
      padding-right: 0;
    }
  }

  &::-webkit-scrollbar{
    display: none;
  }

  .slider-wrapper{
    height: calc(100vh - 145px);
  }

  .article-teaser{
    height: calc(100vh - 100px);

    .slider-wrapper{
      .slider-items{
        height: 100%;

        .article-slide-item{
          height: 100%;

          .scroll-box{
            height: calc(100vh - 243px);
          }
        }
      }
    }

    .pagination{
      bottom: 60px;

      .actions{
        top: -84px;
        opacity: 1;
      }
    }
  }

  .close{
    top: 130px;
  }

  .close-featured-stories{
    margin-top: 35px;
    margin-left: 25px;
  }

  .section-block{
    .section-padding{
      padding: 0;
    }
  }
}

.featured-stories-block{
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  word-break: break-word;

  .cart-container{
    width: 100%;
    position: relative;
    margin: 0 auto;

    .header-title{
      margin-bottom: 30px;

      .title{
        font-family: $headings-font-family;
        @include rem-2(25, 27);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;

        .color{
          color: $color-text-dark-orange;
          display: inline;
        }
      }
    }

    .cart-content{
      position: relative;
      display: flex;
      flex-direction: column;
      border-top: solid $color-text-dark-orange 3px;
      background: $white;
      color: $gray-900;

      .cart-content-img{
        position: relative;
        height: 138px;
        overflow: hidden;

        img{
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
        }
      }

      .cart-content-text{
        padding: 27px 40px 40px;
        position: relative;

        font-family: $headings-font-family;
        @include rem-2(25, 27);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;

        p{
          font-size: 25px !important;
        }

        p:last-of-type{
          margin-bottom: 0;
        }

        .content-arrow{
          text-align: right;
          font-family: $font-family-sans-serif;
        }

        * + .content-arrow{
          margin-top: 20px;
        }
      }

      a{
        color: $gray-900;
      }

      p{
        @include rem-2(25, 33);
      }
    }
  }

  * + .content-link{
    margin-top: 30px;
    text-align: right;
  }
}

.bg-neutral-medium{
  .featured-stories-block{
    .cart-container{
      .cart-content{
        background-color: $white;
      }
    }
  }
}

.bg-neutral-dark{
  .featured-stories-block{
    .cart-container{
      .cart-content{
        background-color: $white;
      }
    }
  }
}

.bg-dark-blue{
  .featured-stories-block{
    .cart-container{
      .cart-content{
        background-color: $white;
      }
    }
  }
}

html.has-mouseover{
  .featured-stories-block{
    .cart-container{
      .cart-content{
        a:hover{
          color: $orange
        }
      }
    }
  }
}

@include media-breakpoint-up(md){
  .jquery-modal .modal-bio-detail.modal-story-content{
    .close-featured-stories{
      margin-top: 45px;
      margin-left: 35px;
    }

    .close{
      margin-top: 12px;
      margin-right: 20px;
    }

    .article-teaser{
      padding-top: 0;

      .slider-wrapper{
        .slider-items{
          .article-slide-item{
            .scroll-box{
              .story-content{
                height: 100%;
                position: relative;

                .limiter{
                  height: 100%;

                  .row{
                    height: 100%;
                  }

                  .content{
                    height: 100%;
                  }

                  .content-image{
                    height: 100%;

                    .image{
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .featured-stories-block{
    padding-left: 40px;
    padding-right: 40px;

    .section-padding{
      padding-right: 40px;
    }

    .cart-container{
      .header-title{
        .title{
          @include rem-2(27, 29);
        }
      }

      .cart-content{
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;

        .cart-content-img{
          width: 200px;
          height: auto;
          min-width: 200px;

          img{
            position: absolute;
            width: auto;
            height: 100%;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
        }

        .cart-content-text{
          padding: 40px;
          position: relative;
          @include rem-2(27, 29);

          * + .content-arrow{
            padding-top: 24px;
          }

          .h1{
            @include rem-2(27, 35);
          }
        }
      }
    }
  }

  .featuredContentTeaser{
    padding-right: 0;
    padding-left: 0;

    .card-container-fluid{
      .title-cart-grid{
        max-width: 480px;
        margin-left: 249px;
      }

      .card-container-row{
        .main-item{
         .card-teaser{
           .image{
              position: relative;
              height: 200px;

              img{
                width: 100%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .featured-stories-block{
    .cart-container{
      .header-title{
        .title{
          @include rem-2(31, 33);
        }
      }

      .cart-content{
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;
        min-height: 272px;

        .cart-content-img{
          width: 270px;
          min-width: 270px;

          img{
            position: absolute;
            width: auto;
            height: 100%;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
        }

        .cart-content-text{
          padding: 40px;
          position: relative;
          min-width: 450px;

          .content-arrow{
            text-align: left;
          }

          * + .content-arrow{
            padding-top: 42px;
          }

          .h1 {
            @include rem-2(31, 39);
          }
        }
      }
    }
  }

  .featuredContentTeaser{
    .card-container-fluid{
      .title-cart-grid{
        max-width: 728px;
        margin-left: 253px;
      }
    }
  }

  .jquery-modal{
    .modal-bio-detail{
      &.modal-story-content{
        top: 60px;

        .close{
          margin-top: -40px;
        }

        .article-teaser{
          .slider-wrapper{
            .slider-items{
              .article-slide-item{
                .scroll-box{
                  height: calc(100vh - 243px);
                }
              }
            }
          }

          .pagination{
            bottom: 60px;
            @include transform(translateY(0));
          }
        }
      }

      .article-teaser .slider-wrapper{
        .slider-items .article-slide-item{
          .scroll-box .story-content{
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .jquery-modal .modal-bio-detail.modal-story-content{
    .close-featured-storie{
      margin-left: 72px;
    }

    .close{
      margin-right: 45px;
    }
  }

  .featured-stories-block{
    .cart-container{
      max-width: 930px;

      .header-title{
        .title{
          @include rem-2(31, 33);
        }
      }

      .cart-content{
        flex-direction: row;
        border-top: solid $color-text-dark-orange 3px;
        min-height: 272px;

        .cart-content-img{
          img{
            position: absolute;
            width: auto;
            height: 100%;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
          }
        }

        .cart-content-text{
          position: relative;
          min-width: 555px;

          .h1{
            @include rem-2(31, 39);
          }

          .content-arrow{
            padding-top: 44px;
          }
        }
      }
    }
  }

  .featuredContentTeaser{
    .card-container-fluid{
      .title-cart-grid{
        max-width: 979px;
        margin-left: 0;
      }
    }
  }
}

html.has-mouseover{
  .featured-stories-block .cart-container .cart-content a:hover{
    color: #ba4d01;
  }
}
